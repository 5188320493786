import React, { useEffect } from "react";
import Modal from "react-modal";
import { BsXCircle } from "react-icons/bs";
import "./PaymentModal.css";
import microsoftpartner from "../../assets/microsoft-partner.png";
import luxi from "../../assets/small-icon.png";
import happyluxi from "../../assets/small-icon-blue.png";
import abtest from "../../assets/abtest.png";
import paymentStare from "../../assets/payment-stare-2.png";
import { auth, db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { RiInformationLine } from "react-icons/ri";
// Hooks
import useScrollTracking from "../../hooks/useScrollTracking";

const PaymentModal = (props) => {
  const paymentPlan = ["", "start", "ultra", "corporate"];
  const infoAdv = [
    {
      icon: <i className="fa-regular fa-timer fs-20"></i>,
      title: "Minimum period",
      desc: "3 months is the minimum plan to try LUXIFER.",
    },
    {
      icon: <i className="fa-regular fa-space-station-moon fs-20"></i>,
      title: "Number of Projects",
      desc: "Depending on your plan, you can have 1, 3 or unlimited projects. The limit will be the power of calculation.",
    },
    {
      icon: <i className="fa-regular fa-page fs-20"></i>,
      title: "Number of pages analyzed",
      desc: "Depending on your plan, you can analyse 1, 3 or unlimited pages of your digital product.",
    },
    {
      icon: <i className="fa-regular fa-chart-simple fs-20"></i>,
      title: "Analytics Integration",
      desc: "LUXIFER works with GA4 and Google Tag Manager. We are working on Matomo and Piano integration.",
    },
    {
      icon: <i className="fa-regular fa-chart-simple fs-20"></i>,
      title: "Metrics analysis",
      desc: "Metrics analysis gives you the ability to connect to your analysis system and see the performance of your results.",
    },
    // { icon: <img src={luxi} style={{ width: "17.5px", height: "20px" }}></img>, title: "Automatic solve issues",     desc: "Our AI can solve problems and give you code or design that you can integrate.",},
    // { icon: <img src={abtest} style={{ width: "20px", height: "20px" }}></img>, title: "A/B test tool",     desc: "Test variation on 2 pages based on AI recommendations", },
    {
      icon: <i className="fa-regular fa-chart-scatter fs-20"></i>,
      title: "Chart views",
      desc: "See visually how recommendations impact your digital product KPIs.",
    },
    {
      icon: <i className="fa-regular fa-comment fs-20"></i>,
      title: "Direct Chat",
      desc: "Talk directly to designers and data experts",
    },
    {
      icon: <i className="fa-regular fa-share fs-20"></i>,
      title: "Share & Collaborate",
      desc: "Share your workspace with others",
    },
    {
      icon: <i className="fa-regular fa-lightbulb fs-20"></i>,
      title: "Limited recommendations",
      desc: "Free plan has only 5 recommendations. ",
    },
    //   {   icon: <img src={abtest} style={{ width: "20px", height: "20px" }}></img>,  title: "Unmoderated A/B tests",desc: "Unlimited page test variations with Ultra & Corporate plans" },
    {
      icon: <i className="fa-regular fa-mask fs-20"></i>,
      title: "UX verifier",
      desc: "Ultra & Corporate plans have a UX Verificator to check results",
    },
    {
      icon: <i className="fa-regular fa-headset fs-20"></i>,
      title: "Support",
      desc: "Basic support will respond within 72 hours. Premium support will respond within 24 hours.",
    },
    {
      icon: <i className="fa-regular fa-pen-nib fs-20"></i>,
      title: "Ad hoc UX Design services",
      desc: `You will have a dedicated UX/UI designer to implement LUXIFER's recommendations in your digital product.
      4 hours/week for the "Ultra" plan.
      Unlimited for the "Corporate" plan.`,
    },
    {
      icon: <i className="fa-regular fa-database fs-20"></i>,
      title: "Data Expert Support",
      desc: "Data Expert set up your analysis to show the impact of the LUXIFER recommendation on tour results.",
    },
  ];

  const startInfo = [
    "3 months",
    "3",
    "3",
    "v",
    "v",
    // "v",
    // "v",
    "v",
    "v",
    "-",
    "Unlimited",
    //  "-",
    "-",
    "Basic",
    "-",
    "Limited",

    "Limited",
  ];
  const ultraInfo = [
    "12 months",
    "Unlimited",
    "Unlimited",
    "v",
    "v",
    //  "v",
    //  "v",
    "v",
    "v",
    "v",
    "Unlimited",
    //   "v",
    "v",
    "Premium",
    "Limited",
    "Limited",

    "Limited",
  ];
  const corpInfo = [
    "3 months",
    "Unlimited",
    "Unlimited",
    "v",
    "v",
    //   "v",
    //   "v",
    "v",
    "v",
    "v",
    "Unlimited",
    //  "v",
    "v",
    "Premium",
    "Unlimited",
    "Unlimited",
    "Unlimited",
  ];

  const { initializeRefs, planHeaderRef, scrollPosition, resetScroll } =
    useScrollTracking(0); // 50 + 44 = 94 is the height of the space between the top of the modal and the top of the plan headers

  /**
   * Loading data for rendering in a component
   * - work like ComponentDidUpdate
   */
  useEffect(() => {
    return () => {
      resetScroll();
    };
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      // INFO: This is a workaround to make sure the refs are initialized after the modal is opened
      let timepout = setTimeout(() => {
        const containerElement = document.querySelector(
          ".payment-modal-shape2-inner",
        );
        if (containerElement) {
          initializeRefs(containerElement);
        }
      }, 100);
      return () => {
        clearTimeout(timepout);
      };
    } else {
      resetScroll(); // Reset scroll position when modal is closed
    }
  }, [props.isOpen, initializeRefs]);

  const getInfoSymbol = (item) => {
    return (
      <div style={{ marginTop: "0px" }}>
        <div className="d-flex align-items-center" style={{ marginTop: "0px" }}>
          <div className="fw-500 fs-16 lh-19.2 fc-black text-center mx-auto">
            {item === "v" ? (
              <i className="fa-regular fa-check fs-16"></i>
            ) : item === "-" ? (
              <i className="fa-regular fa-dash fs-16"></i>
            ) : item === "" ? (
              <></>
            ) : (
              <>{item}</>
            )}
          </div>
        </div>
      </div>
    );
  };

  const PlanDetails = () => {
    let rows = [];
    for (let i = 0; i < infoAdv.length; i++) {
      rows.push(
        <div className="row hover-purple-p" key={i}>
          <div className="col info-block-1" style={{ width: "282px" }}>
            {
              <div
                className="d-flex justify-content-between"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <div className="d-flex align-items-center">
                  {infoAdv[i].icon}
                  <div
                    className="fw-500 fs-16 lh-19.2 fc-black"
                    style={{
                      paddingLeft: "10px",
                      //width: "170px"
                    }}
                  >
                    {infoAdv[i].title}
                  </div>
                </div>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    infoAdv[i].desc ? (
                      <Tooltip id={`tooltip-top`}>{infoAdv[i].desc}</Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <button className="icon-button">
                    <RiInformationLine
                      alt="info icon"
                      size="16px"
                      color="#707070"
                      style={{ marginTop: "3px", pointerEvents: "auto" }}
                    />
                  </button>
                </OverlayTrigger>
              </div>
            }
          </div>

          <div
            className={`col info-block ${
              props?.userInfo?.paymentPlan === "start" ? "col-selected" : ""
            }`}
          >
            {getInfoSymbol(startInfo[i])}
          </div>
          <div
            className={`col info-block ${
              props?.userInfo?.paymentPlan === "ultra" ? "col-selected" : ""
            }`}
          >
            {getInfoSymbol(ultraInfo[i])}
          </div>
          <div
            className={`col info-block ${
              props?.userInfo?.paymentPlan === "corporate" ? "col-selected" : ""
            }`}
          >
            {getInfoSymbol(corpInfo[i])}
          </div>
        </div>,
      );
    }
    return (
      <>
        <div className="row">
          <div
            className="col fw-500 fs-18 lh-21.6 fc-grey info-block-1"
            style={{
              paddingTop: "31px",
              paddingBottom: "16px",
              borderTopLeftRadius: "12px",
            }}
          >
            Information & advantages
          </div>
          <div
            className={`col fw-500 fs-18 lh-21.6 fc-grey info-block ${
              props?.userInfo?.paymentPlan === "start" ? "col-selected" : ""
            }`}
          ></div>
          <div
            className={`col fw-500 fs-18 lh-21.6 fc-grey info-block ${
              props?.userInfo?.paymentPlan === "ultra" ? "col-selected" : ""
            }`}
          ></div>
          <div
            className={`col fw-500 fs-18 lh-21.6 fc-grey info-block ${
              props?.userInfo?.paymentPlan === "corporate" ? "col-selected" : ""
            }`}
          ></div>
        </div>

        {rows}

        <div className="row">
          <div
            className="col fw-500 fs-18 lh-21.6 fc-grey info-block-1"
            style={{
              paddingBottom: "19px",
              borderBottomLeftRadius: "12px",
            }}
          ></div>
          <div
            className={`col fw-500 fs-18 lh-21.6 fc-grey info-block ${
              props?.userInfo?.paymentPlan === "start" ? "col-selected" : ""
            }`}
          ></div>
          <div
            className={`col fw-500 fs-18 lh-21.6 fc-grey info-block ${
              props?.userInfo?.paymentPlan === "ultra" ? "col-selected" : ""
            }`}
          ></div>
          <div
            className={`col fw-500 fs-18 lh-21.6 fc-grey info-block ${
              props?.userInfo?.paymentPlan === "corporate" ? "col-selected" : ""
            }`}
          ></div>
        </div>
      </>
    );
  };

  const changePlan = async (fields) => {
    const userDoc = doc(db, "users", auth.currentUser.uid);
    await updateDoc(userDoc, fields)
      .then(() => {
        props.setUserInfo({ ...props.userInfo, ...fields });
      })
      .catch((e) => {});
  };

  const validUntil = () => {
    return (
      <div className="fc-blue fw500 fs-14 lh-20" style={{ marginTop: "46px" }}>
        Valid until<br></br>
        <span className="fw-700 fs-16 lh-20">February DD, YYYY</span>
      </div>
    );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => {
        if (!props.closable) {
          return;
        }
        props.setIsOpen(false);
      }}
      contentLabel="Payment Plan Modal"
      className="custom-modal payment-modal-shape2"
      appElement={document.getElementById("root")}
      style={{
        overflow: "hidden",
        overlay: {
          background: "#6345F8",
          zIndex: 10,
        },
      }}
    >
      <div className="payment-modal-shape2-inner">
        <div className="payment-container2">
          <img
            src={paymentStare}
            alt="Faded LUXI logo"
            style={{
              height: "313px",
              position: "absolute",
              left: "-50px",
              top: "160.07px",
              zIndex: "-100",
            }}
          ></img>
          <div
            style={{
              position: "absolute",
              width: "1150px",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "50px",
            }}
          >
            <div className="fs-32 fw-900 lh32">Select a plan</div>
            {props.closable && (
              <button
                style={{ marginRight: "50px", zIndex: "999" }}
                onClick={() => {
                  props.setIsOpen(false);
                }}
                className="icon-button"
              >
                <BsXCircle alt="X button" size="30px" color="#1F1F24" />
              </button>
            )}
          </div>

          <div className="plan-headers-wrapper fc-black">
            <div
              className="row plan-headers"
              ref={planHeaderRef}
              style={{
                marginLeft: "289.5px",
                left: `${scrollPosition.x}px`,
                transition: "0s !important",
                paddingTop: "98px",
                background: "white",
              }}
            >
              <div
                className={
                  props?.userInfo?.paymentPlan === "start"
                    ? "payment-square-selected"
                    : "payment-square payment-square-rounded"
                }
              >
                {props?.userInfo?.paymentPlan === "start" && (
                  <div
                    className="payment-pull-tab"
                    style={{ background: "#2A00FF", marginBottom: "24px" }}
                  >
                    Your plan
                  </div>
                )}
                <i className="fa-regular fa-campfire fs-24"></i>
                <div className="square-title">Start</div>
                <div className="square-subtitle">750€/month</div>
                <div className="fw-500 fs-16 fc-grey lh-192">
                  2 250€ / 3 months
                </div>
                {props?.userInfo?.paymentPlan === "start" ? (
                  <div style={{ marginTop: "-20px" }}>{validUntil()}</div>
                ) : (
                  <a
                    href="https://www.luxifer.digitalrise.be/contact-nous/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      type="button"
                      onClick={() => {
                        // changePlan({ paymentPlan: "start" });
                        // props.setPaymentPlan?.("start");
                        // props.setIsOpen(false);
                      }}
                      className="br-4 payment-btn btn-primary align-items-center fw-700"
                    >
                      <div className="fs-16 fc-white vhc">Contact us</div>
                    </button>
                  </a>
                )}
              </div>
              <div
                className={
                  props?.userInfo?.paymentPlan === "ultra"
                    ? "payment-square-selected"
                    : "payment-square"
                }
              >
                {props?.userInfo?.paymentPlan === "ultra" && (
                  <div
                    className="payment-pull-tab"
                    style={{ background: "#2A00FF", marginBottom: "24px" }}
                  >
                    Your plan
                  </div>
                )}
                {props?.userInfo?.paymentPlan !== "ultra" && (
                  <div
                    className="payment-pull-tab"
                    style={{ background: "black", marginBottom: "24px" }}
                  >
                    Best Offer
                  </div>
                )}

                <img
                  src={
                    props?.userInfo?.paymentPlan === "ultra" ? happyluxi : luxi
                  }
                  style={{ width: "21px", height: "24px" }}
                ></img>
                <div style={{ position: "relative", marginRight: "23px" }}>
                  <div className="reduction-box">-7%</div>
                </div>
                <div className="square-title" style={{ marginTop: "19.5px" }}>
                  Ultra
                </div>
                <div className="square-subtitle">695€/month</div>
                <div className="fw-500 fs-16 fc-grey lh-192">8 340€/year</div>
                {props?.userInfo?.paymentPlan === "ultra" ? (
                  <div style={{ marginTop: "-20px" }}>{validUntil()}</div>
                ) : (
                  <a
                    href="https://www.luxifer.digitalrise.be/contact-nous/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      type="button"
                      onClick={() => {
                        // changePlan({ paymentPlan: "ultra" });
                        // props.setPaymentPlan?.("ultra");
                        // props.setIsOpen(false);
                      }}
                      className="br-4 payment-btn btn-primary align-items-center fw-700"
                    >
                      <div className="fs-16 fc-white vhc">Contact us</div>
                    </button>
                  </a>
                )}
              </div>
              <div
                className={
                  props?.userInfo?.paymentPlan === "corporate"
                    ? "payment-square-selected"
                    : "payment-square payment-square-rounded"
                }
              >
                {props?.userInfo?.paymentPlan === "corporate" && (
                  <div
                    className="payment-pull-tab"
                    style={{ background: "#2A00FF", marginBottom: "24px" }}
                  >
                    Your plan
                  </div>
                )}
                <i className="fa-regular fa-volcano fs-24"></i>
                <div className="square-title">Corporate</div>
                <div className="square-subtitle">3.995€/month</div>
                {props?.userInfo?.paymentPlan === "corporate" ? (
                  validUntil()
                ) : (
                  <a
                    href="https://www.luxifer.digitalrise.be/contact-nous/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      type="button"
                      style={{
                        marginTop: "46px",
                      }}
                      onClick={() => {
                        // changePlan({ paymentPlan: "corporate" });
                        // props.setPaymentPlan?.("corporate");
                        // props.setIsOpen(false);
                      }}
                      className="br-4 payment-btn btn-primary align-items-center fw-700 "
                    >
                      <div className="fs-16 fc-white vhc">Contact us</div>
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div
            className="row plan-body"
            style={{
              paddingLeft: "3px",
              marginTop: "382px",
            }}
          >
            <PlanDetails />
          </div>
        </div>

        <div className="row d-flex text-center justify-content-center">
          <div
            style={{ width: "570px", marginTop: "50px" }}
            className="fw-400 fs-28 fc-grey lh-336  justify-content-center"
          >
            ”If You Think Good Design is Expensive, <br></br> You Should Look At
            The Cost of Bad Design”
          </div>
        </div>
        {/*
        
              <div className="row justify-content-center">
          <img
            src={microsoftpartner}
            alt="Microsoft partner logo"
            style={{
              width: "203.25px",
              height: "50px",
              marginTop: "14px",
              marginBottom: "180px",
            }}
          ></img>
        </div>
          */}
      </div>
    </Modal>
  );
};

export default PaymentModal;
