import MainLayout from "../MainLayout";
import { auth, db } from "../../firebase";
import { useEffect, useState } from "react";
import { UserInfoContext } from "../../contexts";
import "./Authorizations.css";
import "../UxChecklist/UxChecklist.css";
import { FiArrowLeft, FiPlus } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  getOtherUserUid,
  getUserInformation,
  getUserProjects,
  sendFirestoreEmail,
  sendInviteEmail,
} from "../../helpers";
import {
  Spinner,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal as BootModal,
} from "react-bootstrap";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import grayPfp from "../../assets/small-icon.png";
import { BsXCircle } from "react-icons/bs";
import CustomInput from "../CustomInput/CustomInput";
import Modal from "react-modal";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

function AuthorizationsContent({
  setSavingToDb,
  projects,
  savingToDb,
  setNewUserModalOpen,
  setNewUserProjectId,
  setCurrentWorkspace,
  setProjects,
  userInfo,
}) {
  const [projectStates, setProjectStates] = useState([]);
  const [loadingNewProjectStates, setLoadingNewProjectStates] = useState(false);
  const [openRevokeAccess, setOpenRevokeAccess] = useState(false);
  const [revokeAccessData, setRevokeAccessData] = useState(null);

  const addUserDataToProjects = async (projects) => {
    const updatedProjects = await Promise.all(
      projects.map(async (project) => {
        const updatedWorkspace = await Promise.all(
          project.workspace?.map(async (user) => {
            if (user.uid) {
              const userData = await getOtherUserUid(user.uid);
              if (userData) {
                return {
                  ...userData,
                  name: userData.firstName,
                  userDropdownIsOpen: false,
                };
              }
            }
            return { ...user, userDropdownIsOpen: false };
          }) || [],
        );

        return { ...project, workspace: updatedWorkspace };
      }),
    );

    return updatedProjects;
  };

  useEffect(() => {
    if (projects) {
      addUserDataToProjects(projects).then((updatedProjects) => {
        setProjectStates(updatedProjects);
      });
    }
  }, [projects]);

  // Entire project section

  const saveProjectToDb = async (projectId, fields) => {
    setSavingToDb(true);
    const projectDoc = doc(db, "projects", projectId);
    await updateDoc(projectDoc, fields).then(() => {
      setSavingToDb(false);
    });
  };

  function handleProjectStateChange(value, field, i) {
    if (["reader", "editor"].includes(userInfo.role)) {
      return;
    }
    setProjectStates((prevList) =>
      prevList.map((states, j) => {
        if (i === j) {
          return {
            ...states,
            [field]: value,
          };
        }
        return states;
      }),
    );
  }

  const getWorkspace = async (projectId) => {
    setLoadingNewProjectStates(true);
    const docSnap = await getDoc(doc(db, "projects", projectId));

    if (docSnap.exists()) {
      return docSnap.data().workspace;
    }
    return null;
  };

  const handleUserRolechange = async (value, i, j, projectId) => {
    // Redundancy for security
    if (["reader", "editor"].includes(userInfo.role)) {
      return;
    }
    // Re-query project in case the invited user has created an account already,
    // so that this state update does not overwrite the user's uid
    const currentWorkspace = await getWorkspace(projectId);
    let updatedWorkspace;
    let userId;

    if (value === "Revoke access") {
      updatedWorkspace = currentWorkspace.filter((user, index) => {
        if (index === j) {
          userId = user.uid;
          return false;
        }
        return true;
      });

      if (userId) {
        const userDoc = doc(db, "users", userId);
        await updateDoc(userDoc, {
          role: "client",
          workspace: "",
        });
      }
    } else {
      updatedWorkspace = currentWorkspace.map((user, index) => {
        if (index === j) {
          userId = user.uid;
          return {
            ...user,
            role: value,
          };
        }
        return user;
      });
      if (userId) {
        const userDoc = doc(db, "users", userId);
        await updateDoc(userDoc, {
          role: value,
        });
      }
    }

    saveProjectToDb(projectId, {
      workspace: updatedWorkspace,
    }).then(() => {
      getUserProjects(userInfo).then((projects) => {
        const updatedProjects = projects.map((project) => ({
          ...project,
        }));
        setProjects(updatedProjects);
        setLoadingNewProjectStates(false);
      });
    });
  };

  const roleOptions = [
    {
      label: "Owner",
      value: "owner",
      description: "",
      separator: false,
      isSelected: false,
      icon: false,
    },
    {
      label: "Editor",
      value: "editor",
      description: "Editor can add metrics to the dashboard, consult reports.",
      separator: false,
      isSelected: false,
      icon: false,
    },
    {
      label: "Reader",
      value: "reader",
      description: "Reader can just read report and see analytics.",
      separator: false,
      isSelected: false,
      icon: false,
    },

    {
      label: "Revoke access",
      value: "Revoke access",
      description: "",
      separator: true,
      isSelected: false,
      icon: <i className="fa-regular fa-book-skull"></i>,
    },
  ];

  return (
    <div style={{ height: "100vh", display: "flex", flexGrow: 1 }}>
      <div className="profile-main-content">
        <div style={{ marginLeft: "50.36px" }}>
          <div style={{ marginTop: "35.5px" }}>
            <Link to={"/"}>
              <div
                style={{
                  width: "158px",
                  height: "24px",
                  textAlign: "center",
                }}
                className="fs-14 fw-700 fc-black"
              >
                <FiArrowLeft
                  color="#000"
                  size="24px"
                  style={{ marginRight: "10px", marginBottom: "2px" }}
                />
                Back to Dashboard
              </div>
            </Link>
            <div
              style={{ height: "24px", marginTop: "61.55px" }}
              className="fs-24 fw-700 lh-243 "
            >
              Authorizations
            </div>
          </div>

          <div
            style={{
              height: "24px",
              marginTop: "68.78px",
            }}
            className="fs-18 fw-700"
          >
            Invite people to join your workspace
          </div>
          <div
            style={{
              maxWidth: "651.64px",
              marginTop: "12px",
            }}
          >
            <div className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023">
              <p>
                Every people you will invite can create an account and access to
                your Company’s LUXIFER account. You can manage people and access
                here.
              </p>
            </div>
          </div>

          <div style={{ marginTop: "50px" }}>
            {projectStates?.map((p, i) => (
              <div key={i}>
                <div
                  className="row"
                  style={{
                    margin: "0",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <img
                    src={p.image}
                    className="img-keep-ratio"
                    style={{
                      width: "38px",
                      height: "38px",
                      objectFit: "contain",
                      display: "block",
                      maxWidth: "100%",
                      padding: "0",
                      marginRight: "20.29px",
                    }}
                  />

                  <div
                    className="col fw-700 fs-18 lh-243"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "70%",
                      flexWrap: "nowrap",
                      overflow: "auto",
                      padding: "0px",
                    }}
                  >
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-top`}>{p.name}</Tooltip>}
                    >
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {p.name}
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    {p?.workspace?.map((u, j) => (
                      <div key={j} className="darker-on-hover">
                        {u.role !== "Revoke access" && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingTop: "16px",
                                paddingBottom: "16px",
                                paddingLeft: "20.52px",
                              }}
                            >
                              <div
                                className="fw-500 fs-18 lh-24"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={u.pfp ?? grayPfp}
                                  alt="profile picture"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "100%",
                                    marginRight: "18.29px",
                                  }}
                                />
                                <div
                                  className="col fw-700 wrapword"
                                  style={{
                                    minWidth: "150px",
                                    maxWidth: "150px",
                                  }}
                                >
                                  {u.name}
                                </div>
                                <div
                                  style={{
                                    minWidth: "240px",
                                    maxWidth: "240px",
                                  }}
                                >
                                  {u.email}
                                </div>
                                <div
                                  className="fc-grey wrapword"
                                  style={{
                                    minWidth: "240px",
                                    maxWidth: "240px",
                                  }}
                                >
                                  {" "}
                                  {u.jobTitle}
                                </div>
                              </div>
                              {/* roles */}
                              <div style={{ width: "100%" }}>
                                <div
                                  className="align-items-center fw-700"
                                  style={{
                                    width: "367px",
                                    marginRight: "50.12px",
                                    height: "44px",
                                    width: "130px",

                                    float: "right",
                                  }}
                                >
                                  {savingToDb || loadingNewProjectStates ? (
                                    <button
                                      style={{
                                        width: "130px",
                                        height: "48px",
                                        color: "#707070",
                                        backgroundColor: "#f7f7f7",
                                      }}
                                    >
                                      <Spinner
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </button>
                                  ) : (
                                    <CustomDropdown
                                      className="role-dropdown"
                                      options={roleOptions?.map((o) => ({
                                        ...o,
                                        isSelected:
                                          o.value === u.role ||
                                          o.label === u.role,
                                        icon:
                                          o.value === u.role ||
                                          o.label === u.role ? (
                                            <i className="fa-regular fa-check"></i>
                                          ) : (
                                            o.icon
                                          ),
                                      }))}
                                      customOptions
                                      onChange={(option) => {
                                        if (option.value === "Revoke access") {
                                          setOpenRevokeAccess(true);
                                          setRevokeAccessData({
                                            value: option.value,
                                            i,
                                            j,
                                            projectId: p.value,
                                            projectName: p.name,
                                            userName: u.name,
                                          });
                                        } else {
                                          handleUserRolechange(
                                            option.value,
                                            i,
                                            j,
                                            p.value,
                                          );
                                        }
                                      }}
                                      value={u.role}
                                      placeholder={
                                        u.role.charAt(0).toUpperCase() +
                                        u.role.slice(1)
                                      }
                                      styles={{
                                        menu: (provided, state) => ({
                                          ...provided,
                                          padding: 0,
                                          width: "300px",
                                          minHeight: "330px",
                                          overflow: "hidden",
                                          marginTop: "20px",
                                          transform: "translateX(-170px)",
                                        }),
                                        menuList: (provided, state) => ({
                                          minHeight: "330px",
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: state.isSelected
                                            ? "var(--off-white-two)"
                                            : "inherit",
                                          fontWeight: state.isSelected
                                            ? "700"
                                            : "500",
                                          borderRadius: 0,
                                          padding: "16px 24px",
                                        }),
                                      }}
                                      unstyled
                                      menuOpenState={
                                        ["reader", "editor"].includes(
                                          userInfo.role,
                                        )
                                          ? false
                                          : u.roleOpen
                                      }
                                      setMenuOpenState={(res) => {
                                        handleProjectStateChange(
                                          res,
                                          "roleOpen",
                                          i,
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            {j < p?.workspace.length - 1 && (
                              <hr style={{ margin: "0px", width: "95%" }}></hr>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {!["reader", "editor"].includes(userInfo.role) && (
                  <button
                    onClick={() => {
                      setNewUserModalOpen(true);
                      setNewUserProjectId(p?.value);
                      setCurrentWorkspace(p?.workspace);
                    }}
                    style={{
                      marginTop: "20.2px",
                      height: "44px",
                      width: "206.5px",
                    }}
                    className="btn-primary d-flex align-items-center"
                  >
                    {savingToDb ? (
                      <Spinner style={{ width: "25px", height: "25px" }} />
                    ) : (
                      <div className="mp-btn-fix">
                        <FiPlus
                          style={{
                            width: "17.5px",
                            height: "20px",
                            marginRight: "12px",
                          }}
                        />
                        Ajouter des utilisateurs
                      </div>
                    )}
                  </button>
                )}

                <hr
                  className="row"
                  style={{
                    width: "95%",
                    marginTop: "50.81px",
                    marginBottom: "32px",
                  }}
                ></hr>
              </div>
            ))}
          </div>
        </div>
      </div>
      <BootModal
        show={openRevokeAccess}
        onHide={() => {
          setOpenRevokeAccess(false);
          setRevokeAccessData(null);
        }}
      >
        <BootModal.Header closeButton>
          <BootModal.Title>{`Are yo sure you want to revoke access to ${revokeAccessData?.projectName}`}</BootModal.Title>
        </BootModal.Header>

        <BootModal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleUserRolechange(
                revokeAccessData.value,
                revokeAccessData.i,
                revokeAccessData.j,
                revokeAccessData.projectId,
              );
              setOpenRevokeAccess(false);
            }}
          >
            Yes
          </Button>
        </BootModal.Footer>
      </BootModal>
    </div>
  );
}

export default function Authorizations() {
  const [userInfo, setUserInfo] = useState(null);
  const [hasUserInfo, setHasUserInfo] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [savingToDb, setSavingToDb] = useState(false);

  const [newUserModalOpen, setNewUserModalOpen] = useState(false);

  const [isGoogleAccount, setIsGoogleAccount] = useState(false);
  const [projects, setProjects] = useState(null);

  const [newUserProjectId, setNewUserProjectId] = useState(null);
  const [currentWorkspace, setCurrentWorkspace] = useState([{}]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [role, setRole] = useState("reader");
  const [selectedRole, setSelectedRole] = useState({
    label: "Reader",
    value: "reader",
  });
  const [isRoleMenuOpen, setRoleIsMenuOpen] = useState(false);
  const [validEmail, setValidEmail] = useState(false);

  const roleOptions = [
    { label: "Owner", value: "owner" },
    { label: "Reader", value: "reader" },
    { label: "Editor", value: "editor" },
    { label: "Revoke access", value: "Revoke access" },
  ];

  useEffect(() => {
    setName("");
    setEmail("");
    setJobTitle("");
    setSelectedRole({ label: "Reader", value: "reader" });
  }, [newUserModalOpen]);

  useEffect(() => {
    const fetchUserInformation = async () => {
      if (user) {
        setHasUserInfo(true);
        getUserInformation().then((info) => {
          setUserInfo(info);
          setIsGoogleAccount(info?.isGoogleAccount);
          getUserProjects(info).then((projects) => {
            const updatedProjects = projects.map((project) => ({
              ...project,
            }));
            setProjects(updatedProjects);
          });
        });
      }
    };
    if (!hasUserInfo) fetchUserInformation();
  }, [user]);

  useEffect(() => {
    if (
      !loading &&
      (!user ||
        (user && userInfo && ["designer", "reader"].includes(userInfo.role)))
    ) {
      navigate("/");
    }
  }, [loading, userInfo, user]);

  const saveProjectToDb = async (projectId, fields) => {
    setSavingToDb(true);
    const projectDoc = doc(db, "projects", projectId);
    await updateDoc(projectDoc, fields).then(() => {
      setSavingToDb(false);
    });
  };

  const handleEmailChange = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmail(email);
    setValidEmail(emailPattern.test(email));
  };

  const getNewOrExistingUser = async () => {
    const newUser = {
      name: name.trim(),
      email: email.trim(),
      jobTitle: jobTitle.trim(),
      role: selectedRole.value,
    };

    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return -1;
      // Uncomment if we want to allow existing users to be added
      /*const newData = querySnapshot.docs[0].data();
      newUser.name = newData.firstName;
      newUser.jobTitle = newData.jobTitle ?? jobTitle;
      newUser.pfp = newData.pfp ?? null;
      newUser.uid = querySnapshot.docs[0].id;*/
    }
    return newUser;
  };

  const saveNewUser = async () => {
    // Check if user tries to add themself
    if (email === userInfo.email) {
      toast.error("You are already part of the workspace!");
      return;
    }

    // Check if email is already in the project (no multiple roles)
    if (currentWorkspace) {
      const userExists = currentWorkspace.find(
        (user) => user.email === email.trim(),
      );
      if (userExists) {
        toast.error("User already exists in this project!");
        return;
      }
    }

    // Check if user already exists and create a new user
    const newUser = await getNewOrExistingUser();
    if (newUser === -1) {
      toast.error("This email is already in use or has been revoked.");
      return;
    }

    const projectRef = doc(db, "projects", newUserProjectId);
    const projectSnap = await getDoc(projectRef);

    if (!projectSnap.exists()) {
      toast.error("This project does not exist");
      return;
    }

    const projectData = projectSnap.data();
    const actualWorkspace = projectData.workspace || [];

    saveProjectToDb(newUserProjectId, {
      workspace: actualWorkspace ? [...actualWorkspace, newUser] : [newUser],
    }).then(() => {
      setNewUserModalOpen(false);
      getUserProjects(userInfo).then((projects) => {
        setProjects(projects);
      });

      sendInviteEmail(newUser.email, [
        {
          name: "url",
          content: `https://www.luxifer.app/invited/${newUserProjectId}?email=${encodeURIComponent(
            newUser.email,
          )}`,
        },
        {
          name: "role",
          content: newUser.role,
        },
        {
          name: "name",
          content: newUser.name,
        },
      ]).then(() => {
        toast.success(name + " added successfully");
      });
    });
  };

  /**
   * If the page is loading, or user info is loading, the Spinning wheel will be shown.
   */
  if (loading || (user && !userInfo?.role)) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  if (user) {
    if (userInfo?.paymentPlan === "start") {
      return (
        <UserInfoContext.Provider value={userInfo}>
          <ToastContainer />
          <div style={{ height: "100vh", display: "flex", flexGrow: 1 }}>
            <div className="profile-main-content">
              <div style={{ marginLeft: "50.36px" }}>
                <div style={{ marginTop: "35.5px" }}>
                  <Link to={"/"}>
                    <div
                      style={{
                        width: "158px",
                        height: "24px",
                        textAlign: "center",
                      }}
                      className="fs-14 fw-700 fc-black"
                    >
                      <FiArrowLeft
                        color="#000"
                        size="24px"
                        style={{ marginRight: "10px", marginBottom: "2px" }}
                      />
                      Back to Dashboard
                    </div>
                  </Link>

                  <div
                    style={{ height: "24px", marginTop: "61.55px" }}
                    className="fs-24 fw-700 lh-243 "
                  >
                    Authorizations
                  </div>
                  <div
                    style={{
                      height: "24px",
                      marginTop: "68.78px",
                    }}
                    className="fs-18 fw-700"
                  >
                    Users on "Start" payment plan cannot add users to their
                    workspace.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </UserInfoContext.Provider>
      );
    }
    return (
      <>
        <AuthorizationsContent
          userInfo={userInfo}
          user={user}
          setUserInfo={setUserInfo}
          setSavingToDb={setSavingToDb}
          savingToDb={savingToDb}
          isGoogleAccount={isGoogleAccount}
          projects={projects}
          setProjects={setProjects}
          setNewUserModalOpen={setNewUserModalOpen}
          setNewUserProjectId={setNewUserProjectId}
          setCurrentWorkspace={setCurrentWorkspace}
        >
          <ToastContainer />
        </AuthorizationsContent>
        <Modal
          isOpen={newUserModalOpen}
          contentLabel="Add new user modal"
          className="add-user-modal custom-modal modal-shape2"
          appElement={document.getElementById("root")}
          style={{
            overlay: {
              background: "rgb(42, 0, 255, 0.7)",
              backdropFilter: "blur(8px)",
              zIndex: 10,
            },
          }}
        >
          <div>
            <button
              className="d-flex justify-content-center align-items-center delete-confirm-x-btn"
              onClick={() => setNewUserModalOpen(false)}
            >
              <BsXCircle alt="X button" size="32px" color="#0F0C22" />
            </button>
          </div>
          <div className="text-center mt-3">
            <h3 className="mb-4 mp-ellipsis-text">Add a new LUXIFER Member</h3>
            <h5 className="mb-4">Complete the information below</h5>
          </div>

          <div className="d-flex justify-content-center align-items-center mb-2">
            <CustomInput
              placeholder="Name, Firstname, Surname"
              containerClassName="observation-text-input-container delete-width-input"
              inputClassNames="delete-width-input"
              className={name === "" ? "active" : ""}
              valueState={name}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-2">
            <CustomInput
              placeholder="Professional email address"
              containerClassName="observation-text-input-container delete-width-input"
              inputClassNames="delete-width-input"
              className={email === "" ? "active" : ""}
              valueState={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              value={email}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <CustomInput
              placeholder="Job Title (optional)"
              containerClassName="observation-text-input-container delete-width-input"
              inputClassNames="delete-width-input"
              className={jobTitle === "" ? "active" : ""}
              valueState={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              value={jobTitle}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div
              className="mx-auto justify-content-center align-items-center"
              style={{ width: "367px" }}
            >
              <CustomDropdown
                options={roleOptions.slice(0, -1)}
                onChange={(option) => setSelectedRole(option)}
                value={selectedRole}
                placeholder="Role"
                unstyled
                menuOpenState={isRoleMenuOpen}
                setMenuOpenState={setRoleIsMenuOpen}
              />
            </div>
          </div>

          <div className="row justify-content-center mb-5 d-flex align-items-center">
            <button
              className="new-user-confirm-btn btn-primary"
              onClick={async () => await saveNewUser()}
              disabled={!validEmail || !name}
            >
              <span style={{ color: "white" }}>Confirm</span>
            </button>
          </div>
        </Modal>
      </>
    );
  }
}
