import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { auth, db } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";

export function Invite({
  setUserInfo = { setUserInfo },
  setSelectedProject = { setSelectedProject },
  userInfo = { userInfo },
}) {
  const pid = useParams().pid;
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");

  useEffect(() => {
    if (auth.currentUser && userInfo && userInfo.role) {
      setUserInfo({});
      setSelectedProject({});
      auth.signOut().then(() => {
        navigate("/invited/" + pid + "?email=" + email, {
          state: { pageMode: "login" },
        });
      });
    } else {
      navigate("/invited/" + pid + "?email=" + email);
    }
  }, [auth]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner style={{ height: "70px", width: "70px" }} />
    </div>
  );
}
