import "./CustomInput.css";
import { forwardRef } from "react";
const CustomInput = forwardRef((props, ref) => {
  return (
    <div
      style={{ position: "relative", ...props.containerStyle }}
      className={`d-flex justify-content-between align-items-center flex-row${
        props.imgRight ? "-reverse" : ""
      }`}
    >
      {props.logo && (
        <div
          style={{ ...props.imgStyle }}
          className={
            props.valueState ? "custom-input-logo-active" : "custom-input-logo"
          }
        >
          {props.logo}
        </div>
      )}
      <div
        className={`input-container ${
          props.logo ? "input-container-logo" : ""
        } w-100 ${
          props.containerClassName
            ? props.containerClassName
            : "observation-text-input-container"
        }`}
      >
        {props.isTextArea ? (
          <textarea
            disabled={props.disabled}
            className={`w-100 ${props.onChange ? "text-input my-2" : ""} ${
              props.valueState ? "active" : ""
            } ${props.inputClassNames ?? ""}`}
            style={{
              height: "46px",
            }}
            value={props.valueState}
            type={props.type ?? "text"}
            onChange={props.handleChange ?? props.onChange}
            readOnly={props.readOnly}
            onClick={props.onClick}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            maxLength={props.maxLength}
          />
        ) : (
          <input
            className={`w-100 ${props.onChange ? "text-input my-2" : ""} ${
              props.valueState ? "active" : ""
            } ${props.inputClassNames ?? ""}`}
            style={{
              height: props.customHeight ?? "460px",
              paddingLeft:
                props.isurlinput && props.valueState ? "62px" : "12px",
            }}
            type={props.type ?? "text"}
            disabled={props.disabled}
            value={props.valueState}
            onChange={props.handleChange ?? props.onChange}
            readOnly={props.readOnly}
            onClick={props.onClick}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
            ref={ref}
            maxLength={props.maxLength}
            isurlinput={props.isurlinput ?? "false"}
          />
        )}
        <label
          className={props.valueState ? "active" : ""}
          style={{
            color: props.description ? "#0F0C22" : "",
            left: `${props.inputClassNames === "search-bar" ? "0px" : "12px"}`,
          }}
        >
          {props.placeholder}
        </label>
        {!props.valueState && props.description && <h6>{props.description}</h6>}
        {props.isurlinput && props.valueState && (
          <div className="https">https://</div>
        )}
      </div>
    </div>
  );
});

export default CustomInput;
