import { useDraggable } from "react-use-draggable-scroll";
import { useEffect, useRef, useState } from "react";
import { FiPlay } from "react-icons/fi";
import { CustomPaginator, getHeatmapData } from "../../helpers";
import h337 from "heatmap.js";
import { Spinner } from "react-bootstrap";
import insightFlame from "../../assets/insight-flame.png";
import { useNavigate } from "react-router-dom";

export default function HeatMap({
  funnel,
  funnelLoaded,
  totalPages,
  imgSrcs,
  setPathModalOpen,
  pid,
  filters,
  MRAID,
  selectedProject,
}) {
  const [page, setPage] = useState(0);
  const [heatmapData, setHeatmapData] = useState(null);
  const [heatmapClouds, setHeatmapClouds] = useState([]);
  const [heatmapDataTemp, setHeatmapDataTemp] = useState([]);
  const latestTimestampRef = useRef(0);
  const latestTimestampRefImages = useRef(0);

  const [hoverContext, setHoverContext] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  const [hoverScrolls, setHoverScrolls] = useState([]);
  const [hoverClicks, setHoverClicks] = useState([]);
  const [hoverHesitations, setHoverHesitations] = useState([]);
  const [hoverFrustrations, setHoverFrustrations] = useState([]);
  const [hoverElements, setHoverElements] = useState([]);
  const [hoverInsights, setHoverInsights] = useState([]);

  const [tooltip, setTooltip] = useState(null);

  const [insightNav, setInsightNav] = useState(null);
  const navigate = useNavigate();

  const ref = useRef(null);
  const trackerRef = useRef(null);
  const { events } = useDraggable(ref, {
    isMounted: true,
  });

  const pageWidth = 650;

  const [loadedImage, setLoadedImage] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = insightFlame;
    img.onload = () => setLoadedImage(img);
  }, []);

  function calculateCurrentPage(scrollLeft) {
    return Math.floor((scrollLeft + 200) / pageWidth);
  }

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const currentPageIndex = calculateCurrentPage(ref.current.scrollLeft);
        setPage(currentPageIndex);
      }
    };

    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleMouseDown = () => {
      if (insightNav) {
        navigate(insightNav);
      }
    };
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [insightNav]);

  useEffect(() => {
    setImagesLoaded(false);
    const currentTimestamp = Date.now();

    const loadImage = async (src) => {
      const img = new Image();
      img.src = src;
      await img.decode();
      return img;
    };

    Promise.all(
      imgSrcs.filter((src) => src !== undefined && src !== "").map(loadImage),
    )
      .then(() => {
        if (currentTimestamp > latestTimestampRefImages.current) {
          latestTimestampRefImages.current = currentTimestamp;
          setImagesLoaded(true);
        }
      })
      .catch((e) => {});
  }, [imgSrcs]);

  async function getAndSetHeatmapData(pid, urls, MRAID) {
    if (!pid || !urls || urls.length === 0 || funnel.length === 0) {
      return null;
    }

    const currentTimestamp = Date.now();
    const data = await getHeatmapData(
      pid,
      urls,
      filters.startDate,
      filters.endDate,
      [
        filters.desktop ? "desktop" : null,
        filters.phone ? "smartphone" : null,
        filters.tablet ? "tablet" : null,
      ].filter(Boolean),
      filters.country ? filters.country.map((el) => el.label) : [],
      filters.duration,
      filters.localTime,
      filters.browser ? filters.browser.map((el) => el.label) : [],
      filters.referrer ? filters.referrer.map((el) => el.value) : [],
      filters.deviceBrand ? filters.deviceBrand.map((el) => el.value) : [],
      filters.resolution ? filters.resolution.map((el) => el.value) : [],
      filters.formUse ? filters.formUse.map((el) => el.value) : [],
      filters.city ? filters.city.map((el) => el.value) : [],
      MRAID,
      selectedProject.rootUrl ?? "",
    );

    setHeatmapDataTemp({ data, timestamp: currentTimestamp });
  }

  useEffect(() => {
    if (
      heatmapDataTemp &&
      heatmapDataTemp.timestamp > latestTimestampRef.current
    ) {
      latestTimestampRef.current = heatmapDataTemp.timestamp;
      setHeatmapData(heatmapDataTemp.data);
    }
  }, [heatmapDataTemp]);

  useEffect(() => {
    if (
      funnelLoaded &&
      funnel.length > 0 &&
      pid &&
      filters.startDate &&
      filters.endDate
    ) {
      const urls = [...funnel.map((el) => encodeURIComponent(el.url))];
      getAndSetHeatmapData(pid, urls, MRAID);
    }
  }, [
    MRAID,
    funnel,
    pid,
    funnelLoaded,
    filters.startDate,
    filters.endDate,
    filters.desktop,
    filters.phone,
    filters.tablet,
    filters.country,
    filters.duration,
    filters.localTime,
    filters.browser,
    filters.referrer,
    filters.deviceBrand,
    filters.resolution,
    filters.formUse,
    filters.city,
  ]);

  function createOrClearCanvas(element, className, id) {
    const width = element.offsetWidth;
    const height = element.offsetHeight;
    let canvas = element.querySelector(`.${className}`);
    if (!canvas) {
      canvas = document.createElement("canvas");
      canvas.className = className;
      canvas.width = width;
      canvas.height = height;
      canvas.style.position = "absolute";
      canvas.style.top = "0";
      canvas.style.left = "0";
      if (id) {
        canvas.id = id;
      }

      element.appendChild(canvas);
    } else {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    return canvas;
  }

  function clearCanvas(ctx) {
    if (ctx) ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  function clearAllCanvases() {
    // Clear all canvases for the hover square
    for (let i = 0; i < hoverElements.length; i++) {
      const element = document.querySelector(`#heatmap-page-${i}`);
      if (element) {
        createOrClearCanvas(element, "elements-canvas");
      }
    }
  }

  function getTooltipHTMLblack(count, total, type) {
    const percentage = ((count / total) * 100)
      .toFixed(2)
      .toString()
      .replace(".", ",");
    return `<div>
              <div>${count}</div>
              <div>${type}${count === 1 ? "" : "s"}</div>
              <div>${percentage}% of all ${type}s</div>
            </div>`;
  }

  function pointInZone(pointX, pointY, zone) {
    return (
      pointX >= zone.x &&
      pointX <= zone.x + zone.w &&
      pointY >= zone.y &&
      pointY <= zone.y + zone.h
    );
  }

  // Main hover canvas
  useEffect(() => {
    if (!hoverContext || !hoverContext.canvas.id) {
      clearAllCanvases();
      return;
    }
    const j = parseInt(hoverContext.canvas.id.split("-")[2]);
    const ctx = hoverContext;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    let isHovering = false;
    let isHoveringOverInsight = false;

    clearAllCanvases();

    if (filters.heatmap && hoverElements[j]) {
      const element = document.querySelector(`#heatmap-page-${j}`);
      if (element) {
        const canvas = createOrClearCanvas(element, "elements-canvas");
        const ctx = canvas.getContext("2d");

        ctx.strokeStyle = "white";
        ctx.lineWidth = 2;

        const hoverableItems = hoverElements[j].filter((item) =>
          pointInZone(mouseX, mouseY, item),
        );

        if (hoverableItems.length > 0) {
          const focus = hoverableItems.reduce((smallest, item) => {
            const smallestArea = smallest.w * smallest.h;
            const itemArea = item.w * item.h;
            return itemArea < smallestArea ? item : smallest;
          });

          ctx.strokeRect(focus.x, focus.y, focus.w, focus.h);

          const total = hoverClicks[j].reduce((acc, e) => acc + e.value, 0);

          const clicksInFocus = hoverClicks[j].filter((e) =>
            pointInZone(e.x, e.y, focus),
          );

          const totalInFocus = clicksInFocus.reduce(
            (acc, e) => acc + e.value,
            0,
          );

          for (let i = 0; i < clicksInFocus.length; i++) {
            if (pointInZone(mouseX, mouseY, focus)) {
              isHovering = true;
              setTooltip({
                show: true,
                html: getTooltipHTMLblack(totalInFocus, total, "click"),
                type: "heatmap",
              });

              break;
            }
          }
        }
      }
    }

    if (filters.frustrations && hoverFrustrations[j]) {
      const frustrationClicks = hoverFrustrations[j].filter(
        (click) => click.frustration,
      );

      if (frustrationClicks) {
        const totalFrustrations = frustrationClicks.reduce(
          (acc, click) => acc + click.frustration,
          0,
        );
        for (let i = 0; i < hoverFrustrations[j].length; i++) {
          const click = hoverFrustrations[j][i];
          if (!click.frustration) {
            continue;
          }
          if (
            mouseY &&
            mouseX &&
            Math.abs(mouseY - click.y) <= 10 &&
            Math.abs(mouseX - click.x) <= 10
          ) {
            const style = {
              type: "frustration",
              backgroundColor: "#27DAB7",
              total: totalFrustrations,
            };
            isHovering = true;
            setTooltip({
              show: true,
              html: getTooltipHTMLblack(
                click.frustration,
                style.total,
                "frustration",
              ),
              type: "frustrations",
            });

            ctx.beginPath();
            ctx.arc(click.x, click.y, 7, 0, 2 * Math.PI, false);
            ctx.strokeStyle = style.backgroundColor;
            ctx.lineWidth = 1;
            ctx.stroke();
            break;
          }
        }
      }
    }

    if (filters.hesitations && hoverHesitations[j]) {
      const totalHesitations = hoverHesitations[j].reduce(
        (acc, hes) => acc + hes.value,
        0,
      );

      for (let i = 0; i < hoverHesitations[j].length; i++) {
        const hes = hoverHesitations[j][i];
        if (
          mouseY &&
          mouseX &&
          Math.abs(mouseY - hes.y) <= 10 &&
          Math.abs(mouseX - hes.x) <= 10
        ) {
          const percentage = ((hes.value / totalHesitations) * 100)
            .toFixed(2)
            .toString()
            .replace(".", ",");
          isHovering = true;
          setTooltip({
            show: true,
            html: getTooltipHTMLblack(
              hes.value,
              totalHesitations,
              "hesitation",
            ),
            type: "hesitations",
          });

          ctx.beginPath();
          ctx.arc(hes.x, hes.y, 7, 0, 2 * Math.PI, false);
          ctx.strokeStyle = "red";
          ctx.lineWidth = 1;
          ctx.stroke();
          break;
        }
      }
    }

    if (filters.insights && hoverInsights[j]) {
      for (let i = 0; i < hoverInsights[j].length; i++) {
        const insight = hoverInsights[j][i];
        if (
          mouseY &&
          mouseX &&
          Math.abs(mouseY - insight.y) <= 10 &&
          Math.abs(mouseX - insight.x) <= 10
        ) {
          isHovering = true;
          isHoveringOverInsight = true;
          setInsightNav(`/insights/view?id=${insight.id}&pid=${pid}`);
          setTooltip({
            show: true,
            html: `<div>
            <div>Insight #${insight.num}</div>
            <div>${insight.title}</div>
            <div>+1,07% potential gain on “Goal”. </div>
          </div>`,
            type: "insights",
          });
          break;
        }
      }
    }

    if (!isHoveringOverInsight) {
      setInsightNav(null);
    }

    if (!isHovering) {
      if (filters.scroll && hoverScrolls[j] && hoverScrolls[j].length > 0) {
        const percentageOverLoc = (
          (hoverScrolls[j].filter(
            (scroll) => scroll > mouseY / ctx.canvas.height,
          ).length /
            hoverScrolls[j].length) *
          100
        ).toFixed(2);

        setTooltip({
          show: true,
          text: `${percentageOverLoc}% of users scroll this far`,
          type: "scroll",
        });
      } else {
        setTooltip(null);
      }

      if (hoverContext && filters.scroll) {
        ctx.beginPath();
        ctx.moveTo(0, mouseY);
        ctx.lineTo(ctx.canvas.width, mouseY);
        ctx.strokeStyle = "#2A00FF";
        ctx.lineWidth = 1;
        ctx.stroke();

        ctx.fillStyle = "rgba(42, 0, 255, 0.1)";
        ctx.fillRect(0, mouseY, ctx.canvas.width, ctx.canvas.height - mouseY);
      }
    }
  }, [
    mouseX,
    mouseY,
    hoverContext,
    filters.scroll,
    filters.clicks,
    filters.frustrations,
    filters.hesitations,
    filters.insights,
    hoverScrolls,
    hoverClicks,
    hoverHesitations,
    hoverInsights,
  ]);

  function createMouseoverCanvas(element, canvas) {
    const ctx = canvas.getContext("2d");

    function handleMouseEnter() {
      setHoverContext(ctx);
      element.addEventListener("mousemove", updateMousePosition);
    }

    element.addEventListener("mouseenter", handleMouseEnter);
    if (element.matches(":hover")) {
      handleMouseEnter();
    }

    element.addEventListener("mouseleave", () => {
      element.removeEventListener("mousemove", updateMousePosition);
      setTooltip(null);
      clearCanvas(ctx);
      setHoverContext(null);
    });

    function updateMousePosition(e) {
      const rect = element.getBoundingClientRect();
      setMouseX(e.clientX - rect.left + element.scrollLeft);
      setMouseY(e.clientY - rect.top + element.scrollTop);
    }
  }

  function distance(click1, click2) {
    return Math.sqrt(
      Math.pow(click1.x - click2.x, 2) + Math.pow(click1.y - click2.y, 2),
    );
  }

  function clusterClicks(clicks, threshold) {
    const clusters = [];
    const visited = new Array(clicks.length).fill(false);

    for (let i = 0; i < clicks.length; i++) {
      if (visited[i]) continue;

      const cluster = [];
      const stack = [i];

      while (stack.length > 0) {
        const index = stack.pop();
        if (visited[index]) continue;

        visited[index] = true;
        cluster.push(clicks[index]);

        for (let j = 0; j < clicks.length; j++) {
          if (!visited[j] && distance(clicks[index], clicks[j]) <= threshold) {
            stack.push(j);
          }
        }
      }

      clusters.push(cluster);
    }

    return clusters;
  }

  function averageCoordinates(cluster) {
    const sum = cluster.reduce(
      (acc, click) => {
        acc.x += click.x;
        acc.y += click.y;
        acc.frustrationCount += click.frustration ? 1 : 0;
        return acc;
      },
      { x: 0, y: 0, frustrationCount: 0 },
    );

    return {
      x: sum.x / cluster.length,
      y: sum.y / cluster.length,
      value: cluster.length,
      frustration: sum.frustrationCount,
    };
  }

  function adjustScrolls(scrolls, height) {
    const min = 350 / height;
    const maxScroll = Math.max(...scrolls);
    const minScroll = Math.min(...scrolls);
    const range = maxScroll - minScroll;

    const normalizedScrolls =
      range === 0
        ? scrolls.map(() => 0)
        : scrolls.map((scroll) => (scroll - minScroll) / range);

    const adjustedScrolls = normalizedScrolls.map(
      (value) => min + value * (maxScroll - min),
    );

    return adjustedScrolls;
  }

  const processHeatmapData = (heatmapData, index, element) => {
    const width = filters.desktop
      ? 1920
      : filters.phone
        ? 360
        : filters.tablet
          ? 1024
          : null;

    const ratio = 650 / width;
    const elementHeight = element.offsetHeight;
    const elementWidth = element.offsetWidth;

    const processCoordinates = (data, hasFrustration = false) => {
      return data.map((e) => ({
        x: Math.floor((e.x / e.w) * elementWidth),
        y: Math.floor((e.y / e.h) * elementHeight),
        value: 1,
        ...(hasFrustration && { frustration: e.frustration }),
      }));
    };
    const processSquares = (data) => {
      return data.map((e) => ({
        x: Math.floor(e.x * ratio),
        y: Math.floor(e.y * ratio),
        w: Math.floor(e.w * ratio),
        h: Math.floor(e.h * ratio),
      }));
    };

    const clicks = heatmapData[index].clicks
      ? processCoordinates(heatmapData[index].clicks, true)
      : [];
    const moves = heatmapData[index].moves
      ? processCoordinates(heatmapData[index].moves)
      : [];
    const hesitations = heatmapData[index].hesitations
      ? processCoordinates(heatmapData[index].hesitations)
      : [];
    const elements = heatmapData[index].elements
      ? processSquares(heatmapData[index].elements)
      : [];

    const scrolls = heatmapData[index].scrolls
      ? adjustScrolls(
          heatmapData[index].scrolls
            .map((scroll) => scroll / 100)
            .sort((a, b) => a - b),
          elementHeight,
        )
      : adjustScrolls([0], elementHeight);

    const insights = heatmapData[index].insights
      ? heatmapData[index].insights.flatMap((e) =>
          e.bounds
            ? e.bounds.map((b) => ({
                x: Math.floor((b.x / b.width) * elementWidth),
                y: Math.floor(b.y * ratio),
                title: e.title,
                num: e.num,
                id: e.trueId,
              }))
            : [],
        )
      : [];

    return { clicks, moves, insights, hesitations, scrolls, elements };
  };

  const drawPoints = (array, ctx, color) => {
    array.forEach((item) => {
      ctx.beginPath();
      ctx.arc(item.x, item.y, 5, 0, 2 * Math.PI, false);
      ctx.fillStyle = color;
      ctx.fill();
    });
  };

  useEffect(() => {
    if (!heatmapData || !filters || !imagesLoaded) {
      return;
    }
    let heatmapCloudsLocal = [];
    heatmapClouds.forEach((hc) => {
      hc.setData({ max: 0, data: [] });
    });
    const hoverScrolls = [];
    const hoverClicks = [];
    const totalClicks = [];
    const hoverHesitations = [];
    const hoverInsights = [];
    const frustrationClicks = [];
    const hoverElements = [];
    for (let i = 0; i < heatmapData.length; i++) {
      const element = document.querySelector(`#heatmap-page-${i}`);
      if (!element) {
        continue;
      }
      const { clicks, moves, insights, hesitations, scrolls, elements } =
        processHeatmapData(heatmapData, i, element);

      const clickClusters = clusterClicks(clicks, 20);
      const avgClicks = clickClusters.map((cluster) =>
        averageCoordinates(cluster),
      );
      const hesClusters = clusterClicks(hesitations, 20);
      const avgHesitations = hesClusters.map((cluster) =>
        averageCoordinates(cluster),
      );

      hoverClicks.push(clicks);
      hoverScrolls.push(scrolls);
      frustrationClicks.push(avgClicks);
      totalClicks.push(clicks.length);
      hoverHesitations.push(avgHesitations);
      hoverInsights.push(insights);
      hoverElements.push(elements);

      const vc2 = createOrClearCanvas(
        element,
        "mouseover-canvas",
        `mouseover-canvas-${i}`,
      );

      if (vc2) {
        createMouseoverCanvas(element, vc2);
      }

      if (filters.scroll) {
        const vc = createOrClearCanvas(element, "heatmap-canvas");
        const verticalCtx = vc.getContext("2d");
        const colors2 = [
          "rgba(255, 0, 0, 0.2)",
          "rgba(255, 0, 0, 0.2)",
          "rgba(255, 0, 0, 0.2)",
          "rgba(255, 255, 0, 0.2)",
          "rgba(255, 255, 0, 0.2)",
          "rgba(255, 255, 0, 0.2)",
          "rgba(255, 255, 0, 0.2)",
          "rgba(255, 255, 0, 0.2)",
          "rgba(0, 0, 255, 0.2)",
          "rgba(0, 0, 255, 0.2)",
          "rgba(0, 0, 255, 0.2)",
        ];

        function getProportion(arr, s) {
          return arr.filter((v) => v < s).length / arr.length;
        }

        const colorMap = [];

        for (let i = 0; i < 11; i++) {
          const proportion = Math.round(getProportion(scrolls, i / 10) * 10);
          if (colors2[proportion])
            colorMap.push({ pos: i / 10, color: colors2[proportion] });
        }

        const gradient = verticalCtx.createLinearGradient(0, 0, 0, vc.height);
        colorMap.forEach((c) => {
          gradient.addColorStop(c.pos, c.color);
        });
        verticalCtx.fillStyle = gradient;
        verticalCtx.fillRect(0, 0, vc.width, vc.height);
      } else {
        createOrClearCanvas(element, "heatmap-canvas");
      }

      if (filters.heatmap) {
        const heatmapInstance = h337.create({
          container: element,
          maxOpacity: 0.6,
          minOpacity: 0,
        });
        heatmapCloudsLocal[i] = heatmapInstance;
        // To add moves back into heatmap, uncomment this
        // heatmapInstance.setData({
        //   max: Math.max((clicks.length + moves.length) / 100, 3),
        //   data: [...clicks, ...moves],
        // });
        heatmapInstance.setData({
          max: Math.max(clicks.length / 100, 3),
          data: clicks,
        });
        setHeatmapClouds(heatmapCloudsLocal);
      } else {
        heatmapClouds.forEach((hc) => {
          hc.setData({ max: 0, data: [] });
        });
        setHeatmapClouds([]);
        heatmapCloudsLocal = [];
      }

      if (filters.insights && loadedImage) {
        const canvas = createOrClearCanvas(element, "insights-canvas");
        const ctx = canvas.getContext("2d");

        const width = loadedImage.width;
        const height = loadedImage.height;

        insights.forEach((insight) => {
          ctx.drawImage(
            loadedImage,
            insight.x - width / 2,
            insight.y - height / 2 - 3,
            width,
            height,
          );
          ctx.font = "700 14px Satoshi";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(Math.min(insight.num, 999), insight.x, insight.y);
        });
      } else {
        createOrClearCanvas(element, "insights-canvas");
      }

      if (filters.frustrations) {
        const canvas = createOrClearCanvas(element, "frustrations-canvas");
        const ctx = canvas.getContext("2d");
        const frustratedClicks = avgClicks.filter((click) => click.frustration);
        drawPoints(frustratedClicks, ctx, "#27DAB7");
      } else {
        createOrClearCanvas(element, "frustrations-canvas");
      }

      if (filters.hesitations) {
        const canvas = createOrClearCanvas(element, "hesitations-canvas");
        const ctx = canvas.getContext("2d");
        drawPoints(avgHesitations, ctx, "#EB0D35");
      } else {
        createOrClearCanvas(element, "hesitations-canvas");
      }
    }
    setHoverScrolls(hoverScrolls);
    setHoverClicks(hoverClicks);
    setHoverHesitations(hoverHesitations);
    setHoverInsights(hoverInsights);
    setHoverFrustrations(frustrationClicks);
    setHoverElements(hoverElements);
  }, [heatmapData, filters, imagesLoaded, loadedImage]);

  const scrollToPage = (page) => {
    const elementId = `heatmap${page}`;
    const element = document.getElementById(elementId);
    if (element) {
      const scrollableParent = element.parentElement;
      const targetScrollLeft = element.offsetLeft - scrollableParent.offsetLeft;
      scrollableParent.scrollTo({
        left: targetScrollLeft,
        behavior: "smooth",
      });
    }
  };

  // Main tooltip rendering
  useEffect(() => {
    const filterTypes = [
      "heatmap",
      "frustrations",
      "scroll",
      "hesitations",
      "insights",
    ];

    if (
      tooltip?.show &&
      filterTypes.some((type) => tooltip.type === type && filters[type])
    ) {
      const tracker = trackerRef.current;

      if (!tracker) {
        return;
      }

      const tooltipElement = document.createElement("div");
      tooltipElement.style.zIndex = "1000";
      tooltipElement.style.position = "absolute";
      tooltipElement.style.pointerEvents = "none";
      tooltipElement.className = `heatmap-tooltip-${
        tooltip.type === "insights" ? 2 : 1
      } ${
        tooltip.type === "scroll"
          ? "v4-tooltip-follow-blue"
          : "v4-tooltip-follow-dark"
      }`;
      if (tooltip.text) {
        tooltipElement.innerText = tooltip.text;
      } else if (tooltip.html) {
        tooltipElement.innerHTML = tooltip.html;
      }

      document.body.appendChild(tooltipElement);

      const handleMouseMove = (event) => {
        const x = event.clientX + window.scrollX;
        const y = event.clientY + window.scrollY;
        tooltipElement.style.left = `${x}px`;
        tooltipElement.style.top = `${y}px`;
        tooltipElement.style.display = "block";
      };

      tracker.addEventListener("mousemove", handleMouseMove);

      return () => {
        tracker.removeEventListener("mousemove", handleMouseMove);
        document.body.removeChild(tooltipElement);
      };
    }
  }, [
    tooltip?.show,
    tooltip?.type,
    tooltip?.text,
    tooltip?.html,
    filters.scroll,
    filters.clicks,
    filters.frustrations,
    filters.hesitations,
    filters.insights,
  ]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          userSelect: "none",
        }}
      >
        <button
          onClick={() => setPathModalOpen(true)}
          className="btn-quaternary fw-700"
        >
          Change the path
        </button>
        {CustomPaginator(page, scrollToPage, totalPages)}
      </div>

      <div ref={trackerRef} id="mouseMoveTracker">
        <div
          ref={ref}
          style={{
            width: "calc(100% + 80px)",
            userSelect: "none",
            paddingRight: "calc(100% - 570px)",
          }}
          {...events}
          className="hide-scrollbar heatmap-container flex overflow-x-scroll scrollbar-hide"
        >
          {funnelLoaded ? (
            funnel.map((item, i) => {
              return (
                <div
                  id={"heatmap" + i}
                  key={i}
                  className={`${
                    i === 0
                      ? "rounded-left"
                      : i === funnel.length - 1
                        ? "rounded-right"
                        : ""
                  } heatmap-item flex-none`}
                >
                  <div
                    className={`${
                      i === 0
                        ? "rounded-left"
                        : i === funnel.length - 1
                          ? "rounded-right"
                          : ""
                    }`}
                  >
                    <div
                      className={`heatmap-item-page ${
                        i === funnel.length - 1 ? "border-right" : ""
                      }`}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="step-page-container">
                          <div className="fs-16 fw-500 lh224 fc-black">
                            STEP {i + 1}
                          </div>
                          <div className="fs-18 fw-900 lh252 fc-black word-wrapper">
                            {item.page}
                          </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              marginRight:
                                i < funnel.length - 1 ? "24px" : "0px",
                              borderRight:
                                i < funnel.length - 1
                                  ? "1px solid #E0E0E0"
                                  : "none",
                            }}
                          >
                            <div
                              style={{ minWidth: "86px" }}
                              className="fs-16 fw-500 lh224 fc-grey"
                            >
                              Sessions
                            </div>
                            <div className="fs-18 fw-900 lh252 fc-black word-wrapper">
                              {item.sessions}
                            </div>
                          </div>
                          {i < funnel.length - 1 && (
                            <div>
                              <div
                                style={{ minWidth: "55px" }}
                                className="fs-16 fw-500 lh224 fc-grey"
                              >
                                Dropoff
                              </div>
                              <div className="fs-18 fw-900 lh252 fc-black word-wrapper">
                                {Math.round(item.dropoff * 10000) / 100}%
                              </div>
                            </div>
                          )}
                          {i === 0 && (
                            <button className="btn-quaternary heatmap-play-btn">
                              <FiPlay size="25px"></FiPlay>
                            </button>
                          )}

                          <button
                            style={{
                              marginLeft: i === 0 ? "0px" : "32px",
                            }}
                            className="btn-quaternary fw-500 fs-14 heatmap-goto-insights"
                          >
                            Go to insights
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      id={`heatmap-page-${i}`}
                      style={{
                        position: "relative",
                        width: "650px",
                        minHeight: "2px",
                      }}
                    >
                      {imgSrcs[i] ? (
                        <img
                          src={imgSrcs[i]}
                          alt="heatmap page"
                          style={{
                            width: "100%",
                            pointerEvents: "none",
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      <div
                        className={
                          i === 0 ? "heatmap-overlay" : "heatmap-overlay-line"
                        }
                      ></div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <Spinner style={{ height: "70px", width: "70px" }} />
          )}
        </div>
      </div>
    </>
  );
}
