import "./CreateProject.css";
import MainLayout from "../MainLayout";
import CustomCarousel from "../../components/CustomCarousel/CustomCarousel";
import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  getIndustryTags,
  uploadFileToFirestore,
  userExists,
  verifyGoogleAnalytics,
  getUserInformation,
  urlIsValid,
  paymentSquare2,
  getUserProjects,
  backToDashboard,
  HTTPSurlIsValid,
  verifyMatomo,
} from "../../helpers";
import { FiArrowRight, FiPlus, FiX } from "react-icons/fi";
import { HiPlus } from "react-icons/hi";
import GaLogo from "../../assets/gaLogo";
import CustomInput from "../CustomInput/CustomInput";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "../../firebase";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import gearIcon from "../../assets/gear-icon.png";
import step1 from "../../assets/step1.png";
import step2 from "../../assets/step2.png";
import step3 from "../../assets/step3.png";
import step4 from "../../assets/step4.png";
import step5 from "../../assets/step5.png";
import copy from "../../assets/copy.png";

import mstep1 from "../../assets/mstep1.png";
import mstep2 from "../../assets/mstep2.png";
import mstep21 from "../../assets/mstep2-1.png";
import mstep3 from "../../assets/mstep3.png";
import mstep4 from "../../assets/mstep4.png";
import mstep5 from "../../assets/mstep5.png";
import mstep6 from "../../assets/mstep6.png";
import mstep7 from "../../assets/mstep7.png";
import mstep8 from "../../assets/mstep8.png";
import matomoLogo from "../../assets/matomologo.png";

import PaymentModal from "../PaymentModal/PaymentModal";
import { ToastContainer, toast } from "react-toastify";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

function CreateProject() {
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);
  const [projectStatus, setProjectStatus] = useState("live");
  const [projectId, setProjectId] = useState("");
  const [cachedPropertyId, setCachedPropertyId] = useState("");
  const [isSSO, setIsSSO] = useState(false);
  const [ssoError, setSSOError] = useState();
  const [logo, setLogo] = useState();

  const [cachedProjectId, setCachedProjectId] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [userLinks, setUserLinks] = useState([""]);
  const [projectName, setProjectName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);

  const [errMsg, setErrMsg] = useState("");

  const [copyFocused, setCopyFocused] = useState(false);

  const [pagesAnalyzed, setPagesAnalyzed] = useState(1);
  const [maxPagesAnalyzed, setMaxPagesAnalyzed] = useState(1);
  const [userLinksValid, setUserLinksValid] = useState(false);

  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState(null);

  const [userInfo, setUserInfo] = useState({});

  const [userProjectsCount, setUserProjectsCount] = useState(null);
  const [maxUserProjectsCount, setMaxUserProjectsCount] = useState(0);

  const [platformOptions, setPlatformOptions] = useState([
    { label: "Google analytics", value: "GA4" },
    {
      label: "Matomo",
      value: "matomo",
    },
  ]);
  const [platform, setPlatform] = useState(platformOptions[0]);
  const [platformOpen, setPlatformOpen] = useState(false);

  const [matomoUrl, setMatomoUrl] = useState("");
  const [matomoId, setMatomoId] = useState("");
  const [matomoApiToken, setMatomoApiToken] = useState("");

  useEffect(() => {
    setErrMsg("");
  }, [platform]);

  useEffect(() => {
    if (userInfo && ["owner", "editor", "reader"].includes(userInfo.role)) {
      navigate("/");
    }
  }, [userInfo]);

  useEffect(() => {
    if (auth?.currentUser?.uid) {
      getUserInformation().then((userInfo) => {
        if (userInfo) {
          setUserInfo(userInfo);
          setPaymentModalIsOpen(!userInfo?.paymentPlan);
          if (userInfo?.paymentPlan) {
            setPaymentPlan(userInfo.paymentPlan);
          }
          getUserProjects(userInfo).then((projects) => {
            setUserProjectsCount(projects.length);
          });
        } else {
          setUserProjectsCount(0);
        }

        if (userInfo && userInfo.awaitingApproval) {
          navigate("/");
        }
      });
    }
  }, [auth.currentUser]);

  useEffect(() => {
    switch (paymentPlan) {
      case "start":
        setMaxPagesAnalyzed(3);
        setMaxUserProjectsCount(3);
        break;
      case "ultra":
        setMaxPagesAnalyzed(99);
        setMaxUserProjectsCount(99);
        break;
      case "corporate":
        setMaxPagesAnalyzed(99);
        setMaxUserProjectsCount(99);
        break;
      default:
        setMaxPagesAnalyzed(1);
        setMaxUserProjectsCount(1);
        break;
    }
  }, [paymentPlan]);

  /**
   * Handlers for copying the google analytics email.
   */
  const handleFocus = useCallback(() => {
    setCopyFocused(true);
  }, [setCopyFocused]);

  const handleBlur = useCallback(() => {
    setCopyFocused(false);
  }, [setCopyFocused]);

  function changeLinks(i, value) {
    const newLinks = [...userLinks];
    newLinks[i] = value;
    setUserLinks(newLinks);

    const formatsValid = newLinks.every((link) => HTTPSurlIsValid(link));

    try {
      const httpsUrl = "https://" + newLinks[0];
      const mainUrl = new URL(httpsUrl);
      const basedOnMainUrl = newLinks.slice(1).every((link) => {
        try {
          const linkUrl = new URL(
            link.startsWith("http") ? link : `https://${link}`,
          );
          return linkUrl.origin === mainUrl.origin;
        } catch (e) {
          return false;
        }
      });

      setUserLinksValid(formatsValid && basedOnMainUrl);
    } catch (e) {
      setUserLinksValid(false);
    }
  }

  // Upload the google analytics ID to the project.
  const updateProjectLinksGA4 = async () => {
    await updateDoc(doc(db, "projects", cachedProjectId), {
      googleAnalyticsId: projectId,
      platform: "GA4",
    });
    navigate("/", {
      state: {
        justCreatedProject: true,
        justCreatedProjectEnqueue: true,
        logo: logo ?? "",
        analysisId: cachedProjectId ?? "",
      },
    });
  };

  const updateProjectLinksMatomo = async () => {
    await updateDoc(doc(db, "projects", cachedProjectId), {
      matomoId: matomoId,
      matomoUrl: matomoUrl,
      matomoApiToken: matomoApiToken,
      platform: "matomo",
    });
    navigate("/", {
      state: {
        justCreatedProject: true,
        justCreatedProjectEnqueue: true,
        logo: logo ?? "",
        analysisId: cachedProjectId ?? "",
      },
    });
  };

  /**
   * Uploads the user's project to Firestore
   * @returns {Promise<void>}
   */
  const uploadProjectToFirestore = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (cachedProjectId) return resolve(null);
        setLoading(true);
        const newDocRef = doc(collection(db, "projects"));

        setCachedProjectId(newDocRef.id);

        if (!userLinks[0].endsWith("/")) userLinks[0] += "/";

        // Make URLs safe
        let cleanedLinks = userLinks.map((link) => {
          if (!link.startsWith("http://") && !link.startsWith("https://")) {
            return `https://${link}`;
          }
          return link;
        });

        let rootUrl = cleanedLinks[0];
        let otherUrls = cleanedLinks.slice(1);

        // Create the project document in the database
        await setDoc(newDocRef, {
          registrationDate: new Date(),
          name: projectName,
          rootUrl: rootUrl,
          otherUrls: otherUrls,
          clientId: auth.currentUser.uid,
          analysisState: 0,
          awaitingAnalysis: true,
        });

        // Upload & update project image
        let imageLink =
          "https://firebasestorage.googleapis.com/v0/b/ux-pro.appspot.com/o/users%2Fblack-logo.png?alt=media&token=e05881ad-fe52-4cbb-9cb5-0e366b03a8f6";

        if (selectedImage !== "") {
          imageLink = await uploadFileToFirestore(
            "projects/" + newDocRef.id,
            selectedImage,
          );
        }

        await updateDoc(newDocRef, {
          imageLink: imageLink,
        });

        setLogo(imageLink);

        // Clear the SSO error & unset loading
        setSSOError();
        setLoading(false);
        resolve({ id: newDocRef.id, img: imageLink });
      } catch (error) {
        setLoading(false);
        reject(error);
      }
    });
  };

  const [userInDb, setUserInDb] = useState(false);

  // Handle project image upload
  useEffect(() => {
    if (!selectedImage) {
      setImagePreview(undefined);
      return;
    }

    const imageUrl = URL.createObjectURL(selectedImage);
    setImagePreview(imageUrl);

    // Free memory whenever this component is unmounted
    return () => URL.revokeObjectURL(imageUrl);
  }, [selectedImage]);

  const userInDBFunc = async () => {
    if (!auth.currentUser) return;
    setUserInDb(await userExists());
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        userInDBFunc();
        return;
      } else {
        // V2 create project flow requires signed in user. Redirect to login if no user.
        navigate("/");
      }

      setIsSSO(false);
      setUserInDb(false);
    });
    userInDBFunc();
  }, []);

  const onSelectImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    setSelectedImage(e.target.files[0]);
  };

  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      setIsSSO(true);
    } catch (e) {}

    setSSOError();
  };

  const paymentPlanRendering = useCallback(
    () =>
      paymentPlan || userInfo?.paymentPlan ? (
        <div className="payment-square-wrapper">
          {paymentSquare2(
            paymentPlan || userInfo?.paymentPlan,
            setPaymentModalIsOpen,
          )}
        </div>
      ) : null,
    [paymentSquare2, paymentPlan, userInfo?.paymentPlan],
  );

  /**
   * These are the carousel elements
   * @type {[{duration: number, stepName: string, subtitle: string, title: string, content: React.JSX.Element},{duration: number, subtitleWidth: string, stepName: string, subtitle: string, title: string, content: React.JSX.Element},{duration: number, stepName: string, subtitle: string, title: string, content: React.JSX.Element},...[{duration: number, stepName: string, subtitle: string, title: string, content: React.JSX.Element}]|*[]]}
   */
  const carouselElements = [
    {
      title: "Provide the main links to analyze",
      stepName: "Provide links",
      duration: 2,
      subtitle:
        "Enter the main URL(s) you want to analyse. You can only add URLs that are linked to your organisation's main domain.",
      className: "provide-links-container",
      contentWidth: userProjectsCount !== null ? "100%" : "504px",
      subtitleWidth: "449px",
      sidebar: <>{paymentPlanRendering()}</>,
      content: (
        <>
          {userProjectsCount !== null ? (
            <>
              {userProjectsCount < maxUserProjectsCount ? (
                <>
                  <div>
                    <div
                      className="my-4 w-100 d-flex flex-column"
                      style={{ padding: "0px 1px" }}
                    >
                      {userLinks.map((link, i) => (
                        <div style={{ position: "relative", width: "504px" }}>
                          <CustomInput
                            key={i}
                            type="text"
                            placeholder={
                              i < 1
                                ? "Main domain of the project"
                                : "Other urls"
                            }
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(
                                /^https?:\/\//,
                                "",
                              );
                              changeLinks(i, e.target.value);
                            }}
                            valueState={userLinks[i]}
                            isurlinput={"true"}
                          />
                          {i > 0 && (
                            <button
                              onClick={() => {
                                const newUserLinks = userLinks.filter(
                                  (_, index) => index !== i,
                                );
                                setUserLinks(newUserLinks);
                                setUserLinksValid(
                                  newUserLinks.every((link) =>
                                    HTTPSurlIsValid(link),
                                  ),
                                );
                                setPagesAnalyzed(pagesAnalyzed - 1);
                              }}
                              type="button"
                              style={{
                                position: "absolute",
                                right: "-5px",
                                top: "10px",
                                backgroundColor: "transparent",
                              }}
                            >
                              <i className="fa-solid fa-xmark fs-20 fc-black"></i>
                            </button>
                          )}
                        </div>
                      ))}
                    </div>

                    {!userLinksValid && userLinks[0] && (
                      <div
                        className="password-mismatch-text-on"
                        style={{ marginTop: "-15px", marginLeft: "5px" }}
                      >
                        One or more URLs are invalid. All URLs must belong to
                        the same domain.
                      </div>
                    )}
                    {pagesAnalyzed < maxPagesAnalyzed && (
                      <button
                        type="button"
                        variant="btn-secondary"
                        onClick={() => {
                          setUserLinks([...userLinks, ""]);
                          setUserLinksValid(false);
                          setPagesAnalyzed(pagesAnalyzed + 1);
                        }}
                        className="d-flex align-items-center btn-tertiary my-4"
                        style={{ width: "172px" }}
                        disabled={
                          !userLinks[userLinks.length - 1] ||
                          /^\s*$/.test(userLinks[0])
                        }
                      >
                        <HiPlus style={{ marginRight: "14px" }} />
                        Add another link
                      </button>
                    )}
                  </div>
                  <div
                    className="create-project-footer  d-flex justify-content-end my-4"
                    style={{ width: "504px" }}
                  >
                    <button
                      type="button"
                      onClick={() => setIndex(1)}
                      className="btn-primary d-flex align-items-center create-project-continue-btn"
                      disabled={userLinks.length === 0 || !userLinksValid}
                    >
                      Continue
                      <FiArrowRight
                        size="17.78px"
                        style={{
                          marginLeft: "12px",
                          marginTop: "2px",
                        }}
                      />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="fs-20">
                    You have reached the maximum number of projects for your
                    payment plan.
                  </div>
                  <div style={{ width: "160px" }}>
                    {backToDashboard(navigate)}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <Spinner style={{ width: "25px", height: "25px" }} />
            </>
          )}
        </>
      ),
    },
    {
      title: "Give crunchy details to your project",
      stepName: "More information",
      duration: 2,
      subtitle:
        "Give your project an identity by choosing a name and an illustrative image.",
      content: (
        <div style={{ height: "720px" }}>
          <div>
            <div
              className="w-100 d-flex flex-column"
              style={{ padding: "0px 1px", marginTop: "32px" }}
            >
              <h4 className="fw-500 fs-18 lh-243">Project name</h4>
              <h5 className="project-name-caption s16-medium">
                Give your project a clear name. You may have several projects in
                the same company.
              </h5>
              <CustomInput
                valueState={projectName}
                type="text"
                placeholder="Give your project a name"
                onChange={(e) => setProjectName(e.target.value)}
                disabled={loading}
              />
            </div>

            <div
              className="mb-4 w-100"
              style={{ padding: "0px 1px", marginTop: "32px" }}
            >
              <h4>Logo / picture of your project</h4>
              <h5
                style={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  width: "449px",
                }}
                className="project-name-caption s16-medium"
              >
                To be sure to have a good image, use jpeg or jpg in min 150x150
                px.
              </h5>

              {!selectedImage && (
                <>
                  <button
                    type="button"
                    className="btn-tertiary d-flex align-items-center my-3"
                    onClick={() =>
                      document.getElementById("project-image-upload").click()
                    }
                  >
                    <FiPlus className="me-3" size="18px" />
                    Upload image
                  </button>
                  <input
                    type="file"
                    id="project-image-upload"
                    style={{ display: "none" }}
                    onChange={onSelectImage}
                    accept=".jpeg, .jpg"
                  />
                </>
              )}
              {selectedImage && (
                <>
                  <button
                    type="button"
                    className="btn-tertiary d-flex align-items-center my-3"
                    disabled={loading}
                  >
                    <img
                      src={imagePreview}
                      alt="Project"
                      className="project-image-preview me-3"
                    />
                    <div className="project-image-name">
                      {selectedImage.name}
                    </div>
                    <FiX
                      className="ms-3"
                      size="18px"
                      onClick={() => setSelectedImage()}
                    />
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end my-4">
            {!loading && (
              <button
                type="button"
                className="btn-secondary me-3"
                onClick={() => setIndex(index - 1)}
              >
                Back
              </button>
            )}
            <button
              type="button"
              onClick={async () => {
                uploadProjectToFirestore().then(() => {
                  setIndex(2);
                });
              }}
              className="btn-primary d-flex align-items-center create-project-continue-btn"
              disabled={
                loading || [projectName].some((field) => /^\s*$/.test(field))
              }
            >
              {loading ? (
                <Spinner style={{ width: "25px", height: "25px" }} />
              ) : (
                <>
                  {projectStatus === "live" ? "Continue" : "Finish"}
                  <FiArrowRight
                    size="17.78px"
                    style={{ marginLeft: "12px", marginTop: "2px" }}
                  />
                </>
              )}
            </button>
          </div>
        </div>
      ),
    },
    ...(projectStatus === "live"
      ? [
          {
            title: "Connect APIs",
            stepName: "Connect API's",
            duration: 5,
            subtitle:
              "To better track your conversion progress, we recommend that you give us access to your analytics systems. Simply enter your tool's API so that we can access your statistics. This will only allow us to retrieve data. This option is not mandatory right now.",
            content: (
              <div>
                <div>
                  <div>
                    <div
                      style={{ marginTop: "32px" }}
                      className="fw-500 fs-18 lh-243 txt-b"
                    >
                      Analytics
                    </div>
                    <div className="fw-500 fs-16 lh-243 fc-grey">
                      Select the analytics tool you use to collect data on your
                      digital product.
                    </div>

                    <div
                      className="no-margins-or-padding"
                      style={{ marginBottom: "50px" }}
                    >
                      <div style={{ marginTop: "16px" }}>
                        <CustomDropdown
                          options={platformOptions}
                          onChange={(option) => {
                            setPlatform(option);
                          }}
                          value={platform}
                          placeholder="Analytics tool"
                          unstyled
                          menuOpenState={platformOpen}
                          setMenuOpenState={(state) => {
                            setPlatformOpen(state);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {platform.value === "GA4" ? (
                    <>
                      <div className="fw-500 fs-18 lh-243 txt-b">How to do</div>
                      <div className="api-list api-list-spacing">
                        <div>
                          1. Go to{" "}
                          <a
                            href="https://analytics.google.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://analytics.google.com/
                          </a>
                        </div>
                        <div>
                          2. Bottom left, click on the “Administration” wheel
                        </div>
                        <div>
                          <img
                            src={gearIcon}
                            style={{ height: "20px", width: "20px" }}
                            alt="gear-icon"
                          ></img>
                        </div>
                        <div>3. Click on “Account access management”</div>
                        <div>
                          <img
                            src={step1}
                            style={{ width: "504px", height: "214px" }}
                            alt="step1"
                          ></img>
                        </div>
                        <div>4. Click on “Add button” top right</div>
                        <div>
                          <img
                            src={step2}
                            style={{ width: "504px", height: "154.87px" }}
                            alt="step1"
                          ></img>
                        </div>
                        <div>
                          5. Click and copy this url. It is our authentified and
                          secure Google
                          <p>
                            Analytics address to connect with your Google
                            Analytics.
                          </p>
                        </div>
                        <div style={{ marginTop: "0px" }}>
                          <div
                            style={{
                              height: "44px",
                              transition: "all 300ms ease-in-out",
                              color: copyFocused ? "black" : "transparent",
                              background: copyFocused
                                ? "#F7F7F7"
                                : "transparent",
                              width: "185px",
                              borderRadius: "12px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "absolute",
                              left: "525px",
                            }}
                          >
                            Copied to clipboard
                          </div>
                          <div style={{ marginTop: "16px", height: "46px" }}>
                            <CustomInput
                              readOnly
                              placeholder="gaintegration@ux-pro.iam.gserviceaccount.com"
                              inputClassNames="copiable-input"
                              valueState={""}
                              customHeight={"46px"}
                              containerClassName="w-100"
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  "gaintegration@ux-pro.iam.gserviceaccount.com",
                                );
                              }}
                            />
                          </div>
                          <div style={{ position: "relative" }}>
                            <img alt="copy" src={copy} className="api-copy" />
                          </div>
                        </div>
                        <div>
                          6. Paste the url in the Email field.
                          <p> Select “Reader” role.</p>
                          <p>Click on “Add” top right”.</p>
                        </div>
                        <div>
                          <img
                            src={step3}
                            style={{ width: "504px", height: "254.62px" }}
                            alt="step1"
                          ></img>
                        </div>
                        <div>
                          7. Go back to your Dashboard.
                          <p>Click on your account in the main bar.</p>
                        </div>
                        <div>
                          <img
                            src={step4}
                            style={{ width: "504px", height: "254.48px" }}
                            alt="step1"
                          ></img>
                        </div>
                        <div>
                          8. Select the right account in the list and look for
                          the number below
                          <p>the account.</p>
                        </div>
                        <div>
                          <img
                            src={step5}
                            style={{ width: "504px", height: "254.92px" }}
                            alt="step1"
                          ></img>
                        </div>
                        <div>
                          9. Copy / Paste that number below. This will allow us
                          to connect
                          <p>properly with your data.</p>
                        </div>
                      </div>
                      <div
                        className="mt-1 mb-4 w-100 d-flex flex-column"
                        style={{ padding: "0px 1px" }}
                      >
                        <CustomInput
                          logo={<GaLogo />}
                          placeholder="Enter project id"
                          onChange={(e) => {
                            if (links.length > 0) {
                              setLinks([]);
                              setErrMsg("");
                            }
                            setProjectId(e.target.value);
                          }}
                          valueState={projectId}
                          disabled={loading}
                          containerClassName="google-analytics-input-container"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="fw-500 fs-18 lh-243 txt-b">How to do</div>
                      <div className="api-list api-list-spacing">
                        <div>
                          1. Go to the top bar of matomo, on the left there will
                          be a gear icon.
                        </div>
                        <div>
                          <img src={mstep1} alt="step1" />
                        </div>
                        <div>
                          2. Depending on your user role, you will see
                          ”Measurables” or
                          <p>
                            “Websites” on the left bar. Expand it and click on
                            ”Tracking code”
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <img src={mstep2} alt="step2" />
                          <div className="vhc"> Or </div>
                          <img src={mstep21} alt="step21" />
                        </div>
                        <div>
                          3. Scroll down and you will find the siteId in a
                          section containing
                          <p>javascript code. Enter this number below.</p>
                        </div>
                        <div>
                          <img
                            src={mstep3}
                            alt="step3"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                        <div
                          className="mt-1 mb-4 w-100 d-flex flex-column"
                          style={{ padding: "0px 1px" }}
                        >
                          <CustomInput
                            logo={
                              <img
                                src={matomoLogo}
                                style={{ width: "30px", height: "30px" }}
                              ></img>
                            }
                            placeholder="Enter Matomo Site id"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value)) {
                                setErrMsg("");
                                setMatomoId(value);
                              }
                            }}
                            valueState={matomoId}
                            disabled={loading}
                            containerClassName="google-analytics-input-container"
                          />
                        </div>
                        <div>
                          4. On the admin page, go to the left and expand the
                          "Personal"
                          <p>section and click on "Security"</p>
                        </div>
                        <div>
                          <img src={mstep4} alt="step4" />
                        </div>
                        <div>5. Click on “CREATE NEW TOKEN”</div>
                        <div>
                          <img
                            src={mstep5}
                            alt="step5"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                        <div>
                          6. Create a new token with any description you want.
                          Make sure
                          <p>
                            secure requests is checked and create the token.
                          </p>
                        </div>
                        <div>
                          <img
                            src={mstep6}
                            alt="step6"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                        <div>
                          7. Click on the top right of the grey box to copy it
                          and then paste the
                          <p>
                            token below. The green button will save the token to
                            Matomo.
                          </p>
                        </div>
                        <div>
                          <img
                            src={mstep7}
                            alt="step7"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                        <div
                          className="mt-1 mb-4 w-100 d-flex flex-column"
                          style={{ padding: "0px 1px" }}
                        >
                          <CustomInput
                            logo={
                              <img
                                src={matomoLogo}
                                style={{ width: "30px", height: "30px" }}
                              ></img>
                            }
                            placeholder="Enter Matomo Api token"
                            onChange={(e) => {
                              setErrMsg("");
                              setMatomoApiToken(e.target.value);
                            }}
                            valueState={matomoApiToken}
                            disabled={loading}
                            containerClassName="google-analytics-input-container"
                          />
                        </div>
                        <div>
                          8. Go to your address bar and copy the url up to the /
                          <p>
                            Paste this url below. Make sure it starts with
                            https://
                          </p>
                        </div>
                        <div>
                          <img
                            src={mstep8}
                            alt="step8"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      </div>
                      <div
                        className="mt-1 mb-4 w-100 d-flex flex-column"
                        style={{ padding: "0px 1px" }}
                      >
                        <CustomInput
                          logo={
                            <img
                              src={matomoLogo}
                              style={{ width: "30px", height: "30px" }}
                            ></img>
                          }
                          placeholder="Enter Matomo URL"
                          onChange={(e) => {
                            setErrMsg("");
                            setMatomoUrl(e.target.value);
                          }}
                          valueState={matomoUrl}
                          disabled={loading}
                          containerClassName="google-analytics-input-container"
                        />
                      </div>
                    </>
                  )}
                </div>
                {errMsg && (
                  <div>
                    <h4
                      style={{
                        fontSize: "15px",
                        color:
                          errMsg === "Success! Project setup verified"
                            ? "#00FF66"
                            : "#ff3333",
                      }}
                    >
                      {errMsg}
                    </h4>
                  </div>
                )}
                <div className="row">
                  <div className="col">
                    {!links.length && (
                      <button
                        type="button"
                        variant="btn-secondary"
                        onClick={async () => {
                          setLoading(true);

                          if (platform.value === "GA4") {
                            const response = await verifyGoogleAnalytics({
                              projectId: projectId,
                            });
                            if (typeof response !== "string") {
                              setErrMsg("Success! Project setup verified");
                              setCachedPropertyId(projectId);
                            } else {
                              setErrMsg("Setup Error: " + response);
                            }
                          }

                          if (platform.value === "matomo") {
                            const response = await verifyMatomo({
                              params: {
                                siteId: matomoId,
                                matomoUrl: matomoUrl,
                                apiToken: matomoApiToken,
                              },
                            });

                            if (response === 1) {
                              setErrMsg("Success! Project setup verified");
                            } else {
                              setErrMsg(
                                "There was an error verifying the setup. Please check your inputs.",
                              );
                            }
                          }

                          setLoading(false);
                        }}
                        className="d-flex align-items-center btn-secondary my-4"
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner style={{ width: "25px", height: "25px" }} />
                        ) : (
                          "Verify Setup"
                        )}
                      </button>
                    )}
                  </div>

                  <div className="col w-100 d-flex justify-content-end my-4">
                    <button
                      type="button"
                      className="btn-secondary me-3"
                      onClick={() => setIndex(index - 1)}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        if (
                          platform.value === "GA4" &&
                          cachedProjectId &&
                          logo
                        ) {
                          await updateProjectLinksGA4();
                        }
                        if (
                          platform.value === "matomo" &&
                          cachedProjectId &&
                          logo
                        ) {
                          await updateProjectLinksMatomo();
                        }
                      }}
                      disabled={loading || !cachedProjectId || !logo}
                      className="btn-primary d-flex align-items-center"
                    >
                      Finish
                      <FiArrowRight
                        size="17.78px"
                        style={{ marginLeft: "12px", marginTop: "2px" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <MainLayout isEmptyBar>
      <ToastContainer />
      <PaymentModal
        isOpen={paymentModalIsOpen}
        setIsOpen={setPaymentModalIsOpen}
        setPaymentPlan={setPaymentPlan}
        closable={userInfo?.paymentPlan ? true : false}
        {...(userInfo ? { userInfo, setUserInfo } : {})}
      />
      <div style={{ paddingLeft: "190px" }}>
        <Form>
          <CustomCarousel elements={carouselElements} index={index} />
        </Form>
      </div>
    </MainLayout>
  );
}

export default CreateProject;
