import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { RTDB, auth, db, functions, storage } from "./firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  setDoc,
  onSnapshot,
  updateDoc,
  addDoc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { MdCheckCircle } from "react-icons/md";
import { components } from "react-select";
import { GiCircle, GiPlainCircle } from "react-icons/gi";
import { Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import gaDashboardLogo from "./assets/ga-dashboard-logo.png";
import { BsPlusLg } from "react-icons/bs";
import { Timestamp } from "firebase/firestore";
import x from "./assets/xw.png";
import luxi from "./assets/small-icon-blue.png";
import { Link } from "react-router-dom";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { set } from "firebase/database";
import { ref as rtref } from "firebase/database";

const ADMIN_ID = "ERUhn7JKx6QLp3flbFAAJo7e7E92";

/**
 * Uploading another file with the same name to the same path will overwrite the original file
 */
export const uploadFileToFirestore = async (path, file) => {
  const storageRef = ref(storage, `${path}/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      },
    );
  });
};

/**
 * Retrieve the user information from firestore auth
 */

export async function getUserChannels(userInfo) {
  const userChannels = [];
  const querySnapshot = await getDocs(
    query(
      collection(db, "channels"),
      where("parties", "array-contains", userInfo.uid),
    ),
  );

  for (const doc of querySnapshot.docs) {
    const data = doc.data();
    const otherUserData = await getOtherUserFromParty(
      data.parties,
      userInfo.uid,
    );
    if (otherUserData) {
      userChannels.push({
        id: doc.id,
        ...data,

        icon: otherUserData.pfp,
        title: otherUserData.firstName,
      });
    }
  }
  return userChannels;
}

export function subscribeToUserChannels(userInfo, callback) {
  const unsubscribe = onSnapshot(
    query(
      collection(db, "channels"),
      where("parties", "array-contains", userInfo.uid),
    ),
    async (querySnapshot) => {
      let userChannels = {};

      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        const otherUserData = await getOtherUserFromParty(
          data.parties,
          userInfo.uid,
        );
        if (
          otherUserData &&
          data.newMessagesCount &&
          data.newMessagesCount[userInfo.uid]
        ) {
          userChannels[doc.id] = data.newMessagesCount[userInfo.uid];
        }
      }
      callback(userChannels); // Call the callback with the updated data
    },
  );

  return unsubscribe; // Return the unsubscribe function to allow stopping the subscription
}

/**
 * Retrieve the user information from firestore auth
 * @param uid
 * @returns {Promise<unknown>}
 */

export async function userProjectsPromise(uid) {
  return new Promise(function (resolve, reject) {
    const userProjects = [];
    getDocs(query(collection(db, "projects"), where("clientId", "==", uid)))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (!doc.data().deleted) {
            userProjects.push({
              label: doc.data().name,
              value: doc.id,
              image: doc.data().imageLink,
              ...doc.data(),
            });
          }
        });
        resolve(userProjects);
      })
      .catch((error) => {
        console.error("Error getting projects: ", error);
      });
  });
}

/**
 * Retrieve the user information from firestore auth
 * @param pid
 * @returns {Promise<unknown>}
 */

function userProjectsPromisePid(pid) {
  if (!pid) return Promise.resolve([]);
  return new Promise(function (resolve, reject) {
    const userProjects = [];
    getDoc(doc(db, "projects", pid))
      .then((doc) => {
        if (!doc.exists) {
          reject("No document found with the given pid");
        } else if (!doc.data().deleted) {
          userProjects.push({
            label: doc.data().name,
            value: doc.id,
            image: doc.data().imageLink,
            ...doc.data(),
          });
        }
        resolve(userProjects);
      })
      .catch((error) => {
        console.error("Error getting projects: ", error);
      });
  });
}

/**
 * Retrieve the user information from firestore auth, using userProjectsPromise
 * @param uid - user id of the client
 * @returns {Promise<unknown>}
 */
export async function getUserProjects(userInfo) {
  // Role as client will also work as designer
  try {
    if (["client", "designer"].includes(userInfo.role)) {
      return await userProjectsPromise(userInfo.uid);
    } else {
      const userProjects = await userProjectsPromise(userInfo.uid);
      const workspaceProject = await userProjectsPromisePid(userInfo.workspace);
      return [...userProjects, ...workspaceProject];
    }
  } catch (error) {
    console.error("Error getting projects: ", error);
  }
}

/**
 * Get the projects of the designer
 * @param uid - user id of the designer
 * @returns {Promise<unknown>}
 */
export async function getProjectsOfDesigner(uid) {
  return new Promise(function (resolve, reject) {
    const designerProjects = [];
    getDocs(
      query(collection(db, "projects"), where("designerId", "==", uid)),
    ).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        designerProjects.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      resolve(designerProjects);
    });
  });
}

export async function getClientOfProject(pid) {
  const projectDoc = doc(db, "projects", pid);
  const project = await getDoc(projectDoc);
  return project.data().clientId;
}

/**
 * Get the contents of the user document from firestore
 * @returns {Promise<{[p: string]: any, uid: string}>} - Promise containing the user object.
 */
export async function getUserInformation() {
  // If the user signs in with a google account,
  // name should be auth.currentUser.displayName
  // pfp should be auth.currentUser.photoURL
  if (!auth?.currentUser?.uid) {
    return;
  }
  const userDoc = doc(db, "users", auth.currentUser.uid);
  const user = await getDoc(userDoc);
  if (!user.exists()) {
    return;
  }
  const userData = user.data();
  let firstName = userData.firstName ?? "User";
  let lastName = userData.lastName ?? "";
  let pfp = userData.pfp ?? "";
  if (userData?.role === "designer") {
    if (userData?.personData?.firstName !== "") {
      firstName = userData.personData.firstName;
      lastName = userData.personData.lastName;
    } else if (userData?.companyData?.companyName !== "") {
      firstName = userData.companyData.companyName;
      lastName = "";
    }
  }
  if (userData?.role === "client" && userData?.isGoogleAccount) {
    lastName = ""; // Google accounts use full name format.
    pfp = auth.currentUser.photoURL;
  }

  return {
    ...user.data(),
    uid: auth.currentUser.uid,
    firstName: firstName,
    lastName: lastName,
    pfp: pfp,
  };
}

export async function getOtherUserUid(uid) {
  const userDoc = doc(db, "users", uid);
  const userSnapshot = await getDoc(userDoc);
  if (!userSnapshot.exists()) {
    return null;
  }
  const userData = userSnapshot.data();

  if (!userData || !userData.pfp || !userData.firstName) {
    return null;
  }
  return userData;
}

export async function getOtherUserFromParty(parties, uid) {
  const otherUid = parties.filter((party) => party !== uid)[0];

  if (!otherUid) {
    return null;
  }

  const userData = await getOtherUserUid(otherUid);
  return userData;
}

/**
 * Function to check if the user exists
 * @returns {Promise<boolean>} - does the user exist?
 */
export async function userExists() {
  const userDoc = doc(db, "users", auth.currentUser.uid);
  const user = await getDoc(userDoc);
  return user.exists();
}

/**
 * Declare the handler for the HTTPS callable function, verifyGoogleAnalytics.
 * @type {HttpsCallable}
 * @argument {Object} data - The data to be passed to the function.
 */
const verifyGoogleAnalyticsCloudFunction = httpsCallable(
  functions,
  "verifyGoogleAnalyticsEU-1",
);

/**
 * Declare the handler for the HTTPS callable function, getAnalyticsData.
 * @type {HttpsCallable}
 * @argument {Object} data - The data to be passed to the function.
 */
const getAnalyticsDataCloudFunction = httpsCallable(
  functions,
  "getAnalyticsDataEU",
);

const getGTMDataCloudFunction = httpsCallable(functions, "getTagManagerDataEU");
const enqueueAnalysisCloudFunction = httpsCallable(
  functions,
  "enqueueAnalysisEU",
);

// Deletes chat messages, notifications and rulesCheckResults
const deleteUserDataCloudFunction = httpsCallable(
  functions,
  "deleteUserDataEU",
);
export async function deleteUserDataGCP(data) {
  const response = await deleteUserDataCloudFunction(data);
  return response.data;
}

const getInvitedWorkspaceCloudFunction = httpsCallable(
  functions,
  "getInvitedWorkspaceEU",
);
export async function getInvitedWorkspace(data) {
  const response = await getInvitedWorkspaceCloudFunction(data);
  return response.data;
}

const getPathFromMatomoCloudFunction = httpsCallable(
  functions,
  "buildPathWithMatomoEU",
);
export async function getPathFromMatomo(data) {
  const response = await getPathFromMatomoCloudFunction(data);
  return response.data;
}

const runGA4ReportCloudFunction = httpsCallable(functions, "runGA4ReportEU");
export async function runGA4Report(data) {
  const response = await runGA4ReportCloudFunction(data);
  return response.data;
}

const startAnalysisMaestroCloudFunction = httpsCallable(
  functions,
  "startAnalysisMaestroEU",
);
export async function startAnalysisMaestro(data) {
  const response = await startAnalysisMaestroCloudFunction(data);
  return response.data;
}

const getMatomoDropdownFieldsCloudFunction = httpsCallable(
  functions,
  "getMatomoDropdownFieldsEU",
);
export async function getMatomoDropdownFields(data) {
  const response = await getMatomoDropdownFieldsCloudFunction(data);
  return response.data;
}

const getMatomoHeatmapDropdownFieldsCloudFunction = httpsCallable(
  functions,
  "getMatomoHeatmapDropdownFieldsEU",
);
export async function getMatomoHeatmapDropdownFields(data) {
  const response = await getMatomoHeatmapDropdownFieldsCloudFunction(data);
  return response.data;
}

const runFunnelReportCloudFunction = httpsCallable(
  functions,
  "runFunnelReportEU",
);
export async function runFunnelReport(data) {
  const response = await runFunnelReportCloudFunction(data);
  return response.data;
}

const verifyMatomoCloudFunction = httpsCallable(functions, "verifyMatomoEU");
export async function verifyMatomo(data) {
  const response = await verifyMatomoCloudFunction(data);
  return response.data;
}

/**
 * Map of error codes to error messages.
 * @type {{3: string, 7: string}}
 */
const errCodeMap = {
  3: "Invalid Project ID",
  7: "No access to project, verify the account is authorized.",
};

/**
 * Call the verifyGoogleAnalytics cloud function.
 * @param data - The arguments to be passed to the function.
 * @returns {Promise<*>} - Object containing the pages of the website.
 */
export async function verifyGoogleAnalytics(data) {
  const response = await verifyGoogleAnalyticsCloudFunction(data);
  if (!response.data) {
    return "Unknown error";
  }
  if (response.data?.error) {
    return errCodeMap[response.data.error];
  }
  return JSON.parse(response.data);
}

/**
 * Call the getAnalyticsData cloud function.
 * @param data - The arguments to be passed to the function.
 * @returns {Promise<{page_name: {metric: {date_range_1: {dataType: string, data: float}, date_range_2: {dataType: string, data: float}}}}>} - The analytics are passed on a page-per-page basis.
 */
export async function getAnalyticsData(data, type = "GA4") {
  try {
    let response;
    if (type === "GA4") {
      response = await getAnalyticsDataCloudFunction(data);
      if (!response.data.pages) return errCodeMap[response.data.error];
      return JSON.parse(response.data.pages);
    } else if (type === "matomo") {
      response = await getMatomoAnalyticsDataCloudFunction(data);
      return response.data;
    } else {
      return [];
    }
  } catch (e) {
    return;
  }
}

export async function getGTMData(data) {
  const response = await getGTMDataCloudFunction(data);
  return response.data.data;
}

export async function enqueueAnalysis(data) {
  const response = await enqueueAnalysisCloudFunction(data);
  return response.data;
}

const sendMailchimpEmailCloudFunction = httpsCallable(
  functions,
  "sendMailchimpEmailEU",
);
export async function sendMailchimpEmail(data) {
  const response = await sendMailchimpEmailCloudFunction(data);
  return response.data;
}

const getMetricsUrlsCloudFunction = httpsCallable(
  functions,
  "getMetricsUrlsEU",
);

const getMatomoAnalyticsDataCloudFunction = httpsCallable(
  functions,
  "getAnalyticsDataMatomoEU",
);
export async function getMatomoAnalyticsData(data) {
  const response = await getMatomoAnalyticsDataCloudFunction(data);
  return response;
}

export async function getMetricsUrls(data) {
  const response = await getMetricsUrlsCloudFunction(data);
  let result = "";

  try {
    if (data.platform === "GA4") {
      result = JSON.parse(response.data);
    } else if (data.platform === "matomo") {
      result = response.data;
    }
  } catch (e) {}

  return result;
}

export function getChartInsights(projectId, type) {
  return new Promise((resolve, reject) => {
    getDocs(
      query(
        collection(db, "insights"),
        where("projectId", "==", projectId),
        where("metric", "==", type),
      ),
    )
      .then((insightsQuerySnapshot) => {
        const insightsTemp = [];
        try {
          insightsQuerySnapshot.docs.forEach((insightDoc) => {
            insightsTemp.push({
              ...insightDoc.data(),
              id: insightDoc.id,
              date: dateFirestoreToReact(insightDoc.data().createdAt),
            });
          });
          resolve(insightsTemp);
        } catch (error) {
          reject(error);
        }
      })
      .catch(reject);
  });
}

export async function getInsightsPid(projectId) {
  const insightsQuerySnapshot = await getDocs(
    query(collection(db, "insights"), where("projectId", "==", projectId)),
  );
  const insightsTemp = [];
  for (const insightDoc of insightsQuerySnapshot.docs) {
    insightsTemp.push({
      ...insightDoc.data(),
      id: insightDoc.id,
    });
  }
  return insightsTemp;
}

export async function getInsightsWithProjectId(pid) {
  const insightsQuery = query(
    collection(db, "insights"),
    where("projectId", "==", pid),
  );
  const insightsSnapshot = await getDocs(insightsQuery);
  const projectSnapshot = await getDoc(doc(db, "projects", pid));

  const insights = await Promise.all(
    insightsSnapshot?.docs?.map(async (d) => {
      const insightData = d.data();
      if (!insightData || !insightData.projectId) {
        return null;
      }

      insightData.status = "Validated";

      return {
        id: d.id,
        project: {
          value: pid,
          ...projectSnapshot.data(),
        },
        ...insightData,
      };
    }),
  );
  return insights;
}

export async function deleteInsightsFastDoc(trueId) {
  try {
    const insightFastQuery = query(
      collection(db, "insightsFast"),
      where("trueId", "==", trueId),
    );

    const querySnapshot = await getDocs(insightFastQuery);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      await deleteDoc(doc.ref);
    }
  } catch (e) {}
}

export async function cloneInsights() {
  const rulesCheckResultsQuery = query(collection(db, "rulesChecksResults"));
  const insightsQuery = query(collection(db, "insights"));

  const [rulesCheckResultsSnapshot, insightsSnapshot] = await Promise.all([
    getDocs(rulesCheckResultsQuery),
    getDocs(insightsQuery),
  ]);

  const combinedDocs = [
    ...rulesCheckResultsSnapshot.docs,
    ...insightsSnapshot.docs,
  ];

  for (const docSnapshot of combinedDocs) {
    const newDocRef = doc(collection(db, "insightsFast"));
    const resultData = docSnapshot.data();

    // Don't clone broken insights
    if (!resultData.projectId) {
      continue;
    }

    let status = docSnapshot.data().status ?? null;
    if (!status) {
      if (resultData.source === "hardCoded") {
        status =
          resultData.recommendation && resultData.recommendation.length > 0
            ? 100
            : 1;
      }

      if (resultData.source === "IBM") {
        status =
          resultData.aiRecommendationGeneral &&
          resultData.aiRecommendationGeneral.length > 0
            ? 100
            : 1;
      }
    }

    if (!status && !resultData.source) {
      status = "Validated";
    }
    if (!status) {
      status = "Error";
    }

    const data = {
      trueId: docSnapshot.id,
      projectId: docSnapshot.data().projectId ?? "",
      source: docSnapshot.data().source ?? "",
      title: docSnapshot.data().title ?? "",
      ruleId: docSnapshot.data().ruleId ?? "",
      url: docSnapshot.data().pageName || docSnapshot.data().url || "/",
      status: status,
    };

    if (docSnapshot.data().date) {
      data.date = docSnapshot.data().date;
    }
    if (docSnapshot.data().createdAt) {
      data.date = docSnapshot.data().createdAt;
    }
    if (docSnapshot.data().verifyDate) {
      data.verifyDate = docSnapshot.data().verifyDate;
    }
    if (docSnapshot.data().num) {
      data.num = docSnapshot.data().num;
    }

    await setDoc(newDocRef, data);
  }
}

export async function getFastInsightsWithPid(pid) {
  let q;
  if (pid === "All") {
    q = collection(db, "insightsFast");
  } else {
    q = query(collection(db, "insightsFast"), where("projectId", "==", pid));
  }

  const snapshot = await getDocs(q);
  const allDocs = snapshot.docs;

  const projectSnapshot = await getDoc(doc(db, "projects", pid));

  const res = await Promise.all(
    allDocs.map(async (d) => {
      const insight = d.data();
      if (!insight || !insight.projectId) {
        return null;
      }

      switch (insight.status) {
        case "in-progress":
        case "complete":
        case insight.num && insight.num > 0:
          insight.status = "Validated";
          break;
        case 1:
          insight.status = "Missing AI recommendation";
          break;
        case 100:
          insight.status = "Not yet validated";
          break;
        default:
          break;
      }

      return {
        ...insight,
        id: d.id,
        num: insight.num || "X",
        title:
          insight.title ||
          getHardcodedInsightFields(insight.ruleId)?.title ||
          "Error",
        project: {
          value: pid,
          ...projectSnapshot.data(),
        },
      };
    }),
  );
  return res;
}

/**
 * Get the data within a report document
 * @param reportDoc
 * @returns {Promise<{date: string, clientFName: any, projects: any, clientId: any, submitted: (boolean|*), clientLName: any, reportId, insights: number, projectName: any, projectId}>} - Promise containing the Report object.
 */
export async function getReportData(reportDoc) {
  const date = reportDoc.data().createdAt.toDate();
  const insightsSnapshot = await getDocs(collection(reportDoc.ref, "insights"));
  const project = await getDoc(doc(db, "projects", reportDoc.data().projectId));
  const client = await getDoc(doc(db, "users", project.data().clientId));

  return {
    date: `${date.toLocaleString("default", {
      month: "long",
    })} ${date.getFullYear()}`,
    insights: insightsSnapshot.size,
    projectId: reportDoc.data().projectId,
    projectName: project.data().name,
    projects: project.data().imageLink,
    clientId: project.data().clientId,
    clientFName: client.data().firstName,
    clientLName: client.data().lastName,
    reportId: reportDoc.id,
    submitted: reportDoc.data().submitted,
    designerId: reportDoc.data().designerId,
    projectDeleted: project.data().deleted || false,
  };
}

/**
 * Get the insights associated with a report document
 * @param reportDoc - Report document
 * @param idx - Report index (to be attached to the returned object)
 * @returns {Promise<unknown>}
 */
export async function getInsightData(reportDoc, index) {
  return new Promise(function (resolve, reject) {
    const insightsSnapshot = getDocs(collection(reportDoc.ref, "insights"));
    const insights = [];

    insightsSnapshot
      .then((querySnapshot) => {
        const promises = querySnapshot.docs.map((doc) => {
          let status = "";
          return getDocs(collection(doc.ref, "actions"))
            .then((actionsSnapshot) => {
              let allComplete = actionsSnapshot.docs.every(
                (aDoc) => aDoc.data().status === "complete",
              );
              if (allComplete) {
                status = "complete";
              } else {
                actionsSnapshot.forEach((aDoc) => {
                  if (aDoc.data().status === "in-progress") {
                    status = "in-progress";
                  }
                });
              }
              return status;
            })
            .then((status) => {
              insights.push({
                date: new Date(doc.data().createdAt.toDate().getTime()),
                reportId: reportDoc.id,
                pageName: doc.data().pageName,
                fixEase: getLabel(doc.data().fixEase),
                severity: getLabel(doc.data().severity),
                title: doc.data().title,
                id: doc.id,
                reportnumber: index + 1,
                status: status,
              });
            });
        });

        return Promise.all(promises);
      })
      .then(() => {
        resolve(
          insights
            .sort((a, b) => a.date - b.date)
            .map((insight, idx) => {
              return { ...insight, ninreport: idx + 1 };
            })
            .reverse(),
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getLabel(val) {
  const options = [
    { value: "very-easy", label: "Very easy to fix" },
    { value: "easy", label: "Easy to fix" },
    { value: "challenging", label: "Challenging to fix" },
    { value: "very-challenging", label: "Very challenging to fix" },
    { value: "unfixable", label: "Unfixable" },
    { value: "not-usability", label: "Not a usability problem" },
    { value: "cosmetic", label: "Cosmetic" },
    { value: "minor", label: "Minor" },
    { value: "major", label: "Major" },
    { value: "critical", label: "Critical" },
    { value: "user-tests", label: "User tests" },
    { value: "expert-review", label: "Expert review" },
    { value: "heuristic-evaluation", label: "Heuristic evaluation" },
    { value: "analytics", label: "Analytics" },
    { value: "ab-testing", label: "A/B testing" },
    { value: "other", label: "Other" },
  ];
  const res = options.find((option) => option.value === val);
  return val ? (res ? res.label : val) : "N/A";
}

export function calcDaysLeft(regDate) {
  // Get number of days between today and one month ahead of registration date
  const dueDate = new Date(
    regDate.toDate().getTime() + 30 * 24 * 60 * 60 * 1000,
  );
  return Math.ceil((dueDate - new Date().getTime()) / (1000 * 3600 * 24));
}

export function addOneMonth(regDate) {
  // Add one month and format date
  let date = regDate.toDate();
  date.setMonth(date.getMonth() + 1);

  let options = { year: "numeric", month: "long", day: "numeric" };
  let formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
}

export const modifyDropdowns = (num, property, newValue, setDropdowns) => {
  setDropdowns((prevDropdowns) => {
    const updatedDropdowns = [...prevDropdowns];
    updatedDropdowns[num] = {
      ...updatedDropdowns[num],
      [property]: newValue,
    };
    return updatedDropdowns;
  });
};

export function removeDuplicates(objects) {
  return objects.filter((obj, index, self) => {
    const value = obj["value"] == null ? "null" : obj["value"];
    return (
      self.findIndex(
        (item) => (item["value"] == null ? "null" : item["value"]) === value,
      ) === index
    );
  });
}

/**
 * Returns an array containing industry tag objects.
 */
export function getIndustryTags() {
  return {
    None: { value: false, icon: "" },
    Aerospace: { value: false, icon: <i className="fa-regular fa-ufo"></i> },
    Agricultural: {
      value: false,
      icon: <i className="fa-regular fa-tractor"></i>,
    },
    Automotive: {
      value: false,
      icon: <i className="fa-regular fa-car-side"></i>,
    },
    Chemical: { value: false, icon: <i className="fa-regular fa-flask"></i> },
    Computer: { value: false, icon: <i className="fa-regular fa-laptop"></i> },
    Construction: {
      value: false,
      icon: <i className="fa-regular fa-block-brick"></i>,
    },
    Creative: { value: false, icon: <i className="fa-regular fa-brush"></i> },
    Cultural: {
      value: false,
      icon: <i className="fa-regular fa-masks-theater"></i>,
    },
    Defense: { value: false, icon: <i className="fa-regular fa-shield"></i> },
    Education: { value: false, icon: <i className="fa-regular fa-book"></i> },
    "Electric power": {
      value: false,
      icon: <i className="fa-regular fa-book"></i>,
    },
    Electronics: {
      value: false,
      icon: <i className="fa-regular fa-microchip"></i>,
    },
    Energy: {
      value: false,
      icon: <i className="fa-solid fa-fire-flame-simple"></i>,
    },
    Engineering: {
      value: false,
      icon: <i className="fa-regular fa-ruler"></i>,
    },
    Entertainment: {
      value: false,
      icon: <i className="fa-regular fa-party-horn"></i>,
    },
    Farming: { value: false, icon: <i className="fa-regular fa-sheep"></i> },
    Fashion: {
      value: false,
      icon: <i className="fa-regular fa-vest-patches"></i>,
    },
    Film: { value: false, icon: <i className="fa-regular fa-film-simple"></i> },
    "Financial services": {
      value: false,
      icon: <i className="fa-regular fa-sack-dollar"></i>,
    },
    Food: {
      value: false,
      icon: <i className="fa-regular fa-cart-shopping"></i>,
    },
    Gambling: { value: false, icon: <i className="fa-regular fa-dice"></i> },
    Green: { value: false, icon: <i className="fa-regular fa-seedling"></i> },
    "Health services": {
      value: false,
      icon: <i className="fa-regular fa-notes-medical"></i>,
    },
    Hotels: { value: false, icon: <i className="fa-regular fa-hotel"></i> },
    Information: {
      value: false,
      icon: <i className="fa-regular fa-newspaper"></i>,
    },
    Infrastructure: {
      value: false,
      icon: <i className="fa-regular fa-road-bridge"></i>,
    },
    Insurance: {
      value: false,
      icon: <i className="fa-regular fa-shield-check"></i>,
    },
    Jewelry: { value: false, icon: <i className="fa-regular fa-gem"></i> },
    Leisure: { value: false, icon: <i className="fa-regular fa-heart"></i> },
    Manufacturing: {
      value: false,
      icon: <i className="fa-regular fa-industry"></i>,
    },
    Media: {
      value: false,
      icon: <i className="fa-regular fa-rectangle-ad"></i>,
    },
    Merchandising: {
      value: false,
      icon: <i className="fa-regular fa-shop"></i>,
    },
    Mining: { value: false, icon: <i className="fa-regular fa-pickaxe"></i> },
    Music: { value: false, icon: <i className="fa-regular fa-headphones"></i> },
    "Nature & Environment": {
      value: false,
      icon: <i className="fa-sharp fa-regular fa-frog"></i>,
    },
    Pharmaceutical: {
      value: false,
      icon: <i className="fa-regular fa-mortar-pestle"></i>,
    },
    Professional: {
      value: false,
      icon: <i className="fa-regular fa-briefcase"></i>,
    },
    Railway: {
      value: false,
      icon: <i className="fa-regular fa-train-track"></i>,
    },
    "Real estate": {
      value: false,
      icon: <i className="fa-sharp fa-regular fa-igloo"></i>,
    },
    Retail: {
      value: false,
      icon: <i className="fa-regular fa-washing-machine"></i>,
    },
    Scientific: {
      value: false,
      icon: <i className="fa-regular fa-atom-simple"></i>,
    },
    Services: { value: false, icon: <i className="fa-regular fa-soap"></i> },
    Sex: { value: false, icon: <i className="fa-regular fa-eggplant"></i> },
    Software: { value: false, icon: <i className="fa-regular fa-code"></i> },
    Space: {
      value: false,
      icon: <i className="fa-regular fa-planet-ringed"></i>,
    },
    Sport: {
      value: false,
      icon: <i className="fa-regular fa-tennis-ball"></i>,
    },
    Technology: {
      value: false,
      icon: <i className="fa-regular fa-memory"></i>,
    },
    Telecommunications: {
      value: false,
      icon: <i className="fa-regular fa-satellite-dish"></i>,
    },
    Textile: { value: false, icon: <i className="fa-regular fa-shirt"></i> },
    Tobacco: { value: false, icon: <i className="fa-regular fa-smoking"></i> },
    Transport: {
      value: false,
      icon: <i className="fa-regular fa-baby-carriage"></i>,
    },
    Utility: {
      value: false,
      icon: <i className="fa-solid fa-utility-pole"></i>,
    },
    "Video game": {
      value: false,
      icon: <i className="fa-regular fa-gamepad"></i>,
    },
    Water: {
      value: false,
      icon: <i className="fa-sharp fa-regular fa-droplet"></i>,
    },
    Wood: { value: false, icon: <i className="fa-regular fa-tree"></i> },
  };
}

export function validateDateForPicker(value) {
  // Return valid date object or "" if cannot be converted
  if (!value || isNaN(Date.parse(value))) return "";
  const date = value instanceof Date ? value : new Date(value?.toDate());
  return !isNaN(date.getTime()) ? date : "";
}

export function formatDateForPicker(value) {
  if (value === "") return "";
  const date = new Date(value);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
}

export const checkCircle = (props) => (
  <components.Option {...props}>
    {props.isSelected ? (
      <MdCheckCircle size={24} color="var(--main-blue)" />
    ) : (
      <GiCircle size={24} color="var(--main-blue)" />
    )}{" "}
    <h5
      style={{
        marginBottom: "0",
        marginLeft: "10px",
      }}
    >
      {props.data.label}
    </h5>
  </components.Option>
);

export const getMetricsDescriptions = () => {
  return {
    active1DayUsers:
      "The number of distinct active users on your site or app within a 1 day period. The 1 day period includes the last day in the report's date range. Note: this is the same as Active Users.",
    active28DayUsers:
      "The number of distinct active users on your site or app within a 28 day period. The 28 day period includes the last day in the report's date range.",
    active7DayUsers:
      "The number of distinct active users on your site or app within a 7 day period. The 7 day period includes the last day in the report's date range.",
    activeUsers: "The number of distinct users who visited your site or app.",
    adUnitExposure:
      "The time that an ad unit was exposed to a user, in milliseconds.",
    addToCarts:
      "The number of times users added items to their shopping carts.",
    advertiserAdClicks:
      "Total number of times users have clicked on an ad to reach the property. Includes clicks from linked integrations like linked Search Ads 360 advertisers. Also includes uploaded clicks from data import.",
    advertiserAdCost:
      "The total amount you paid for your ads. Includes costs from linked integrations like linked Google Ads accounts. Also includes uploaded cost from data import; to learn more, see Import cost data.",
    advertiserAdCostPerClick:
      "Ads cost per click is ad cost divided by ad clicks and is often abbreviated CPC.",
    advertiserAdCostPerConversion:
      "Cost per conversion is ad cost divided by conversions.",
    advertiserAdImpressions:
      "The total number of impressions. Includes impressions from linked integrations like linked Display & Video 360 advertisers. Also includes uploaded impressions from data import.",
    averagePurchaseRevenue:
      "The average purchase revenue in the transaction group of events.",
    averagePurchaseRevenuePerPayingUser:
      "Average revenue per paying user (ARPPU) is the total purchase revenue per active user that logged a purchase event. The summary metric is for the time period selected.",
    averagePurchaseRevenuePerUser:
      "The average purchase revenue per active user is the total purchase revenue per active user that logged any event. The summary metric is for the time period selected.",
    averageRevenuePerUser:
      "Average revenue per active user (ARPU). The summary metric is for the time period selected. ARPU uses Total Revenue and includes AdMob estimated earnings.",
    averageSessionDuration:
      "The average duration (in seconds) of users` sessions.",
    bounceRate:
      "The percentage of sessions that were not engaged ((Sessions Minus Engaged sessions) divided by Sessions). This metric is returned as a fraction; for example, 0.2761 means 27.61% of sessions were bounces.",
    cartToViewRate:
      "The number of users who added a product(s) to their cart divided by the number of users who viewed the same product(s). This metric is returned as a fraction; for example, 0.1132 means 11.32% of users who viewed a product also added the same product to their cart.",
    checkouts:
      "The number of times users started the checkout process. This metric counts the occurrence of the begin_checkout event.",
    cohortActiveUsers:
      "The number of users in the cohort who are active in the time window corresponding to the cohort nth day/week/month. For example for the row where cohortNthWeek = 0001, this metric is the number of users (in the cohort) who are active in week 1.",
    cohortTotalUsers:
      "The total number of users in the cohort. This metric is the same value in every row of the report for each cohort. Because cohorts are defined by a shared acquisition date, cohortTotalUsers is the same as cohortActiveUsers for the cohort's selection date range. For report rows later than the cohort's selection range, it is typical for cohortActiveUsers to be smaller than cohortTotalUsers. This difference represents users from the cohort that were not active for the later date. cohortTotalUsers is commonly used in the metric expression cohortActiveUsers/cohortTotalUsers to compute a user retention fraction for the cohort. The relationship between activeUsers and totalUsers is not equivalent to the relationship between cohortActiveUsers and cohortTotalUsers.",
    conversions:
      "The count of conversion events. Events are marked as conversions at collection time; changes to an event's conversion marking apply going forward. You can mark any event as a conversion in Google Analytics, and some events (i.e., first_open, purchase) are marked as conversions by default. To learn more, see About conversions.",
    crashAffectedUsers:
      "The number of users that logged a crash in this row of the report. For example, if the report is time series by date, this metrics reports total users with at least one crash on this date. Crashes are events with the name 'app_exception'.",
    crashFreeUsersRate:
      "The number of users without crash events (in this row of the report) divided by the total number of users. This metric is returned as a fraction; for example, 0.9243 means 92.43% of users were crash-free.",
    dauPerMau:
      "The rolling percent of 30-day active users who are also 1-day active users. This metric is returned as a fraction; for example, 0.113 means 11.3% of 30-day active users were also 1-day active users.",
    dauPerWau:
      "The rolling percent of 7-day active users who are also 1-day active users. This metric is returned as a fraction; for example, 0.082 means 8.2% of 7-day active users were also 1-day active users.",
    ecommercePurchases:
      "The number of times users completed a purchase. This metric counts purchase events; this metric does not count in_app_purchase and subscription events.",
    engagedSessions:
      "The number of sessions that lasted longer than 10 seconds, or had a conversion event, or had 2 or more screen views.",
    engagementRate:
      "The percentage of engaged sessions (Engaged sessions divided by Sessions). This metric is returned as a fraction; for example, 0.7239 means 72.39% of sessions were engaged sessions.",
    eventCount: "The count of events.",
    eventCountPerUser:
      "The average number of events per user (Event count divided by Active users).",
    eventValue: "The sum of the event parameter named 'value'.",
    eventsPerSession:
      "The average number of events per session (Event count divided by Sessions).",
    firstTimePurchaserConversionRate:
      "The percentage of active users who made their first purchase. This metric is returned as a fraction; for example, 0.092 means 9.2% of active users were first-time purchasers.",
    firstTimePurchasers:
      "The number of users that completed their first purchase event.",
    firstTimePurchasersPerNewUser:
      "The average number of first-time purchasers per new user.",
    grossItemRevenue:
      "The total revenue from items only. Gross item revenue is the product of its price and quantity. Item revenue excludes tax and shipping values; tax & shipping values are specified at the event and not item level. Gross item revenue does not include refunds.",
    grossPurchaseRevenue:
      "The sum of revenue from purchases made in your app or site. Gross purchase revenue sums the revenue for these events: purchase, ecommerce_purchase, in_app_purchase, app_store_subscription_convert, and app_store_subscription_renew. Purchase revenue is specified by the 'value' parameter in tagging.",
    itemDiscountAmount:
      "The monetary value of item discounts in eCommerce events. This metric is populated in tagging by the 'discount' item parameter.",
    itemListClickEvents:
      "The number of times users clicked an item when it appeared in a list. This metric counts the occurrence of the select_item event.",
    itemListClickThroughRate:
      "The number of users who selected a list(s) divided by the number of users who viewed the same list(s). This metric is returned as a fraction; for example, 0.2145 means 21.45% of users who viewed a list also selected the same list.",
    itemListViewEvents:
      "The number of times the item list was viewed. This metric counts the occurrence of the view_item_list event.",
    itemPromotionClickThroughRate:
      "The number of users who selected a promotion(s) divided by the number of users who viewed the same promotion(s). This metric is returned as a fraction; for example, 0.1382 means 13.82% of users who viewed a promotion also selected the promotion.",
    itemRefundAmount:
      "Item refund amount is the total refunded transaction revenue from items only. Item refund amount is the product of price and quantity for the refund event.",
    itemRevenue:
      "The total revenue from purchases minus refunded transaction revenue from items only. Item revenue is the product of its price and quantity. Item revenue excludes tax and shipping values; tax & shipping values are specified at the event and not item level.",
    itemViewEvents:
      "The number of times the item details were viewed. The metric counts the occurrence of the view_item event.",
    itemsAddedToCart:
      "The number of units added to cart for a single item. This metric counts the quantity of items in add_to_cart events.",
    itemsCheckedOut:
      "The number of units checked out for a single item. This metric counts the quantity of items in begin_checkout events.",
    itemsClickedInList:
      "The number of units clicked in list for a single item. This metric counts the quantity of items in select_item events.",
    itemsClickedInPromotion:
      "The number of units clicked in promotion for a single item. This metric counts the quantity of items in select_promotion events.",
    itemsPurchased:
      "The number of units for a single item included in purchase events. This metric counts the quantity of items in purchase events.",
    itemsViewed:
      "The number of units viewed for a single item. This metric counts the quantity of items in view_item events.",
    itemsViewedInList:
      "The number of units viewed in list for a single item. This metric counts the quantity of items in view_item_list events.",
    itemsViewedInPromotion:
      "The number of units viewed in promotion for a single item. This metric counts the quantity of items in view_promotion events.",
    newUsers:
      "The number of users who interacted with your site or launched your app for the first time (event triggered: first_open or first_visit).",
    organicGoogleSearchAveragePosition:
      "The average ranking of your website URLs for the query reported from Search Console. For example, if your site's URL appears at position 3 for one query and position 7 for another query, the average position would be 5 (3+7/2). This metric requires an active Search Console link.",
    organicGoogleSearchClickThroughRate:
      "The organic Google Search click through rate reported from Search Console. Click through rate is clicks per impression. This metric is returned as a fraction; for example, 0.0588 means about 5.88% of impressions resulted in a click. This metric requires an active Search Console link.",
    organicGoogleSearchClicks:
      "The number of organic Google Search clicks reported from Search Console. This metric requires an active Search Console link.",
    organicGoogleSearchImpressions:
      "The number of organic Google Search impressions reported from Search Console. This metric requires an active Search Console link.",
    promotionClicks:
      "The number of times an item promotion was clicked. This metric counts the occurrence of the select_promotion event.",
    promotionViews:
      "The number of times an item promotion was viewed. This metric counts the occurrence of the view_promotion event.",
    publisherAdClicks: "The number of ad_click events.",
    publisherAdImpressions: "The number of ad_impression events.",
    purchaseRevenue:
      "The sum of revenue from purchases minus refunded transaction revenue made in your app or site. Purchase revenue sums the revenue for these events: purchase, ecommerce_purchase, in_app_purchase, app_store_subscription_convert, and app_store_subscription_renew. Purchase revenue is specified by the 'value' parameter in tagging.",
    purchaseToViewRate:
      "The number of users who purchased a product(s) divided by the number of users who viewed the same product(s). This metric is returned as a fraction; for example, 0.128 means 12.8% of users that viewed a product(s) also purchased the same product(s).",
    purchaserConversionRate:
      "The percentage of active users who made 1 or more purchase transactions. This metric is returned as a fraction; for example, 0.412 means 41.2% of users were purchasers.",
    refundAmount:
      "The total refunded transaction revenues. Refund amount sums refunded revenue for the refund and app_store_refund events.",
    returnOnAdSpend:
      "Return On Ad Spend (ROAS) is total revenue divided by advertiser ad cost.",
    screenPageViews:
      "The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events).",
    screenPageViewsPerSession:
      "The number of app screens or web pages your users viewed per session. Repeated views of a single page or screen are counted. (screen_view + page_view events) / sessions.",
    screenPageViewsPerUser:
      "The number of app screens or web pages your users viewed per active user. Repeated views of a single page or screen are counted. (screen_view + page_view events) / active users.",
    scrolledUsers:
      "The number of unique users who scrolled down at least 90% of the page.",
    sessionConversionRate:
      "The percentage of sessions in which any conversion event was triggered.",
    sessions:
      "The number of sessions that began on your site or app (event triggered: session_start).",
    sessionsPerUser:
      "The average number of sessions per user (Sessions divided by Active Users).",
    shippingAmount:
      "Shipping amount associated with a transaction. Populated by the shipping event parameter.",
    taxAmount:
      "Tax amount associated with a transaction. Populated by the tax event parameter.",
    totalAdRevenue:
      "The total advertising revenue from both Admob and third-party sources.",
    totalPurchasers:
      "The number of users that logged purchase events for the time period selected.",
    totalRevenue:
      "The sum of revenue from purchases, subscriptions, and advertising (Purchase revenue plus Subscription revenue plus Ad revenue) minus refunded transaction revenue.",
    totalUsers:
      "The number of distinct users who have logged at least one event, regardless of whether the site or app was in use when that event was logged.",
    transactions:
      "The count of transaction events with purchase revenue. Transaction events are in_app_purchase, ecommerce_purchase, purchase, app_store_subscription_renew, app_store_subscription_convert, and refund.",
    transactionsPerPurchaser:
      "The average number of transactions per purchaser.",
    userConversionRate:
      "The percentage of users who triggered any conversion event.",
    userEngagementDuration:
      "The total amount of time (in seconds) your website or app was in the foreground of users` devices.",
    wauPerMau:
      "The rolling percent of 30-day active users who are also 7-day active users. This metric is returned as a fraction; for example, 0.234 means 23.4% of 30-day active users were also 7-day active users.",
  };
};

export function getMetricsQuery(
  userMetricsCards, // Can be client or designer cards
  commercialMetricsCards,
) {
  return {
    pages: [
      ...(userMetricsCards ?? [])?.map((card) => card.path),
      ...(commercialMetricsCards ?? [])?.map((card) => card.path),
    ].filter((value, index, self) => self.indexOf(value) === index),
    pageMetrics: userMetricsCards //grey cards
      ?.map((card) => card.metricType)
      .filter((value, index, self) => self.indexOf(value) === index),
    commercialMetrics: commercialMetricsCards //blue cards
      ?.map((card) => card.metricType)
      .filter((value, index, self) => self.indexOf(value) === index),
  };
}

export function getMetricsQueryMatomo(
  userMetricsCards,
  commercialMetricsCards,
) {
  const filterMetrics = (metrics) => {
    return metrics.map(({ metricType, path }) => ({ metricType, path }));
  };

  return {
    pageMetrics: filterMetrics(userMetricsCards),
    commercialMetrics: filterMetrics(commercialMetricsCards),
  };
}
export function getGTMQuery(GTMCards) {
  return {
    name: GTMCards?.map((card) => card.name) //blue cards
      .filter((value, index, self) => self.indexOf(value) === index),
  };
}

export async function fetchAnalyticsData(id, metricsQuery) {
  return await getAnalyticsData({
    projectId: id,
    pages: [...metricsQuery.pages],
    pageMetrics: [...(metricsQuery.pageMetrics ?? [])], //grey cards
    commercialMetrics: [...(metricsQuery.commercialMetrics ?? [])], //blue cards
    dateRanges: [
      ...(metricsQuery.dateRanges ?? [
        {
          startDate: "yesterday",
          endDate: "yesterday",
        },
        {
          startDate: "30daysAgo",
          endDate: "30daysAgo",
        },
        {
          startDate: "2daysAgo", // This is backup for the start date
          endDate: "2daysAgo", // in case GA4 data is uneven or unavailable
        },
      ]),
    ],
  });
}

export async function fetchAnalyticsDataMatomo(
  id,
  url,
  apiToken,
  metricsQuery,
) {
  return new Promise(async (resolve) => {
    const response = await getAnalyticsData(
      {
        params: {
          apiToken: apiToken,
          matomoUrl: url,
          siteId: id,
        },
        query: metricsQuery,
        view: "card",
      },
      "matomo",
    );

    resolve(response);
  });
}

export async function fetchGTMData(id, tagNames) {
  // Example usage:
  /*
  fetchGTMData("367579235", 
    [
      "Cta_Click",
      "Clique_sur_bouton_1",
      "Clique_sur_bouton_Bientot_disponible",
    ]);
    */

  const data = await getGTMData({
    projectId: id,
    tagNames: [...tagNames],
    dateRanges: [
      {
        startDate: "yesterday",
        endDate: "yesterday",
      },
      {
        startDate: "30daysAgo",
        endDate: "30daysAgo",
      },
    ],
  });

  const res = JSON.parse(data).rows;
  const result = res.reduce((acc, item) => {
    const [eventName, dateRange] = item.dimensionValues.map((d) => d.value);
    const eventCount = item.metricValues[0].value;

    acc[eventName] = {
      ...(acc[eventName] || {}),
      [dateRange]: eventCount,
    };

    return acc;
  }, {});

  return result;
}

export async function fetchAnalyticsChartData(
  id,
  page,
  commercialMetric,
  date,
) {
  return await getAnalyticsData({
    projectId: id,
    pages: [page],
    pageMetrics: [...[]], //grey cards
    commercialMetrics: [...([commercialMetric] ?? [])], //blue cards
    dateRanges: [
      ...[
        {
          startDate: `${date}daysAgo`,
          endDate: `${date}daysAgo`,
        },
        {
          startDate: `${date + 1}daysAgo`,
          endDate: `${date + 1}daysAgo`,
        },
        {
          startDate: `${date + 2}daysAgo`,
          endDate: `${date + 2}daysAgo`,
        },
        {
          startDate: `${date + 3}daysAgo`,
          endDate: `${date + 3}daysAgo`,
        },
      ],
    ],
  });
}

export async function fetchAnalyticsMatomoChartData(
  id,
  url,
  apiToken,
  metricsQuery,
  days,
) {
  return new Promise(async (resolve) => {
    const response = await getAnalyticsData(
      {
        params: {
          apiToken: apiToken,
          matomoUrl: url,
          siteId: id,
        },
        query: metricsQuery,
        view: "chart",
        days: days,
      },
      "matomo",
    );
    resolve(response);
  });
}

export async function fetchGTMChartData(id, tagName, date) {
  const data = await getGTMData({
    projectId: id,
    tagNames: [...([tagName] ?? [])],
    dateRanges: [
      ...[
        {
          startDate: `${date}daysAgo`,
          endDate: `${date}daysAgo`,
        },
        {
          startDate: `${date + 1}daysAgo`,
          endDate: `${date + 1}daysAgo`,
        },
        {
          startDate: `${date + 2}daysAgo`,
          endDate: `${date + 2}daysAgo`,
        },
        {
          startDate: `${date + 3}daysAgo`,
          endDate: `${date + 3}daysAgo`,
        },
      ],
    ],
  });

  if (!data) {
    return [];
  }

  const res = JSON.parse(data).rows;
  const result = res.reduce((acc, item) => {
    const [eventName, dateRange] = item.dimensionValues.map((d) => d.value);
    const eventCount = item.metricValues[0].value;

    acc[eventName] = {
      ...(acc[eventName] || {}),
      [dateRange]: eventCount,
    };

    return acc;
  }, {});

  return result;
}

// Grey cards
export function buildMetricsCardElements(queryLoading, metricsData, card) {
  // Calculate the value and delta.
  const isLoading = !hasMetricsData(metricsData);
  let valueData = null,
    value = null,
    valueString = queryLoading ? "Loading..." : "No Metric Found",
    valueDecimal = "",
    deltaData = null,
    delta = null,
    deltaString = "",
    deltaDecimal = "",
    missingDateRangeTwo = false;
  if (!isLoading) {
    if (
      card.path &&
      card.metricType &&
      metricsData &&
      metricsData[card.path] &&
      metricsData[card.path][card.metricType]
    ) {
      value = metricsData[card.path][card.metricType]["date_range_0"] ??
        metricsData[card.path][card.metricType]["date_range_2"] ?? {
          dataType: "TYPE_INTEGER",
          data: 0,
        };

      delta = metricsData[card.path][card.metricType]["date_range_1"] ?? {
        dataType: "TYPE_INTEGER",
        data: 0,
      };
    } else {
      value = {
        dataType: "TYPE_INTEGER",
        data: 0,
      };

      delta = {
        dataType: "TYPE_INTEGER",
        data: 0,
      };
    }
    // To revert to old metrics calculations, remove date_range_2

    let isPercent =
      value?.dataType === "TYPE_FLOAT" || delta?.dataType === "TYPE_FLOAT";

    valueData = value?.data * (isPercent ? 100 : 1);
    deltaData =
      delta?.dataType === "TYPE_FLOAT"
        ? valueData - delta?.data * 100
        : (value.data / delta.data - 1) * 100;

    // Can happen if the above divides by 0
    if (isNaN(deltaData)) {
      deltaData = 0;
    }

    valueString = valueData.toString();
    deltaString = deltaData.toString();
    if (isPercent) {
      if (valueString.includes(".")) {
        valueDecimal = valueString.split(".")[1].slice(0, 2);
        valueString = valueString.split(".")[0];
      } else {
        valueDecimal = "00";
      }
    } else {
      let parts = valueString.split(".");
      valueString =
        parts[0] + (parts.length === 2 ? "." + parts[1].slice(0, 2) : "");
    }
    if (deltaString.includes(".")) {
      deltaDecimal = deltaString.split(".")[1].slice(0, 2);
      deltaString = deltaString.split(".")[0];
    } else {
      deltaDecimal = "00";
    }
    if (deltaString.includes("Infinity")) {
      deltaString = "100";
    }
  }

  let sign =
    deltaData === 0 ? "neutral" : deltaData > 0 ? "positive" : "negative";

  let signFlipped =
    deltaData === 0 ? "neutral" : deltaData > 0 ? "negative" : "positive";

  let flippedMetrics = getFlippedMetrics();

  return (
    <div className="d-flex align-items-start">
      <div className="percent">
        {isLoading ? "Loading..." : valueString}
        <span className="subpercent">
          {valueDecimal ? "," + valueDecimal + "%" : ""}
        </span>
      </div>
      {!isLoading && delta && (
        <div
          className={`custom-progress dashboard-card-substats ${
            flippedMetrics.includes(card.metricType) ? signFlipped : sign
          }`}
        >
          {isNaN(deltaData)
            ? ""
            : (sign === "positive" ? "+" : "") + deltaString}
          <span className="decimals2">
            {isNaN(deltaData) ? "N/A" : "," + deltaDecimal + "%"}
          </span>
        </div>
      )}
      {!queryLoading && missingDateRangeTwo && (
        <div className={`custom-progress dashboard-card-substats neutral`}>
          N/A
        </div>
      )}
    </div>
  );
}

async function deletePerformanceCard(card, cards, pid) {
  const cardsAfterRemoval = cards.filter((item) => item !== card);
  const projectDoc = doc(db, "projects", pid);
  const cardsWithoutGTM = cardsAfterRemoval.filter(
    (item) => item.path !== "Google Tag Manager",
  );

  if (card.path === "Google Tag Manager") {
    const tagManagerCards = cardsAfterRemoval.filter(
      (item) => item.path === "Google Tag Manager",
    );
    updateDoc(projectDoc, { tagManagerCards: tagManagerCards });
  } else {
    const uid = auth.currentUser.uid;
    const blueCards = cardsAfterRemoval.filter(
      (item) => item.path !== "Google Tag Manager",
    );
    setClientMetricsViewBlue(uid, pid, blueCards);
  }
  return cardsWithoutGTM;
}

// Check if array or object. Then return if has data.
function hasMetricsData(metricsData) {
  return Array.isArray(metricsData)
    ? metricsData.length > 0
    : Object.keys(metricsData || {}).length > 0;
}

// Blue cards
export function BuildPagesGrid({
  metricsLoading,
  filteredPagesCards,
  queryLoading,
  metricsData,
  canEdit,
  setTrackerModalOpen,
  GTMData,
  cards,
  setCards,
  pid = null,
  setFilteredCards,
}) {
  return (
    <div className="pages-grid">
      {metricsLoading ? (
        <div
          style={{ height: "112px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner />
        </div>
      ) : (
        <>
          {canEdit && (
            <div className="col flex-column dashboard-card-new d-flex align-items-center justify-content-center">
              <button
                onClick={() => setTrackerModalOpen(true)}
                style={{ marginRight: 0 }}
                className="unstyled-button"
              >
                <BsPlusLg
                  color="var(--main-blue)"
                  size="32px"
                  className="me-2"
                />
                <div className="dashboard-metrics-text">
                  Track performance of a new page
                </div>
              </button>
            </div>
          )}
          {filteredPagesCards?.map((card, index, arr) => {
            card.text1 = card.text1 ?? "You have";
            card.text2 = card.text2 ?? getMatomoDatatypeLabel(card.metricType);
            card.title = card.title ?? getMatomoDatatypeLabel(card.metricType);
            card.subtitle = card.subtitle ?? beautifyPath(card.path);

            let isLoading = !hasMetricsData(metricsData);
            let valueData = null,
              value = null,
              deltaData = null,
              delta = null,
              deltaString = isLoading ? "Loading..." : "No Metric Found",
              deltaDecimal = "";

            if (
              card.path &&
              card.metricType &&
              metricsData &&
              metricsData[card.path] &&
              metricsData[card.path][card.metricType]
            ) {
              value = metricsData[card.path][card.metricType]["date_range_0"] ??
                metricsData[card.path][card.metricType]["date_range_2"] ?? {
                  dataType: "TYPE_INTEGER",
                  data: 0,
                };

              delta = metricsData[card.path][card.metricType][
                "date_range_1"
              ] ?? {
                dataType: "TYPE_INTEGER",
                data: 0,
              };
            } else {
              value = {
                dataType: "TYPE_INTEGER",
                data: 0,
              };

              delta = {
                dataType: "TYPE_INTEGER",
                data: 0,
              };
            }

            // To revert to old metrics calculations, remove date_range_2

            let isPercent =
              value?.dataType === "TYPE_FLOAT" ||
              delta?.dataType === "TYPE_FLOAT" ||
              isPercentagedMetric(card.metricType);

            valueData = value?.data * (isPercent ? 100 : 1);
            deltaData = valueData - delta?.data * (isPercent ? 100 : 1);

            deltaString = deltaData.toString();
            if (isPercent) {
              if (deltaString.includes(".")) {
                deltaDecimal = deltaString.split(".")[1].slice(0, 2);
                deltaString = deltaString.split(".")[0];
              } else {
                deltaDecimal = "00";
              }
            }

            if (card.path === "Google Tag Manager") {
              let { firstPart, secondPart } = formatPercentageDiff(
                GTMData[card.name]?.["date_range_0"],
                GTMData[card.name]?.["date_range_1"],
              );
              valueData = secondPart;
              deltaData = firstPart;
              deltaString = deltaData.toString();
              if (deltaString === "-NaN" || deltaString === "+NaN") {
                deltaString = "0";
                deltaData = 0;
              }
            }

            let sign =
              deltaData === 0
                ? "neutral"
                : deltaData > 0
                ? "positive"
                : "negative";
            let notFound = //true;
              deltaString === "No Metric Found" || deltaString === "Loading...";
            let signFlipped =
              deltaData === 0
                ? "neutral"
                : deltaData > 0
                ? "negative"
                : "positive";
            let flippedMetrics = getFlippedMetrics();

            let signText = "";
            let txt1 = "";
            let txt2 = "";
            let dot = "";
            let cent = "";

            if (card.path === "Google Tag Manager") {
              signText =
                sign === "positive" ? "" : sign === "negative" ? "" : "";
              dot = ",";
              cent = "%";
              deltaDecimal = "00";
              txt1 =
                card.template?.split("<>")[0]?.trim() ?? card?.template ?? "";
              txt2 = card.template?.split("<>")[1]?.trim() ?? "";
            } else {
              if (deltaDecimal !== "") {
                dot = ",";
                cent = "%";
              }
              signText =
                sign === "positive" ? "+" : sign === "negative" ? "" : "";
            }

            const CardStats = ({ text1, text2 }) => (
              <span>
                {text1}{" "}
                <span
                  className={`dashboard-card-stats ${
                    flippedMetrics.includes(card.metricType)
                      ? signFlipped
                      : sign
                  } px-2 py-1`}
                >
                  {signText}
                  {deltaString}
                  {dot}
                  <span className="decimals">
                    {deltaDecimal}
                    {cent}
                  </span>
                </span>{" "}
                {text2}
              </span>
            );

            const CardBody = ({ text1, text2 }) => (
              <div className="body mt-3">
                {!notFound && !isLoading ? (
                  <CardStats text1={text1} text2={text2} />
                ) : isLoading ? (
                  "Loading..."
                ) : (
                  `${text2} not found`
                )}
              </div>
            );

            return (
              <div
                className={`${"dashboard-pages-card"} col d-flex flex-column justify-content-between`}
                key={index}
              >
                {canEdit && (
                  <div
                    style={{
                      position: "absolute",
                      transform: "translate(242px, -10px)",
                    }}
                  >
                    <button
                      className="icon-button"
                      style={{
                        margin: "0px",
                      }}
                      onClick={() =>
                        deletePerformanceCard(
                          card,
                          filteredPagesCards,
                          pid,
                        ).then((res) => {
                          setFilteredCards(res);
                          setCards(res);
                        })
                      }
                    >
                      <img
                        className="delete-metric-btn"
                        src={x}
                        alt="close button"
                      />
                    </button>
                  </div>
                )}

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>{card.subtitle}</Tooltip>
                  }
                >
                  <div className="title">{card.subtitle}</div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      {notFound ? (
                        <>
                          {"Not found in "} {card.subtitle}
                        </>
                      ) : card.path === "Google Tag Manager" ? (
                        <>
                          {txt1} {deltaString}
                          {dot}
                          {deltaDecimal}
                          {cent} {txt2}
                        </>
                      ) : (
                        <>
                          {card.text1} {signText}
                          {deltaString}
                          {dot}
                          {deltaDecimal}
                          {cent} {card.text2}
                        </>
                      )}
                    </Tooltip>
                  }
                >
                  <CardBody
                    text1={
                      card.path === "Google Tag Manager" ? txt1 : card.text1
                    }
                    text2={txt2 || card.text2}
                  />
                </OverlayTrigger>
                <div className="d-flex justify-content-end align-items-center">
                  <div
                    className="d-flex flex-column align-items-center "
                    style={{ fontSize: "10px" }}
                  >
                    <img
                      src={gaDashboardLogo}
                      alt="Google Analytics test"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "55px",
                      }}
                    />
                    <div className="mt-1">Google Analytics</div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export function fillGTMCardFields(GTMCards) {
  return GTMCards.map((card) => {
    return {
      path: "Google Tag Manager",
      title: card?.title ?? "GTM Metric",
      name: card?.name ?? "None",
      subtitle: card?.subtitle ?? "Google Tag Manager",
      template: card?.text ?? card?.template ?? "",
    };
  });
}

export function getCardOptions() {
  // Commented out options violate the dimension & metric compatibility

  const metricOptions = [
    { value: "activeUsers", label: "Active users" },
    { value: "bounceRate", label: "Bounce rate" },
    { value: "screenPageViews", label: "Page views" },
    { value: "active1DayUsers", label: "Active 1-Day Users" },
    { value: "active28DayUsers", label: "Active 28-Day Users" },
    { value: "active7DayUsers", label: "Active 7-Day Users" },
    { value: "crashAffectedUsers", label: "Crash Affected Users" },
    { value: "crashFreeUsersRate", label: "Crash Free Users Rate" },
    { value: "dauPerMau", label: "DAU/MAU" },
    { value: "dauPerWau", label: "DAU/WAU" },
    { value: "engagedSessions", label: "Engaged Sessions" },
    { value: "engagementRate", label: "Engagement Rate" },
    { value: "eventCount", label: "Event Count" },
    // { value: "newUsers", label: "New Users" },
    { value: "screenPageViewsPerUser", label: "Screen Page Views Per User" },
    { value: "sessions", label: "Sessions" },
    { value: "totalUsers", label: "Total Users" },
    { value: "userEngagementDuration", label: "User Engagement Duration" },
    { value: "wauPerMau", label: "WAU/MAU" },
  ];
  const commercialOptions = [
    { value: "addToCarts", label: "Add to Carts" },
    { value: "averagePurchaseRevenue", label: "Average Purchase Revenue" },
    {
      value: "averagePurchaseRevenuePerPayingUser",
      label: "Average Purchase Revenue Per Paying User",
    },
    {
      value: "averagePurchaseRevenuePerUser",
      label: "Average Purchase Revenue Per User",
    },
    { value: "averageRevenuePerUser", label: "Average Revenue Per User" },
    { value: "cartToViewRate", label: "Cart to View Rate" },
    { value: "checkouts", label: "Checkouts" },
    { value: "conversions", label: "Conversions" },
    { value: "ecommercePurchases", label: "E-commerce Purchases" },
    { value: "eventValue", label: "Event Value" },
    {
      value: "firstTimePurchaserConversionRate",
      label: "First Time Purchaser Conversion Rate",
    },
    { value: "firstTimePurchasers", label: "First Time Purchasers" },
    /* { value: "itemsAddedToCart", label: "Items Added to Cart" },
    { value: "itemsCheckedOut", label: "Items Checked Out" },
     { value: "itemsClickedInList", label: "Items Clicked in List" },
    { value: "itemsClickedInPromotion", label: "Items Clicked in Promotion" },
    { value: "itemsPurchased", label: "Items Purchased" },
    { value: "itemsViewed", label: "Items Viewed" },
    { value: "itemsViewedInList", label: "Items Viewed in List" },
    { value: "itemsViewedInPromotion", label: "Items Viewed in Promotion" },*/
    { value: "itemViewEvents", label: "Item View Events" },
    { value: "promotionClicks", label: "Promotion Clicks" },
    { value: "promotionViews", label: "Promotion Views" },
    { value: "purchaserConversionRate", label: "Purchaser Conversion Rate" },
    { value: "purchaseRevenue", label: "Purchase Revenue" },
    { value: "purchaseToViewRate", label: "Purchase to View Rate" },
    { value: "refundAmount", label: "Refund Amount" },
    { value: "shippingAmount", label: "Shipping Amount" },
    { value: "taxAmount", label: "Tax Amount" },
    { value: "totalAdRevenue", label: "Total Ad Revenue" },
    { value: "totalPurchasers", label: "Total Purchasers" },
    { value: "totalRevenue", label: "Total Revenue" },
    { value: "transactions", label: "Transactions" },
    { value: "transactionsPerPurchaser", label: "Transactions Per Purchaser" },
    { value: "userConversionRate", label: "User Conversion Rate" },
  ];

  return [...metricOptions, ...commercialOptions];
}

export function formatPercentageDiff(selected, hoverValue) {
  let percentageDifference = 0;
  let firstPart = "",
    secondPart = "";
  const flip = Number.isInteger(Number(selected));

  if (
    (!hoverValue || hoverValue === 0 || hoverValue === "0") &&
    (!selected || selected === 0 || selected === "0")
  ) {
    return {
      percentageDifference: 0,
      firstPart: "0",
      secondPart: "00",
      sign: "",
    };
  }

  if ((!hoverValue || hoverValue === 0 || hoverValue === "0") && selected > 0) {
    return {
      percentageDifference: 1,
      firstPart: "+100",
      secondPart: "00",
      sign: "+",
    };
  }

  if ((!selected || selected === 0 || selected === "0") && hoverValue > 0) {
    return {
      percentageDifference: 1,
      firstPart: "-100",
      secondPart: "00",
      sign: "-",
    };
  }

  if (flip) {
    percentageDifference =
      ((parseFloat(selected) - hoverValue) / hoverValue) * 100;
  } else {
    percentageDifference = (hoverValue - selected) * 100;
  }

  const sign =
    Math.sign(percentageDifference) === -1
      ? flip
        ? "-"
        : "+"
      : Math.sign(percentageDifference) === 0
      ? ""
      : flip
      ? "+"
      : "-";

  const integerPart = Math.floor(Math.abs(percentageDifference));
  firstPart = sign + integerPart;

  const decimalPart = (Math.abs(percentageDifference) % 1)
    .toFixed(2)
    .substring(2);
  secondPart = decimalPart;

  return { percentageDifference, firstPart, secondPart, sign };
}

export function formatPercentage(value) {
  let firstPart = "",
    secondPart = "";
  const sign = "";

  const integerPart = Math.floor(Math.abs(value));
  firstPart = integerPart;

  const decimalPart = (Math.abs(value) % 1).toFixed(2).substring(2);
  secondPart = decimalPart;

  return { value, firstPart, secondPart, sign };
}

export const sendFirestoreEmail = (recipient, subject, text, html) => {
  return new Promise((resolve, reject) => {
    const newDocRef = doc(collection(db, "mail"));
    setDoc(newDocRef, {
      to: [recipient],
      message: {
        subject: subject,
        text: text,
        html: html,
        // `<pre>${prettyContent}</pre>`,
      },
    })
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

export function getUxErrorOptions() {
  return [
    {
      value: "navigationAndStructure",
      label: (
        <>
          <i className="fa-solid fa-block-brick me-2"></i>Navigation & Structure
        </>
      ),
    },
    {
      value: "designAndTypography",
      label: (
        <>
          <i className="fa-solid fa-brush me-2"></i>Design & Typography
        </>
      ),
    },
    {
      value: "accessibilityAndConformity",
      label: (
        <>
          <i className="fa-brands fa-accessible-icon me-2"></i>Accessibility &
          Conformity
        </>
      ),
    },
    {
      value: "contentAndEngagement",
      label: (
        <>
          <i className="fa-solid fa-book me-2"></i>Content & Engagement
        </>
      ),
    },
    {
      value: "mobileAndResponsive",
      label: (
        <>
          <i className="fa-solid fa-mobile me-2"></i>Mobile & Responsive
        </>
      ),
    },
    {
      value: "metric",
      label: (
        <>
          <i className="fa-solid fa-chart-simple"></i> Metric
        </>
      ),
    },
  ];
}

export function getUxErrorLabel(label) {
  const map = [
    { value: "navigationAndStructure", label: "Navigation & Structure" },
    { value: "designAndTypography", label: "Design & Typography" },
    {
      value: "accessibilityAndConformity",
      label: "Accessibility & Conformity",
    },
    { value: "contentAndEngagement", label: "Content & Engagement" },
    { value: "mobileAndResponsive", label: "Mobile & Responsive" },
    { value: "metric", label: "Performance Metric" },
  ];
  return map.find((item) => item.value === label)?.label ?? -1;
}

export function getUxErrorIcon(label) {
  const map = [
    {
      value: "navigationAndStructure",
      label: <i className="fa-solid fa-block-brick"></i>,
    },
    {
      value: "designAndTypography",
      label: <i className="fa-solid fa-brush"></i>,
    },
    {
      value: "accessibilityAndConformity",
      label: <i className="fa-brands fa-accessible-icon"></i>,
    },
    {
      value: "contentAndEngagement",
      label: <i className="fa-solid fa-book"></i>,
    },
    {
      value: "mobileAndResponsive",
      label: <i className="fa-solid fa-mobile"></i>,
    },
    {
      value: "metric",
      label: <i className="fa-solid fa-chart-simple"></i>,
    },
  ];
  return map.find((item) => item.value === label)?.label ?? -1;
}
export function getUxErrorLabelAndIcon(value) {
  const map = [
    {
      value: "navigationAndStructure",
      label: (
        <>
          <i className="fa-solid fa-block-brick me-2"></i> Navigation &
          Structure
        </>
      ),
    },
    {
      value: "designAndTypography",
      label: (
        <>
          <i className="fa-solid fa-brush me-2"></i> Design & Typography
        </>
      ),
    },
    {
      value: "accessibilityAndConformity",
      label: (
        <>
          <i className="fa-brands fa-accessible-icon me-2"></i> Accessibility &
          Conformity
        </>
      ),
    },
    {
      value: "contentAndEngagement",
      label: (
        <>
          <i className="fa-solid fa-book me-2"></i> Content & Engagement
        </>
      ),
    },
    {
      value: "mobileAndResponsive",
      label: (
        <>
          <i className="fa-solid fa-mobile me-2"></i> Mobile & Responsive
        </>
      ),
    },
    {
      value: "metric",
      label: (
        <>
          <i className="fa-solid fa-chart-simple me-2"></i> Performance Metric
        </>
      ),
    },
  ];
  return map.find((item) => item.value === value)?.label ?? null;
}

export function dateFirestoreToReact(firebaseTimestamp) {
  return firebaseTimestamp.toDate();
}

export function dateReactToFirestore(date) {
  return Timestamp.fromDate(date);
}

export function formatDate(date) {
  return `${date.toLocaleString("default", {
    month: "long",
  })} ${date.getFullYear()}`;
}

export function formatDateMDY(date) {
  return `${date.toLocaleString("default", {
    month: "long",
  })} ${date.getDate()}, ${date.getFullYear()}`;
}

// Does not return deleted ones
export async function getAllProjects() {
  const projects = await getDocs(collection(db, "projects"));
  return projects.docs
    .map((doc) => ({ id: doc.id, ...doc.data() }))
    .filter((project) => !project.deleted);
}

export async function getExistingClientsForAdmin() {
  const projectsRef = collection(db, "projects");
  const q = query(projectsRef);

  const querySnapshot = await getDocs(q);
  const projects = querySnapshot.docs
    .map((doc) => ({ id: doc.id, ...doc.data() }))
    .filter((project) => !project.deleted);

  const enhancedProjects = await Promise.all(
    projects.map(async (project) => {
      const userRef = doc(db, "users", project.clientId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.hidden === true) {
          return null;
        }

        let workspaceUsers = [];
        if (project.workspace && project.workspace.length > 0) {
          workspaceUsers = await Promise.all(
            project.workspace.map(async (workspaceItem) => {
              if (!workspaceItem.uid) {
                return null;
              }
              const workspaceUserRef = doc(db, "users", workspaceItem.uid);
              const workspaceUserDoc = await getDoc(workspaceUserRef);
              if (workspaceUserDoc.exists()) {
                return { id: workspaceUserDoc.id, ...workspaceUserDoc.data() };
              }
              return null;
            }),
          );
        }

        return {
          ...project,
          clientEmail: userData.email,
          clientFirstName: userData.firstName,
          clientPaymentPlan: userData.paymentPlan,
          clientId: userDoc.id,
          role: userData.role,
          clientCompany: userData.companyName,
          clientAwaitingApproval: userData.awaitingApproval,
          type: "project",
        };
      } else {
        return project;
      }
    }),
  );

  return enhancedProjects.filter((project) => project !== null);
}

export async function getUserNotifications(uid, callback) {
  const notificationsRef = collection(db, "notifications");
  const userRef = doc(db, "users", uid);
  const userDoc = await getDoc(userRef);

  let unsubscribeNotifications = [];
  let unsubscribeSubs = [];

  if (userDoc.exists()) {
    const userData = userDoc.data();
    const queries = [query(notificationsRef, where("clientId", "==", uid))];
    if (userData.workspace) {
      queries.push(
        query(notificationsRef, where("workspace", "==", userData.workspace)),
      );
    }

    const combinedQuery = (querySnapshots) => {
      let allSubDocsMap = new Map();

      // Unsubscribe from old listeners
      unsubscribeSubs.forEach((unsub) => unsub());
      unsubscribeSubs = [];

      querySnapshots.forEach((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          const unsubscribeSub = onSnapshot(
            collection(doc.ref, "notification"),
            (subDocsSnapshot) => {
              const subDocs = subDocsSnapshot.docs.map((subDoc) => {
                const data = subDoc.data();
                return {
                  id: subDoc.id,
                  ...data,
                };
              });
              allSubDocsMap.set(doc.id, subDocs);
              const allSubDocs = Array.from(allSubDocsMap.values()).flat();
              callback(allSubDocs);
            },
          );

          unsubscribeSubs.push(unsubscribeSub);
        });
      });
    };

    // Listen to all queries and combine their results
    const querySnapshots = [];
    queries.forEach((q) => {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshots.push(querySnapshot);
        combinedQuery(querySnapshots);
      });
      unsubscribeNotifications.push(unsubscribe);
    });
  }

  // Return a function that unsubscribes from all snapshots
  return () => {
    unsubscribeNotifications.forEach((unsub) => unsub());
    unsubscribeSubs.forEach((unsub) => unsub());
  };
}
export function urlIsValid(url) {
  const websitePattern =
    /^https:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}$/;

  return websitePattern.test(url) && url.split(/\s/).length === 1;
}

export function HTTPSurlIsValid(url) {
  const websitePattern =
    /^(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}$/;

  const hasHTTPS = url.includes("https://");
  const hasHTTP = url.includes("http://");

  return (
    websitePattern.test(url) &&
    url.split(/\s/).length === 1 &&
    !hasHTTPS &&
    !hasHTTP
  );
}

export function paymentSquare(paymentPlan, setPaymentModalIsOpen) {
  return (
    <div
      className="payment-square"
      style={{
        background: "#7C4DFF29",
        height: paymentPlan ? "268.49px" : "120px",
      }}
    >
      <div className="payment-pull-tab" style={{ background: "#2A00FF" }}>
        Your plan
      </div>
      <div className="fc-blue" style={{ marginTop: "24px" }}>
        {paymentPlan === "start" ? (
          <>
            <i className="fa-regular fa-campfire fs-24"></i>
            <div className="square-title">Start</div>{" "}
            <div className="square-subtitle">750€/month</div>
          </>
        ) : paymentPlan === "ultra" ? (
          <>
            <img
              src={luxi}
              style={{ width: "21px", height: "24px" }}
              alt="luxi"
            ></img>
            <div className="square-title">Ultra</div>{" "}
            <div className="square-subtitle">695€/month</div>
          </>
        ) : paymentPlan === "corporate" ? (
          <>
            <i className="fa-regular fa-volcano fs-24"></i>
            <div className="square-title">Corporate</div>{" "}
            <div className="square-subtitle">3.995€/month</div>
          </>
        ) : (
          <h2 style={{ marginTop: "32px" }}>No plan selected</h2>
        )}
      </div>
      {paymentPlan && (
        <>
          <div
            className="fc-blue fw500 fs-14 lh-20"
            style={{ marginTop: "24px" }}
          >
            Valid until<br></br>
            <span className="fw-700 fs-16 lh-20">February DD, YYYY</span>
          </div>
          <button
            type="button"
            onClick={() => setPaymentModalIsOpen(true)}
            style={{
              borderColor: "#2A00FF",
              background: "white",
              width: "135px",
              borderRadius: "8px !important",
            }}
            className="payment-btn btn-secondary align-items-center fw-700"
          >
            <div className="fs-1334 fc-blue">Consult details</div>
          </button>
        </>
      )}
    </div>
  );
}

export function paymentSquare2(paymentPlan, setPaymentModalIsOpen) {
  return (
    <div
      className="payment-square"
      style={{
        background: "#7C4DFF29",
        height: paymentPlan ? "268.49px" : "120px",
      }}
    >
      <div className="payment-pull-tab" style={{ background: "#2A00FF" }}>
        Your plan
      </div>
      <div className="fc-blue" style={{ marginTop: "24px" }}>
        {paymentPlan === "start" ? (
          <>
            <i className="fa-regular fa-campfire fs-24"></i>
            <div className="square-title">Start</div>{" "}
            <div className="square-subtitle">750€/month</div>
          </>
        ) : paymentPlan === "ultra" ? (
          <>
            <img
              src={luxi}
              alt="luxi"
              style={{ width: "21px", height: "24px" }}
            ></img>
            <div className="square-title">Ultra</div>{" "}
            <div className="square-subtitle">695€/month</div>
          </>
        ) : paymentPlan === "corporate" ? (
          <>
            <i className="fa-regular fa-volcano fs-24"></i>
            <div className="square-title">Corporate</div>{" "}
            <div className="square-subtitle">3.995€/month</div>
          </>
        ) : (
          <h2 style={{ marginTop: "32px" }}>No plan selected</h2>
        )}
      </div>
      {paymentPlan && (
        <>
          <div
            className="fc-blue fw500 fs-14 lh-20 payment-square-li-container"
            style={{ marginTop: "24px", marginLeft: "5px" }}
          >
            <div>{paymentPlan === "start" ? 3 : "Unlimited"} link(s)</div>
            <div>Unlim. recommendations</div>
          </div>
          <button
            type="button"
            onClick={() => setPaymentModalIsOpen(true)}
            style={{
              borderColor: "#2A00FF",
              background: "white",
              width: "135px",
              borderRadius: "8px !important",
            }}
            className="payment-btn btn-secondary align-items-center fw-700"
          >
            <div className="fs-1334 fc-blue">Consult details</div>
          </button>
        </>
      )}
    </div>
  );
}

export function getSeverityOptions() {
  return [
    { value: "not-usability", label: "Not a usability problem" },
    { value: "cosmetic", label: "Cosmetic" },
    { value: "minor", label: "Minor" },
    { value: "major", label: "Major" },
    { value: "critical", label: "Critical" },
  ];
}

export function getFixEaseOptions() {
  return [
    { value: "very-easy", label: "Very easy to fix" },
    { value: "easy", label: "Easy to fix" },
    { value: "challenging", label: "Challenging to fix" },
    { value: "very-challenging", label: "Very challenging to fix" },
    { value: "unfixable", label: "Unfixable" },
  ];
}

export function getSeverityOption(value) {
  const options = [
    { value: "not-usability", label: "Not a usability problem" },
    { value: "cosmetic", label: "Cosmetic" },
    { value: "minor", label: "Minor" },
    { value: "major", label: "Major" },
    { value: "critical", label: "Critical" },
    { value: "manual", label: "Manual" },

    { value: "Not a usability problem", label: "Not a usability problem" },
    { value: "Cosmetic", label: "Cosmetic" },
    { value: "Minor", label: "Minor" },
    { value: "Major", label: "Major" },
    { value: "Critical", label: "Critical" },
    { value: "Manual", label: "Manual" },
  ];
  return options.find((option) => option.value === value) ?? null;
}

export function getFixEaseOption(value) {
  const options = [
    { value: "very-easy", label: "Very easy to fix" },
    { value: "easy", label: "Easy to fix" },
    { value: "challenging", label: "Challenging to fix" },
    { value: "very-challenging", label: "Very challenging to fix" },
    { value: "unfixable", label: "Unfixable" },

    { value: "Very easy", label: "Very easy to fix" },
    { value: "Easy", label: "Easy to fix" },
    { value: "Challenging", label: "Challenging to fix" },
    { value: "Very challenging", label: "Very challenging to fix" },
    { value: "Unfixable", label: "Unfixable" },
  ];
  return options.find((option) => option.value === value) ?? null;
}

export function fixAiFixEaseSeverity(value) {
  const wordMapping = {
    "Very easy": "very-easy",
    Easy: "easy",
    Challenging: "challenging",
    "Very challenging": "very-challenging",
    Unfixable: "unfixable",
    "Not a usability problem": "not-usability",
    Cosmetic: "cosmetic",
    Minor: "minor",
    Major: "major",
    Critical: "critical",
    Manual: "manual",
  };
  return wordMapping[value] || value;
}

export function getHardcodedInsightFields(ruleId) {
  let fields = {};

  switch (ruleId) {
    case "1_2_1":
      fields = {
        title: "The font size is not optimum for the readibility",
        uxErrorType: [
          { value: "accessibilityAndConformity" },
          { value: "contentAndEngagement" },
        ],
        severity: "minor",
        fixEase: "very-easy",
        uxRule:
          "If you are using a label to describe an inside or outside field, you can use 14px. But for text that needs to be read and understood, you need to use a minimum font size of 16px.",
      };
      break;
    case "1_3_1":
      fields = {
        title:
          "Quantity of content is exceeding 3-8 seconds of engagement time",
        uxErrorType: [{ value: "contentAndEngagement" }],
        severity: "not-usability",
        fixEase: "very-easy",
        uxRule:
          "The human speed read is around 250 words per minute. We know by experience that the average engagement time on a website is aroung 3-8 seconds. So it means between 13 and 33 words max.Chances are higher to engage a user if a user read your content into that period of time.",
      };
      break;
    case "1_3_2":
      fields = {
        title: "The content of your sentence is too complex or uses jargon",
        uxErrorType: [{ value: "contentAndEngagement" }],
        severity: "cosmetic",
        fixEase: "easy",
      };
      break;
    case "1_3_3":
      fields = {
        title: "Serif text has been detected in the page",
        uxErrorType: [{ value: "designAndTypography" }],
        severity: "cosmetic",
        fixEase: "very-easy",
        uxRule:
          "Even if Sans serif is beautif it increase the cognition work to read. The user xill take more time to read and understand the content than Sans Serif font.",
      };
      break;
    case "3_1_1":
      fields = {
        title: "The length of your text is too large for user reading",
        uxErrorType: [{ value: "contentAndEngagement" }],
        severity: "not-usability",
        fixEase: "very-easy",
        uxRule:
          "At 30cm from the screen, you have peripheral vision that allows you to effectively view content that is 450-650px wide.",
      };
      break;
    case "7_1_1":
      fields = {
        recommendation: [
          "To reduce bounce rate, enhance website loading speed, improve content quality, make navigation intuitive, ensure mobile responsiveness, and use engaging visuals. Additionally, implement clear calls-to-action and optimize for relevant keywords to attract the right audience.",
        ],
        uxRule:
          "The bounce rate is a metric that measures the percentage of visitors to a website who navigate away from the site after viewing only one page. A high bounce rate could indicate that the site isn't capturing the interest of its visitors, while a lower bounce rate suggests better engagement.",
      };
      break;
    case "7_1_2":
      fields = {
        recommendation: [
          "To increase engagement rate, create interactive content, use engaging multimedia, optimize for mobile, and personalize user experiences. Implement clear calls-to-action, improve website navigation, and provide valuable content that encourages longer visits and more interactions.",
        ],
        uxRule:
          "The engagement rate is a metric that evaluates the depth of visitor interaction on a website, based on actions like clicks, time spent, and page views per session. Higher engagement rates indicate more effective content and user involvement.",
      };
      break;
    case "8_1_1":
      fields = {
        title: "Page filesize exceeds recommendation",
      };
      break;
    case "8_1_2":
      fields = {
        title: "The page is slow to load",
      };
      break;
    default:
      fields = {};
  }

  return fields;
}

export function backToDashboard(navigate) {
  return (
    <div className="w-100 d-flex justify-content-end my-4">
      <Link to={"/"}>
        <div
          style={{
            width: "158px",
            height: "24px",
            textAlign: "center",
          }}
          className="fs-14 fw-700 fc-black"
          onClick={() => navigate("/")}
        >
          <FiArrowLeft
            color="#000"
            size="24px"
            style={{ marginRight: "10px", marginBottom: "2px" }}
          />
          Back to Dashboard
        </div>
      </Link>
    </div>
  );
}

function writeNewNotification(projectId, clientId, body) {
  if (!body) {
    return false;
  }

  const notificationsRef = collection(db, "notifications");
  let q;
  let newDoc;
  if (projectId && clientId) {
    q = query(
      notificationsRef,
      where("clientId", "==", clientId),
      where("workspace", "==", projectId),
    );
    newDoc = { clientId: clientId, workspace: projectId };
  }
  if (!projectId && clientId) {
    q = query(notificationsRef, where("clientId", "==", clientId));
    newDoc = { clientId: clientId };
  }

  if (!q) {
    return;
  }

  getDocs(q).then((querySnapshot) => {
    if (querySnapshot.empty) {
      const newDocRef = doc(notificationsRef);
      setDoc(newDocRef, newDoc).then(() => {
        const notificationCollection = collection(newDocRef, "notification");
        const newNotificationRef = doc(notificationCollection);
        setDoc(newNotificationRef, body);
      });
    } else {
      querySnapshot.forEach((docSnapshot) => {
        const notificationCollection = collection(
          docSnapshot.ref,
          "notification",
        );
        const newNotificationRef = doc(notificationCollection);
        setDoc(newNotificationRef, body);
      });
    }
  });
}

export function newInsightNotification(
  projectId,
  clientId,
  insightId,
  num,
  title,
) {
  const body = {
    body: `Insight #${num} "${title}" has been detected.`,
    createdAt: dateReactToFirestore(new Date()),
    urlText: "Check it out now.",
    url: `/insights/view?id=${insightId}&pid=${projectId}`,
  };

  writeNewNotification(projectId, clientId, body);
}

export function newUserNotification(clientId) {
  const body = {
    body: "Your account has been successfully created. Welcome 🎉",
    createdAt: dateReactToFirestore(new Date()),
  };

  writeNewNotification(null, clientId, body);
}

export async function getInsightMaxNum(pid) {
  try {
    const q = query(collection(db, "insights"), where("projectId", "==", pid));
    const querySnapshot = await getDocs(q);
    const res = querySnapshot.docs.reduce(
      (maxNum, doc) => Math.max(maxNum, doc.data().num),
      0,
    );
    return res;
  } catch (e) {}
  return;
}

async function writeInsightsFast(i, id) {
  const newDocRef = doc(collection(db, "insightsFast"));
  let insight = {
    verifyDate: i.verifyDate ?? dateReactToFirestore(new Date()),
    date: i.createdAt ?? i.date ?? dateReactToFirestore(new Date()),
    projectId: i.projectId ?? i.pid ?? "",
    url: i.url ?? i.pageName ?? "/no-url",
    status: "Validated",
    ruleId: i.ruleId ?? "",
    title: i.title ?? "Untitled",
    num: i.num,
    source: i.source ?? "",
    trueId: id,
  };
  try {
    await setDoc(newDocRef, insight);
  } catch (e) {}
}

// Save the insight to firestore
export async function verifyInsight(i) {
  const maxNum = await getInsightMaxNum(i.projectId);
  return new Promise(async (resolve, reject) => {
    try {
      let insight = {
        ...i,
      };

      let uxErrorTypes = [];
      if (insight?.uxErrorType?.length > 0) {
        insight.uxErrorType.forEach((errorType) => {
          uxErrorTypes.push({ value: errorType.value });
        });
      }

      const newDocRef = doc(collection(db, "insights"));
      const id = newDocRef.id;
      const tempInsight = {
        verifyDate: dateReactToFirestore(new Date()),
        DOMId: insight.DOMId ?? "",
        createdAt: insight.date,
        projectId: insight.projectId,
        uxErrorType: uxErrorTypes ?? [],
        pageName: insight.url ?? "/",
        status: "in-progress",
        ruleId: insight.ruleId ?? "",

        title: insight.title ?? "",
        uxRule: insight.uxRule ?? "",

        description: insight.description ?? [],
        num: maxNum + 1,
        recommendation: insight.recommendation ?? [],

        element: insight.element ?? [],
        text: insight.text ?? [],
        xpath: insight.xpath ?? [],
        MRAID: insight.MRAID ?? "",
      };
      insight.fixEase && (tempInsight.fixEase = insight.fixEase);
      insight.severity && (tempInsight.severity = insight.severity);
      insight.metric && (tempInsight.metric = insight.metric);

      try {
        await setDoc(newDocRef, tempInsight);
      } catch (e) {}

      getClientOfProject(insight.projectId).then((cid) => {
        newInsightNotification(
          insight.projectId,
          cid,
          id,
          tempInsight.num,
          tempInsight.title,
        );
      });

      resolve({ id, ...tempInsight });
    } catch (error) {
      reject(error);
    }
  });
}

async function getPropertyIDsFor7_1_X(projects, ruleId) {
  const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000);
  const GAProjects = projects.filter((p) => p.googleAnalyticsId);
  const res = [];

  // For each project, do not query if the rule has been recently evaluated
  for (let p of GAProjects) {
    let docs;
    try {
      docs = await getInsightsPid(p.value);
    } catch (e) {
      continue;
    }
    let shouldQuery = true && docs.length === 0;
    let ruleDoc;
    let ruleDocId;

    // Check to see if the rule has been evaluated ever
    for (let doc of docs) {
      if (doc.ruleId && doc.ruleId === ruleId) {
        ruleDoc = doc;
        ruleDocId = doc.id;
      }
    }

    shouldQuery =
      !ruleDoc ||
      (ruleDoc.createdAt &&
        dateFirestoreToReact(ruleDoc.createdAt) <= oneHourAgo);

    if (shouldQuery) {
      res.push({
        projectId: p.value,
        url: p.rootUrl,
        googleAnalyticsId: p.googleAnalyticsId,
        ...(ruleDoc && { ruleDoc }),
        ...(ruleDocId && { ruleDocId }),
      });
    }
  }

  return res;
}

function get7_1MetricsQuery(num = 1, url) {
  if (!url) return;
  return {
    pages: [url],
    commercialMetrics: [num === 1 ? "bounceRate" : "engagementRate"],
    pageMetrics: [num === 1 ? "bounceRate" : "engagementRate"],
    dateRanges: [
      {
        startDate: "today",
        endDate: "today",
      },
      { startDate: "today", endDate: "today" },
    ],
  };
}

async function update7_1doc(pid, rid, desc) {
  const maxNum = await getInsightMaxNum(pid);

  const docRef = doc(db, "insights", rid);
  let title = "";

  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    title = docSnap.data().title;
  }

  try {
    await updateDoc(docRef, {
      seen: false,
      createdAt: dateReactToFirestore(new Date()),
      verifyDate: dateReactToFirestore(new Date()),
      description: desc,
      num: maxNum + 1,
    });
  } catch (e) {}

  const insightFastQuery = query(
    collection(db, "insightsFast"),
    where("trueId", "==", rid),
  );

  try {
    getDocs(insightFastQuery).then((querySnapshot) => {
      querySnapshot.forEach((documentSnapshot) => {
        const docRefFast = doc(db, "insightsFast", documentSnapshot.id);
        updateDoc(docRefFast, {
          seen: false,
          createdAt: dateReactToFirestore(new Date()),
          verifyDate: dateReactToFirestore(new Date()),
          description: desc,
          num: maxNum + 1,
        });
      });
    });
  } catch (e) {}
  return { num: maxNum + 1, title: title };
}

function get7_1Description(rule, value) {
  return `The page is measuring a ${
    rule === "7_1_1" ? "bounce" : "engagement"
  } rate of ${Math.round(value * 100)}%. It is ${
    rule === "7_1_1" ? "higher" : "lower"
  } than other pages. It should be interesting to go deeper to detect an issue if Design or User Experience.`;
}

export const hardCodedRules7_1_1and7_1_2 = async (projects) => {
  const pids7_1_1 = await getPropertyIDsFor7_1_X(projects, "7_1_1");
  const pids7_1_2 = await getPropertyIDsFor7_1_X(projects, "7_1_2");

  const fetchAndLogData = async (p, X) => {
    let pathname = p.url;
    if (!p.url.startsWith("http://") && !p.url.startsWith("https://")) {
      pathname = "http://" + p.url;
    }
    if (pathname.endsWith("/")) {
      pathname = pathname.slice(0, -1);
    }
    pathname = new URL(pathname).pathname;

    let res;
    try {
      res = await fetchAnalyticsData(
        p.googleAnalyticsId,
        get7_1MetricsQuery(X, pathname),
      );
    } catch (e) {}
    if (!res) {
      return;
    }

    const BR = res?.[pathname]?.["bounceRate"]?.["date_range_0"]?.["data"];
    const ER = res?.[pathname]?.["engagementRate"]?.["date_range_0"]?.["data"];
    if (X === 1 && BR && BR > 0.35) {
      if (p.ruleDoc) {
        // Update the existing insight
        try {
          const { num, title } = await update7_1doc(
            p.projectId,
            p.ruleDocId,
            get7_1Description("7_1_1", BR),
          );
          getClientOfProject(p.projectId).then((cid) => {
            newInsightNotification(p.projectId, cid, p.ruleDocId, num, title);
          });
        } catch (e) {}
      } else {
        // Create a new insight
        const i = {
          projectId: p.projectId,
          date: dateReactToFirestore(new Date()),
          ruleId: "7_1_1",
          title: `Bounce rate on ${
            pathname === "/" ? "Homepage" : pathname
          } is higher than standards`,
          description: get7_1Description("7_1_1", BR),
          source: "hardCoded",
          url: p.url,
          ...getHardcodedInsightFields("7_1_1"),
        };

        try {
          const newInsight = await verifyInsight(i);
          await writeInsightsFast(newInsight, newInsight.id);
        } catch (e) {}
      }
    } else if (X === 2 && ER && ER < 0.5) {
      if (p.ruleDoc) {
        // Update the existing insight

        try {
          const { num, title } = await update7_1doc(
            p.projectId,
            p.ruleDocId,
            get7_1Description("7_1_2", ER),
          );
          getClientOfProject(p.projectId).then((cid) => {
            newInsightNotification(p.projectId, cid, p.ruleDocId, num, title);
          });
        } catch (e) {}
      } else {
        // Create a new insight
        const i = {
          projectId: p.projectId,
          date: dateReactToFirestore(new Date()),
          ruleId: "7_1_2",
          title: `Engagement rate on ${
            pathname === "/" ? "Homepage" : pathname
          } is lower than standards`,
          description: get7_1Description("7_1_2", ER),
          source: "hardCoded",
          url: p.url,
          ...getHardcodedInsightFields("7_1_2"),
        };

        try {
          const newInsight = await verifyInsight(i);
          await writeInsightsFast(newInsight, newInsight.id);
        } catch (e) {}
      }
    }
  };

  try {
    await Promise.all(pids7_1_1.map((p) => fetchAndLogData(p, 1)));
    await Promise.all(pids7_1_2.map((p) => fetchAndLogData(p, 2)));
  } catch (error) {
    console.error("Error processing data:", error);
  }

  return "data";
};

export async function createChatRoom(id) {
  const chatRoomCollection = collection(db, "channels");
  await addDoc(chatRoomCollection, {
    parties: [id, ADMIN_ID],
  });
  return;
}

export async function getSavedDOM(docId) {
  const docRef = doc(db, "scrapedSites", docId);
  const docSnapshot = await getDoc(docRef);

  if (docSnapshot.exists()) {
    return { id: docSnapshot.id, ...docSnapshot.data() };
  } else {
    return null;
  }
}

export async function getTextDataFromStorage(url) {
  const response = await fetch(url);
  if (!response.ok) {
    return "";
  }
  const text = await response.text();
  return text;
}

export async function getInsightCountOfPid(pid) {
  const insights = await getInsightsPid(pid);
  return insights.length;
}

export async function sendAccountActivatedEmail(mailTo, vars) {
  sendMailchimpEmail({
    mailTo: mailTo || "hello@luxifer.app",
    template: "account-activated",
    subject: "Your account has been activated",
    vars: vars ?? [
      {
        name: "url",
        content:
          "https://www.luxifer.app/this-is-a-custom-url-to-connecturlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurl",
      },
      {
        name: "url1",
        content: "https://www.luxifer.app/custom-1",
      },
      {
        name: "url2",
        content: "https://www.luxifer.app/custom-2",
      },
      {
        name: "url3",
        content: "https://www.luxifer.app/custom-3",
      },
      {
        name: "url4",
        content: "https://www.luxifer.app/custom-4",
      },
      {
        name: "unsub",
        content: "https://www.luxifer.app/unsubscribeid=123",
      },
      {
        name: "support",
        content:
          "https://www.luxifer.app/chat-with-adminurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurl",
      },
    ],
  });
}

export async function sendVerifyEmail(mailTo, vars) {
  sendMailchimpEmail({
    mailTo: mailTo || "hello@luxifer.app",
    template: "verify-your-email",
    subject: "Please verify your email for LUXIFER",
    vars: vars ?? [
      {
        name: "url",
        content:
          "https://www.luxifer.app/this-is-a-custom-urlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurl",
      },
    ],
  });
}

export async function sendMonthlyReportEmail(mailTo, vars) {
  sendMailchimpEmail({
    mailTo: mailTo || "hello@luxifer.app",
    template: "monthly-report",
    subject: "Your LUXIFER monthly report is here! 🚀",
    vars: vars ?? [
      {
        name: "test_url",
        content: "https://www.luxifer.app/ab-test-not-implemented",
      },
      {
        name: "month_year",
        content: "Juuuune 2099",
      },
      {
        name: "insights",
        content: "2111",
      },
      {
        name: "solved",
        content: "1113",
      },
      {
        name: "impact",
        content: "+16456455.00$",
      },
      {
        name: "connect",
        content:
          "https://www.luxifer.app/custom-connect-urlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurl",
      },
      {
        name: "support",
        content:
          "https://www.luxifer.app/custom-chat-with-adminurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurl",
      },
      {
        name: "unsub",
        content: "https://www.luxifer.app/unsubid=12312312",
      },
    ],
  });
}

export function sendInviteEmail(mailTo, vars) {
  return new Promise((resolve, reject) => {
    try {
      sendMailchimpEmail({
        mailTo: mailTo || "hello@luxifer.app",
        template: "workspace-invite",
        subject: "You have been added to a LUXIFER workspace",
        vars: vars ?? [
          {
            name: "url",
            content:
              "https://www.luxifer.app/invited/QAPROJECTFAKE?email=qa@123.com",
          },
          {
            name: "role",
            content: "QA Tester",
          },
          {
            name: "name",
            content: "John Doe",
          },
        ],
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

export async function testAllEmails() {
  sendAccountActivatedEmail();
  sendVerifyEmail();
  sendMonthlyReportEmail();
  sendInviteEmail();
}

// Only works if the user keeps the page open.
// Cloud function takes care of if the page closes.
export async function firebaseLogout(uid) {
  if (uid && db && RTDB) {
    // Ensure uid is available
    const userStatusFirestoreRef = doc(db, "/status/" + uid);
    const isOfflineForFirestore = { state: "offline" };
    setDoc(userStatusFirestoreRef, isOfflineForFirestore);

    const userStatusDatabaseRef = rtref(RTDB, "/status/" + uid);
    set(userStatusDatabaseRef, isOfflineForFirestore);
    return;
  }
}

export const DataAccuracyWarning = () => (
  <div style={{ marginBottom: "25px" }}>
    ⚠️ Data from the last 48 hours may not be accurate.⚠️
  </div>
);

export const CustomPaginator = (page, setPage, totalPages) => {
  const handlePrevious = () => {
    if (page > 0) setPage(page - 1);
  };

  const handleNext = () => {
    if (page < totalPages - 1) setPage(page + 1);
  };
  return (
    <div style={{ color: "black" }}>
      <FiArrowLeft
        onClick={(event) => {
          event.preventDefault();
          handlePrevious();
        }}
        style={{
          cursor: page > 0 ? "pointer" : "not-allowed",
          color: page > 0 ? "inherit" : "#BABABA",
          marginRight: "10px",
        }}
        size={24}
      />
      {Array.from({ length: totalPages }, (_, index) => index).map(
        (pageIndex) =>
          pageIndex === page ? (
            <GiPlainCircle
              key={pageIndex}
              size={12}
              style={{ strokeWidth: "10px", marginRight: "4.3px" }}
              onClick={() => setPage(pageIndex)}
            />
          ) : (
            <GiCircle
              key={pageIndex}
              size={12}
              style={{ strokeWidth: "40px", marginRight: "4.3px" }}
              onClick={() => setPage(pageIndex)}
            />
          ),
      )}
      <FiArrowRight
        onClick={(event) => {
          event.preventDefault();
          handleNext();
        }}
        style={{
          cursor: page < totalPages - 1 ? "pointer" : "not-allowed",
          color: page < totalPages - 1 ? "inherit" : "#BABABA",
          marginLeft: "5.7px",
        }}
        size={24}
      />
    </div>
  );
};

export const getMraUrls = async (MRAID, urls, device) => {
  const [projectId, timestamp] = MRAID.split("_");
  const screenshotsCollection = collection(db, "projectScreenshots");

  const q = query(
    screenshotsCollection,
    where("projectId", "==", projectId),
    where("url", "in", urls),
    where("device", "==", device),
    where("MRAID", "==", MRAID),
  );

  const querySnapshot = await getDocs(q);
  const urlToImgMap = {};

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    urlToImgMap[data.url] = data.img || "";
  });

  return urls.map((url) => urlToImgMap[url] || "");
};

export function isMatomoProject(selectedProject) {
  return (
    selectedProject &&
    selectedProject.matomoUrl &&
    selectedProject.matomoId &&
    selectedProject.matomoApiToken
  );
}

export function appendSegmentParamsToMatomoQuery(
  paramsWithoutSegment,
  props,
  handleError,
) {
  const params = { ...paramsWithoutSegment };

  // There will always be a single device type
  const segment = props.heatmapFunnelFilters.phone
    ? "deviceType==smartphone"
    : props.heatmapFunnelFilters.desktop
    ? "deviceType==desktop"
    : props.heatmapFunnelFilters.tablet
    ? "deviceType==tablet"
    : "error";

  if (segment !== "error") {
    params.segment = params.segment ? `${params.segment};${segment}` : segment;
  }

  if (
    props.heatmapFunnelFilters.duration &&
    props.heatmapFunnelFilters.duration.value
  ) {
    const start = props.heatmapFunnelFilters.duration.value.split("~")[0];
    const end = props.heatmapFunnelFilters.duration.value.split("~")[1];
    const segment = `visitDuration>=${start};visitDuration<=${end}`;

    params.segment = params.segment ? `${params.segment};${segment}` : segment;
  }

  if (
    props.heatmapFunnelFilters.localTime &&
    props.heatmapFunnelFilters.localTime.value
  ) {
    const start = props.heatmapFunnelFilters.localTime.value.split("~")[0];
    const end = props.heatmapFunnelFilters.localTime.value.split("~")[1];
    const segment = `visitLocalHour>=${start};visitLocalHour<=${end}`;

    params.segment = params.segment ? `${params.segment};${segment}` : segment;
  }

  const applyFilter = (filterData, fieldName) => {
    if (filterData && filterData.length > 0) {
      const segment = filterData
        .map((item) => `${fieldName}==${item.value}`)
        .join(",");
      params.segment = params.segment
        ? `${params.segment};${segment}`
        : segment;
    }
  };

  // Apply filters
  applyFilter(props.heatmapFunnelFilters.country, "countryCode");
  applyFilter(props.heatmapFunnelFilters.browser, "browserName");
  applyFilter(props.heatmapFunnelFilters.referrer, "referrerType");
  applyFilter(props.heatmapFunnelFilters.deviceBrand, "deviceBrand");
  applyFilter(props.heatmapFunnelFilters.resolution, "resolution");
  applyFilter(props.heatmapFunnelFilters.city, "city");

  if (
    props.heatmapFunnelFilters.formUse &&
    props.heatmapFunnelFilters.formUse.length > 0
  ) {
    let filterData = props.heatmapFunnelFilters.formUse;
    const segment = filterData
      .map((item) => {
        switch (item.value) {
          case "No activity":
            return "form_num_starts==0";
          case "Started":
            return "form_num_starts>=1";
          case "Did not submit":
            return "form_num_submissions==0";
          case "Submitted":
            return "form_num_submissions>=1";
          default:
            return ``;
        }
      })
      .join(",");

    params.segment = params.segment ? `${params.segment};${segment}` : segment;
  }
  // Ensure segment string ending is valid
  if (params.segment && params.segment.endsWith(";")) {
    params.segment = params.segment.slice(0, -1);
  }

  return params;
}

export function getFlippedMetrics() {
  return [
    "bounceRate",
    "bounce_count",
    "entry_bounce_count",
    "exit_rate",
    "bounce_rate",
  ];
}

export function getPercentageMetrics() {
  return [
    "bounceRate",
    "exit_rate",
    "bounce_rate",
    "conversion_rate",
    "conversion_rate_new_visit",
    "conversion_rate_returning_visit",
    "crashFreeUsersRate",
    "engagementRate",
    "wauPerMau",
    "dauPerWau",
    "dauPerMau",
    "cartToViewRate",
    "firstTimePurchaserConversionRate",
    "purchaserConversionRate",
    "purchaseToViewRate",
    "userConversionRate",
    "userKeyEventRate",
    "sessionKeyEventRate",
    "purchaserRate",
    "firstTimePurchaserRate",
  ];
}

export function isFlippedMetric(metric) {
  return getFlippedMetrics().includes(metric);
}

export function isPercentagedMetric(metric) {
  return getPercentageMetrics().includes(metric);
}

export async function getClientMetricsView(uid, pid) {
  try {
    const docRef = doc(db, `users/${uid}/metrics/${pid}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (e) {}
}

export async function setClientMetricsViewBlue(uid, pid, cards) {
  try {
    const filteredCards = cards.filter(
      (card) => card.path !== "Google Tag Manager",
    );
    const docRef = doc(db, `users/${uid}/metrics/${pid}`);
    await setDoc(
      docRef,
      {
        blueMetrics: filteredCards,
      },
      { merge: true },
    );
  } catch (e) {
    console.error("Error setting blue metrics:", e);
  }
}

export async function setClientMetricsViewGrey(uid, pid, cards) {
  try {
    const docRef = doc(db, `users/${uid}/metrics/${pid}`);
    await setDoc(
      docRef,
      {
        greyMetrics: cards,
      },
      { merge: true },
    );
  } catch (e) {
    console.error("Error setting grey metrics:", e);
  }
}

export function getMatomoCardOptionsGeneral() {
  return [
    { value: "nb_uniq_visitors", label: "Unique Visitors" },
    { value: "nb_visits", label: "Visit count" },
    { value: "nb_users", label: "Unique user count" },
    { value: "nb_actions", label: "Action count" },
    { value: "sum_visit_length", label: "Total visit time" },
    { value: "bounce_count", label: "Bounce count" },
    { value: "max_actions", label: "Max Actions" },
    { value: "nb_visits_converted", label: "Converted Visits" },
    { value: "nb_conversions", label: "Conversions" },
    { value: "revenue", label: "Revenue of goals" },
  ];
}

export function getMatomoCardOptionsPage() {
  return [
    { value: "nb_hits", label: "Page views" },
    { value: "entry_nb_visits", label: "Entries" },
    { value: "entry_nb_uniq_visitors", label: "Unique entries" },
    { value: "entry_nb_actions", label: "Action count" },
    { value: "entry_sum_visit_length", label: "Total visit time of entries" },
    { value: "entry_bounce_count", label: "Bounce count" },
    { value: "exit_nb_visits", label: "Exits" },
    { value: "exit_nb_uniq_visitors", label: "Unique exits" },
    { value: "sum_time_spent", label: "Total time on page" },
    {
      value: "sum_daily_nb_uniq_visitors",
      label: "Total daily unique visitors",
    },
    {
      value: "sum_daily_entry_nb_uniq_visitors",
      label: "Total daily unique entries",
    },
    { value: "avg_time_on_page", label: "Average time on page" },
    { value: "bounce_rate", label: "Bounce rate" },
    { value: "exit_rate", label: "Exit rate" },
  ];
}

export function getMatomoCardOptionsEcommerce() {
  return [
    { value: "nb_conversions", label: "Number of Conversions" },
    { value: "nb_visits_converted", label: "Number of Visits Converted" },
    { value: "revenue", label: "Revenue" },
    { value: "conversion_rate", label: "Conversion Rate" },
    {
      value: "nb_conversions_new_visit",
      label: "Number of Conversions (New Visit)",
    },
    {
      value: "nb_visits_converted_new_visit",
      label: "Number of Visits Converted (New Visit)",
    },
    { value: "revenue_new_visit", label: "Revenue (New Visit)" },
    {
      value: "conversion_rate_new_visit",
      label: "Conversion Rate (New Visit)",
    },
    {
      value: "nb_conversions_returning_visit",
      label: "Conversions on returning visit",
    },
    {
      value: "nb_visits_converted_returning_visit",
      label: "Visits Converted on returning visit)",
    },
    { value: "revenue_returning_visit", label: "Revenue (Returning Visit)" },
    {
      value: "conversion_rate_returning_visit",
      label: "Conversion Rate (Returning Visit)",
    },
  ];
}

export function getMatomoCardOptionsEvents() {
  return [
    { value: "nb_events", label: "Number of Events" },
    { value: "nb_events_with_value", label: "Events with Value" },
    { value: "sum_event_value", label: "Total Event Values" },
    { value: "min_event_value", label: "Minimum Event Value" },
    { value: "max_event_value", label: "Maximum Event Value" },
    { value: "avg_event_value", label: "Average Event Value" },
  ];
}

function getMatomoDatatypeLabel(datatype) {
  const datatypeMap = {
    nb_uniq_visitors: "Unique Visitors",
    nb_visits: "Visit count",
    nb_users: "Unique user count",
    nb_actions: "Action count",
    sum_visit_length: "Total visit time",
    bounce_count: "Bounce count",
    max_actions: "Max Actions",
    nb_visits_converted: "Converted Visits",
    nb_conversions: "Conversions",
    revenue: "Revenue of goals",
    nb_hits: "Page views",
    entry_nb_visits: "Entries",
    entry_nb_uniq_visitors: "Unique entries",
    entry_nb_actions: "Action count",
    entry_sum_visit_length: "Total visit time of entries",
    entry_bounce_count: "Bounce count",
    exit_nb_visits: "Exits",
    exit_nb_uniq_visitors: "Unique exits",
    sum_time_spent: "Total time on page",
    sum_daily_nb_uniq_visitors: "Total daily unique visitors",
    sum_daily_entry_nb_uniq_visitors: "Total daily unique entries",
    avg_time_on_page: "Average time on page",
    bounce_rate: "Bounce rate",
    exit_rate: "Exit rate",
    quantity: "Sale Quantity",
    orders: "Orders",
    abandoned_carts: "Abandoned Carts",
    avg_price: "Average Price",
    avg_quantity: "Average Quantity",
    conversion_rate: "Conversion Rate",
    nb_events: "Number of Events",
    nb_events_with_value: "Events with Value",
    sum_event_value: "Total Event Values",
    min_event_value: "Minimum Event Value",
    max_event_value: "Maximum Event Value",
    avg_event_value: "Average Event Value",
    nb_conversions_new_visit: "Number of Conversions (New Visit)",
    nb_visits_converted_new_visit: "Number of Visits Converted (New Visit)",
    revenue_new_visit: "Revenue (New Visit)",
    conversion_rate_new_visit: "Conversion Rate (New Visit)",
    nb_conversions_returning_visit: "Conversions on returning visit",
    nb_visits_converted_returning_visit: "Visits Converted on returning visit",
    revenue_returning_visit: "Revenue (Returning Visit)",
    conversion_rate_returning_visit: "Conversion Rate (Returning Visit)",
  };

  return datatypeMap[datatype] || "Error";
}

function urlOfSequence(url, i, urlSequence) {
  return urlSequence[i] === decodeURIComponent(url) || false;
}

const filterSessionsByLocalTime = (sessions, localTimeValue) => {
  if (!localTimeValue) return sessions;

  const [start, end] = localTimeValue.split("~").map(Number);
  if (isNaN(start) || isNaN(end)) return sessions;

  return sessions.filter((dsnap) => {
    const timestamp = dsnap.data().createdAt;
    const userTimeZone = dsnap.data().timezone ?? "America/New_York";

    if (!(timestamp instanceof Timestamp)) return false;
    const date = timestamp.toDate();

    // Extract the hour in 24-hour format
    const localHour = new Date(
      date.toLocaleString("en-US", { timeZone: userTimeZone }),
    ).getHours();

    return localHour >= start && localHour < end;
  });
};

function getSearchEngines() {
  return [
    "google.ca",
    "google.com",
    "bing.com",
    "yahoo.com",
    "yandex.ru",
    "duckduckgo.com",
    "baidu.com",
    "ask.com",
    "naver.com",
    "ecosia.org",
    "aol.com",
    "archive.org",
    "yep.com",
    "brave.com",
    "andi.com",
    "perplexity.ai",
    "phind.com",
    "you.com",
    "komo.com",
  ];
}

export async function getHeatmapData(
  pid,
  urls,
  startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
  endTime = new Date(new Date().setDate(new Date().getDate() + 1)),
  devices,
  country,
  duration,
  localTime,
  browser,
  referrer,
  deviceBrand,
  resolution,
  formUse,
  city,
  MRAID,
  rootUrl,
) {
  const sessionDataRef = collection(db, "sessionData");
  startTime.setHours(0, 0, 0, 0);
  endTime.setHours(23, 59, 59, 999);

  const conditions = [
    where("projectId", "==", pid),
    where("lastUpdated", ">=", dateReactToFirestore(startTime)),
    where("lastUpdated", "<=", dateReactToFirestore(endTime)),
  ];

  if (devices.length > 0) {
    conditions.push(where("deviceType", "in", devices));
  }
  if (country.length > 0) {
    conditions.push(where("country", "in", country));
  }
  const q = query(sessionDataRef, ...conditions);

  const heatmaps = [];

  try {
    const querySnapshot = await getDocs(q);
    const insights = devices.includes("desktop")
      ? await getHeatmapInsights(pid, urls)
      : [];

    const elements = await getHeatmapElements(pid, urls, devices[0], MRAID);

    for (let index = 0; index < urls.length; index++) {
      const url = urls[index];

      if (url === "Error") {
        heatmaps.push({});
        continue;
      }
      const filteredElements = elements.filter(
        (element) => element.url === decodeURIComponent(url),
      );

      const heatmapData = {
        clicks: [],
        moves: [],
        scrolls: [],
        hesitations: [],
        insights: insights.filter(
          (insight) => insight.url === decodeURIComponent(url),
        ),
        elements:
          filteredElements.length > 0 ? filteredElements[0].elements : [],
      };

      let validSessions = querySnapshot.docs.filter((dsnap) =>
        urlOfSequence(url, index, dsnap.data().urlSequence),
      );

      // Filter duration
      if (duration && duration.value) {
        validSessions = validSessions.filter((dsnap) => {
          const value = dsnap.data().duration / 1000;
          const [start, end] = duration.value.split("~");
          if (start && end) {
            return value >= start && value <= end;
          }
          return true;
        });
      }

      // Filter localTime
      if (localTime && localTime.value) {
        validSessions = filterSessionsByLocalTime(
          validSessions,
          localTime.value,
        );
      }

      const applyFilter = (
        sessions,
        filterData,
        fieldName,
        specialCase = null,
      ) => {
        if (filterData && filterData.length > 0) {
          return sessions.filter((dsnap) => {
            const value = dsnap.data()[fieldName];
            if (specialCase && filterData.includes(specialCase)) {
              return !value || filterData.includes(value);
            }
            return filterData.includes(value);
          });
        }
        return sessions;
      };

      // Apply filters
      validSessions = applyFilter(validSessions, browser, "browser");
      validSessions = applyFilter(
        validSessions,
        deviceBrand,
        "deviceBrand",
        "Unknown",
      );
      validSessions = applyFilter(validSessions, city, "city", "Unknown");
      validSessions = applyFilter(
        validSessions,
        resolution,
        "resolution",
        "unknown",
      );

      // Filter referrer
      if (referrer && referrer.length > 0) {
        validSessions = validSessions.filter((dsnap) => {
          const ref = dsnap.data().referrer;
          const searchEngines = getSearchEngines();

          let referrerType;

          if (!ref || ref === "" || (rootUrl !== "" && ref.includes(rootUrl))) {
            referrerType = "direct";
          } else if (searchEngines.some((engine) => ref.includes(engine))) {
            referrerType = "search";
          } else {
            referrerType = "website";
          }

          return referrer.includes(referrerType);
        });
      }

      // Filter form usage
      if (formUse && formUse.length > 0) {
        validSessions = validSessions.filter((dsnap) => {
          const startForm = dsnap.data().startForm ?? 0;
          const submitForm = dsnap.data().submitForm ?? 0;
          let isValid = false;

          if (
            formUse.includes("No activity") &&
            startForm === 0 &&
            submitForm === 0
          ) {
            isValid = true;
          }

          if (formUse.includes("Started") && startForm === 1) {
            isValid = true;
          }

          if (formUse.includes("Did not submit") && submitForm === 0) {
            isValid = true;
          }

          if (formUse.includes("Submitted") && submitForm === 1) {
            isValid = true;
          }

          return isValid;
        });
      }

      const promises = validSessions.map(async (docSnapshot) => {
        const sessionId = docSnapshot.id;

        const clicksDocRef = doc(db, `sessionData/${sessionId}/clicks/${url}`);
        const movesDocRef = doc(db, `sessionData/${sessionId}/moves/${url}`);
        const scrollsDocRef = doc(
          db,
          `sessionData/${sessionId}/scrolls/${url}`,
        );
        const hesitationsDocRef = doc(
          db,
          `sessionData/${sessionId}/hesitations/${url}`,
        );

        const [
          clicksDocSnapshot,
          movesDocSnapshot,
          scrollsDocSnapshot,
          hesitationsDocSnapshot,
        ] = await Promise.all([
          getDoc(clicksDocRef),
          getDoc(movesDocRef),
          getDoc(scrollsDocRef),
          getDoc(hesitationsDocRef),
        ]);

        if (clicksDocSnapshot.exists()) {
          const clicksData = clicksDocSnapshot.data().clicks;
          if (clicksData) {
            heatmapData.clicks.push(...clicksData);
          }
        }

        if (movesDocSnapshot.exists()) {
          const movesData = movesDocSnapshot.data().moves;
          if (movesData) {
            heatmapData.moves.push(...movesData);
          }
        }

        if (hesitationsDocSnapshot.exists()) {
          const hesitationsData = hesitationsDocSnapshot.data().hesitations;
          if (hesitationsData) {
            heatmapData.hesitations.push(...hesitationsData);
          }
        }

        if (scrollsDocSnapshot.exists()) {
          const scrollsData = scrollsDocSnapshot.data().depth;
          if (scrollsData) {
            heatmapData.scrolls.push(scrollsData);
          }
        } else {
          heatmapData.scrolls.push(0);
        }
      });

      await Promise.all(promises);
      heatmaps.push(heatmapData);
    }
    return heatmaps;
  } catch (e) {
    console.error("Error getting heatmap data: ", e);
    throw e;
  }
}

export async function getHeatmapInsights(pid, urls) {
  const decodedUrls = urls.map((url) => decodeURIComponent(url));
  const insightsQuerySnapshot = await getDocs(
    query(
      collection(db, "insightsFast"),
      where("projectId", "==", pid),
      where("url", "in", decodedUrls),
    ),
  );
  const insightsTemp = [];
  for (const insightDoc of insightsQuerySnapshot.docs) {
    insightsTemp.push({
      ...insightDoc.data(),
      id: insightDoc.id,
    });
  }
  return insightsTemp;
}

export function beautifyPath(path) {
  if (!path) return "";
  const beautified = path
    .replace("/", "")
    .replace("-", " ")
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));

  return beautified === "" ? "Home" : beautified;
}

export async function getHeatmapElements(pid, urls, deviceRaw, MRAID) {
  const device = deviceRaw === "smartphone" ? "phone" : deviceRaw;
  const decodedUrls = urls.map((url) => decodeURIComponent(url));
  const elementsQuerySnapshot =
    !MRAID || MRAID === ""
      ? await getDocs(
          query(
            collection(db, "heatmapElements"),
            where("projectId", "==", pid),
            where("url", "in", decodedUrls),
            where("deviceType", "==", device),
          ),
        )
      : await getDocs(
          query(
            collection(db, "heatmapElements"),
            where("projectId", "==", pid),
            where("url", "in", decodedUrls),
            where("deviceType", "==", device),
            where("MRAID", "==", MRAID),
          ),
        );
  const elementsTemp = [];
  for (const elementDoc of elementsQuerySnapshot.docs) {
    elementsTemp.push({
      ...elementDoc.data(),
      id: elementDoc.id,
    });
  }
  return elementsTemp;
}

export function addHTTPS(url) {
  if (!url) return "";
  if (url.startsWith("http://") || url.startsWith("https://")) return url;
  return "https://" + url;
}

export function isValidMatomoProject(p) {
  if (!p) {
    return false;
  }
  return (
    p.matomoUrl &&
    p.matomoId &&
    p.matomoApiToken &&
    p.matomoUrl !== "" &&
    p.matomoId !== "" &&
    p.matomoApiToken !== ""
  );
}

export const saveNewUserEmail = async (uid, email) => {
  const userDocRef = doc(db, "users", uid);
  await updateDoc(userDocRef, { email });
};

export async function cascadePaymentplanToWorkspace(uid, plan) {
  let count = 0;
  const projectsRef = collection(db, "projects");
  const q = query(projectsRef, where("clientId", "==", uid));

  try {
    const querySnapshot = await getDocs(q);
    const uids = [];

    querySnapshot.forEach((doc) => {
      const projectData = doc.data();
      const workspace = projectData.workspace || [];

      workspace.forEach((map) => {
        if (map.uid) {
          uids.push(map.uid);
        }
      });
    });

    for (const uid of uids) {
      const userRef = doc(db, "users", uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        await updateDoc(userRef, {
          paymentPlan: plan,
        });
        count += 1;
      }
    }
  } catch (error) {}
  return count;
}

export const updateProjectApproval = async (pid, status) => {
  const docRef = doc(db, "projects", pid);
  await updateDoc(docRef, { awaitingAnalysis: status });
};

export const updateUserApproval = async (uid, status) => {
  const docRef = doc(db, "users", uid);
  await updateDoc(docRef, { awaitingApproval: status, hidden: status });
};

// Do not enqueue analysis if one has already started
export async function enqueueAnalysisGuarded(pid) {
  const docSnapshot = await getDoc(doc(db, "projects", pid));
  // if (docSnapshot.exists() && docSnapshot.data().analysisState === 0) {
  await updateDoc(doc(db, "projects", pid), {
    analysisState: 1,
  });
  startAnalysisMaestro({ projectId: pid });
  // }
}

export const uppercase1stLetter = (string) => {
  if (!string) {
    return;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export async function getClientsWaitingForApproval() {
  const ref = collection(db, "users");
  const q = query(ref, where("awaitingApproval", "==", true));

  try {
    const querySnapshot = await getDocs(q);
    const clients = querySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((client) => !client.hidden);
    return clients;
  } catch (error) {
    console.error("Error getting clients waiting for approval: ", error);
    throw error;
  }
}

export async function getChatroomsForAdmin() {
  const ref = collection(db, "channels");
  const q = query(ref, where("parties", "array-contains", ADMIN_ID));

  try {
    const querySnapshot = await getDocs(q);

    const chatrooms = await Promise.all(
      querySnapshot.docs.map(async (d) => {
        const chatroom = {
          id: d.id,
          ...d.data(),
        };

        const otherPartyId = chatroom.parties.find(
          (partyId) => partyId !== ADMIN_ID,
        );
        if (otherPartyId) {
          try {
            const userSnap = await getDoc(doc(db, "users", otherPartyId));
            if (userSnap.exists()) {
              const userData = userSnap.data();
              chatroom.clientFirstName = userData.firstName;
              chatroom.clientCompany = userData.companyName;
              chatroom.uid = userSnap.id;
              chatroom.icon = userSnap.data().pfp;

              if (userData.workspace) {
                const projectRef = doc(db, "projects", userData.workspace);
                const projectSnap = await getDoc(projectRef);
                if (projectSnap.exists()) {
                  chatroom.mainClientId = projectSnap.data().clientId;
                  chatroom.projects = [projectSnap.data().name];
                }
              } else {
                const projects = await getUserProjects({
                  role: "client",
                  uid: userSnap.id,
                });
                chatroom.projects = projects.map((project) => project.name);
              }
            } else {
              chatroom.user = null;
            }
          } catch (error) {
            chatroom.user = null;
          }
        }

        return chatroom;
      }),
    );

    // Filter out chatrooms with null users
    const filteredChatrooms = chatrooms.filter(
      (chatroom) => chatroom.user !== null,
    );

    // Sort chatrooms by latestMessage in reverse chronological order
    filteredChatrooms.sort((a, b) => {
      if (!a.latestMessage) return 1;
      if (!b.latestMessage) return -1;
      return b.latestMessage.toMillis() - a.latestMessage.toMillis();
    });

    // Add invited user clientCompany to chatrooms
    filteredChatrooms.map((c) => {
      if (!c.mainClientId) {
        return c;
      }
      const mainClientChatroom = chatrooms.find(
        (chatroom) => chatroom.uid === c.mainClientId,
      );

      if (mainClientChatroom) {
        c.clientCompany = mainClientChatroom.clientCompany;
      }
      return c;
    });

    return filteredChatrooms;
  } catch (error) {
    console.error("Error getting chatrooms for admin: ", error);
    throw error;
  }
}

export function dateFirestoreToReactDaysAgo(date) {
  if (!date) return "";
  const now = new Date();
  const then = date.toDate();
  const diff = now - then;
  const daysAgo = Math.floor(diff / (1000 * 60 * 60 * 24));
  if (daysAgo === 0) {
    return "Today";
  }
  if (daysAgo === 1) {
    return "Yesterday";
  }
  return `${daysAgo} days ago`;
}

export const AdminTable = ({ widths, headers, rows }) => {
  return (
    <table className="reports-table">
      <thead>
        <tr className="d-flex align-items-center reports-header">
          {headers.map((header, i) => (
            <th
              style={{
                width: `${(widths[i] / 1170) * 100}%`,
                minWidth: `${widths[i]}px`,
              }}
              key={i}
              className="ml-20 d-flex align-items-center flex-row"
            >
              {header.icon}
              <div className="fs-16 fc-grey ml-10">{header.title}</div>
            </th>
          ))}
        </tr>
      </thead>
      {rows &&
        rows.map((p, i) => {
          return (
            <tbody key={i} className="admin-expand-tbody-border-bottom">
              <tr
                className={`d-flex align-items-center ${
                  p.expanded ? "border-none" : ""
                }`}
                style={{ paddingTop: "7px", paddingBottom: "7px" }}
              >
                {headers.map((header, j) => (
                  <td
                    className="ml-20"
                    key={j}
                    style={{
                      width: `${(widths[j] / 1170) * 100}%`,
                      minWidth: `${widths[j]}px`,
                    }}
                  >
                    {header.render && header.render(p, i)}
                  </td>
                ))}
              </tr>

              {p.expanded && (
                <tr
                  key={i}
                  className={`d-flex align-items-center expand-transition ${
                    p.expanded ? "expanded" : ""
                  }`}
                >
                  {headers.map((header, j) => (
                    <td
                      className="ml-20"
                      key={j}
                      style={{
                        width: `${(widths[j] / 1170) * 100}%`,
                        minWidth: `${widths[j]}px`,
                      }}
                    >
                      {header.renderExpand && header.renderExpand(p, i)}
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          );
        })}
    </table>
  );
};
