import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import upload from "../../assets/upload.svg";
import { BsPlusLg } from "react-icons/bs";
import close1 from "../../assets/close-1.svg";
import { useContext, useEffect, useState, useRef, useMemo } from "react";
import "../../components/CustomInput/CustomInput.css";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import CustomCollapse from "../CustomCollapse";
import "./UxInsights.css";
import "../../components/CreateReport/CreateReport.css";
import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { FiX } from "react-icons/fi";
import arrowRight from "../../assets/arrow-right.svg";
import {
  uploadFileToFirestore,
  validateDateForPicker,
  formatDateForPicker,
  getCardOptions,
  getClientOfProject,
  newInsightNotification,
} from "../../helpers";
import { UserInfoContext } from "../../contexts";
import CustomInput from "../CustomInput/CustomInput";
import Modal from "react-modal";
import xButton from "../../assets/xbutton.svg";
import html2canvas from "html2canvas";
import { wait } from "@testing-library/user-event/dist/utils";
import { IoTrashOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export default function UxInsights({ handleActiveInsightChange, ...props }) {
  const userInfo = useContext(UserInfoContext);
  const [openedObservation, setOpenedObservation] = useState(null);
  const [severityMenuOpen, setSeverityMenuOpen] = useState(false);
  const [fixEaseMenuOpen, setFixEaseMenuOpen] = useState(false);
  const [metricMenuOpen, setMetricMenuOpen] = useState(false);
  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorTypeMenuOpen, setIsErrorTypeMenuOpen] = useState(false);
  const [indexBeingDeleted, setIndexBeingDeleted] = useState(null);

  const [actionDropdownText, setActionDropdownText] = useState(null);
  const [isActionTypeMenuOpen, setIsActionTypeMenuOpen] = useState(false);
  const [doubleSubmit, setDoubleSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [insightIdToDelete, setInsightIdToDelete] = useState(null);

  const navigate = useNavigate();

  const calRef = useRef();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [datePickedTemp, setDatePickedTemp] = useState(new Date());
  const [datePickerValueState, setDatePickerValueState] = useState("");
  const [pageName, setPageName] = useState("/");
  const [uxRule, setUxRule] = useState("");
  const [isMetric, setIsMetric] = useState(false);

  const metricOptions = getCardOptions();

  useEffect(() => {
    const isMetric = openedObservation?.uxErrorType?.some(
      (item) => item.value === "metric",
    );
    setIsMetric(!!isMetric);
  }, [openedObservation]);

  function submitReport() {
    const reportRef = doc(db, "reports", props.reportId);
    updateDoc(reportRef, { submitted: true });
    navigate("/reports");
  }

  async function deleteInsight(insightId) {
    setIndexBeingDeleted(insightId);

    await deleteDoc(doc(db, "insights", insightId));
    props.handleDeleteObservation(insightId);

    setIndexBeingDeleted(null);
  }

  function handleSetOpened(insightId) {
    if (props.activeInsight === insightId) {
      props.setActiveInsight(null);
      setOpenedObservation(null);
    } else {
      props.setActiveInsight(insightId);
    }
  }

  /**
   * Gets the observation and actions from the database and sets them in the state.
   * @returns {Promise<void>}
   */
  async function getObservationAndActions() {
    if (props.activePage && props.activeInsight) {
      const insightDoc = await getDoc(doc(db, "insights", props.activeInsight));

      if (insightDoc.data()?.screenshotUrl) {
        setImageCache((prevState) => {
          return {
            ...prevState,
            [insightDoc.id]: {
              selectedImage: new File(
                [],
                insightDoc
                  .data()
                  .screenshotUrl.replace(/\?.*$/, "") // Remove query string
                  .replace(/^.*(%2F|\/)/, ""), // Remove path
                {
                  type: "image/png",
                },
              ),
              imagePreview: insightDoc.data().screenshotUrl,
            },
          };
        });
      }

      if (!insightDoc.exists() || !insightDoc.data()) return;

      const { uxErrorType, ...uxInsightDocWithoutErrorType } =
        insightDoc.data();
      let uxErrorTypeTemp = [];
      if (uxErrorType) {
        uxErrorType.map(({ value }) => {
          uxErrorTypeTemp.push(
            uxErrorOptions.find((option) => option.value === value),
          );
        });
      }

      setOpenedObservation({
        id: insightDoc.id,
        uxErrorType: uxErrorTypeTemp,
        ...uxInsightDocWithoutErrorType,
      });

      setPageName({
        label: insightDoc.data().pageName,
        value: insightDoc.data().pageName,
      });

      // Fetch actions for the insight.
      const actionsSnapshot = await getDocs(
        collection(
          doc(db, "reports", props.reportId, "insights", props.activeInsight),
          "actions",
        ),
      );
      const actionsTemp = [];
      for (const doc of actionsSnapshot.docs) {
        actionsTemp.push({ id: doc.id, ...doc.data() });
      }

      setActions(() => {
        return actionsTemp.length === 0 ? [{}] : actionsTemp;
      });
      setSelectedAction(0);
      setDatePickedTemp(
        validateDateForPicker(
          actionsTemp[0]?.deliveryDate instanceof Timestamp
            ? actionsTemp[0]?.deliveryDate.toDate()
            : actionsTemp[0]?.deliveryDate,
        ),
      );
      setDatePickerValueState(
        formatDateForPicker(
          validateDateForPicker(
            actionsTemp[0]?.deliveryDate instanceof Timestamp
              ? actionsTemp[0]?.deliveryDate.toDate()
              : actionsTemp[0]?.deliveryDate,
          ),
        ),
      );
    } else {
      setOpenedObservation(null);
    }
  }

  useEffect(() => {
    if (isDatePickerOpen) {
      setDatePickerValueState("Selecting");
    } else {
      setDatePickerValueState(
        formatDateForPicker(
          validateDateForPicker(actions[selectedAction]?.deliveryDate),
        ),
      );
    }
  }, [isDatePickerOpen]);

  async function loadObservations() {
    setLoading(true);
    await getObservationAndActions();
    setLoading(false);
  }

  // Load observations when the active insight changes.
  useEffect(() => {
    loadObservations();
  }, [props.activeInsight, props.activePage.name]);

  useEffect(() => {
    setActionDropdownText(actions[selectedAction]?.actionType);
    setDatePickedTemp(
      validateDateForPicker(
        actions[selectedAction]?.deliveryDate instanceof Timestamp
          ? actions[selectedAction]?.deliveryDate.toDate()
          : actions[selectedAction]?.deliveryDate,
      ),
    );
    setDatePickerValueState(
      formatDateForPicker(
        validateDateForPicker(
          actions[selectedAction]?.deliveryDate instanceof Timestamp
            ? actions[selectedAction]?.deliveryDate.toDate()
            : actions[selectedAction]?.deliveryDate,
        ),
      ),
    );
  }, [selectedAction]);

  const [imageCache, setImageCache] = useState({});

  // Modify the image cache when a new image is selected.
  const onSelectImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setIsModalOpen(true);

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setImageCache((prevState) => {
      return {
        ...prevState,
        [openedObservation?.id]: {
          selectedImage: e.target.files[0],
          imagePreview: imageUrl,
        },
      };
    });
  };

  // Check if an image has been selected.
  const hasSelectedImage = useMemo(() => {
    if (!openedObservation?.id) return false;
    return !!(
      imageCache[openedObservation?.id]?.selectedImage &&
      imageCache[openedObservation?.id]?.imagePreview
    );
  }, [imageCache[openedObservation?.id]]);

  /**
   * Starts a new observation.
   */
  const startNewObservation = () => {
    const newInsightRef = doc(collection(db, "insights"));

    setOpenedObservation({
      id: newInsightRef.id,
    });
    setActions([]);
    setActionDropdownText(null);

    setDoc(newInsightRef, {
      createdAt: new Date(),
      pageName: props.activePage.name ?? "/",
      title: "",
      num:
        props.insights.length > 0
          ? Math.max(...props.insights.map((insight) => insight.num)) + 1
          : 1,
    });

    props.handleAddNewObservation({
      id: newInsightRef.id,
      title: "",
      pageName: props.activePage.name ?? "/",
      num:
        props.insights.length > 0
          ? Math.max(...props.insights.map((insight) => insight.num)) + 1
          : 1,
    });
  };

  /**
   * Saves the insight to the database.
   * @param observation - The insight to save.
   * @returns {Promise<void>} - A promise that resolves when the insight is saved.
   */
  function saveInsight(observation) {
    return new Promise(async (resolve, reject) => {
      try {
        setOpenedObservation(null);
        props.handleSavedInsight();

        const { id, uxErrorType, fixEase, severity, ...observationWithoutId } =
          observation;
        let uxErrorTypes = [];
        if (uxErrorType)
          uxErrorType.map((errorType) => {
            uxErrorTypes.push({ value: errorType.value });
          });

        await setDoc(
          doc(db, "insights", id),
          {
            ...observationWithoutId,
            projectId: props.projectId,
            uxErrorType: uxErrorTypes,
            fixEase: isMetric ? null : fixEase ?? null,
            severity: isMetric ? null : severity ?? null,
            pageName: props.activePage.name ?? "/",
            screenshotUrl:
              imageCache[openedObservation?.id]?.imagePreview ?? null,
          },
          { merge: true },
        );

        // getClientOfProject(props.projectId).then((cid) => {
        //   newInsightNotification(props.projectId, cid, id );
        // });

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  const resizeBasises = {
    move: "move",
    top: "ns",
    bottom: "ns",
    left: "ew",
    right: "ew",
    "top-right": "nesw",
    "bottom-left": "nesw",
    "bottom-right": "nwse",
  };

  const directionDeltas = {
    top: { start: [0, 1], end: [0, 0] },
    bottom: { start: [0, 0], end: [0, 1] },
    left: { start: [1, 0], end: [0, 0] },
    right: { start: [0, 0], end: [1, 0] },
    "top-right": { start: [0, 1], end: [1, 0] },
    "bottom-left": { start: [1, 0], end: [0, 1] },
    "bottom-right": { start: [0, 0], end: [1, 1] },
  };

  /**
   * An interface which will return the mouse position.
   * @returns {{x: null, y: null}} - The mouse position.
   */
  const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({
      x: null,
      y: null,
    });
    useEffect(() => {
      if (isModalOpen) {
        const updateMousePosition = (ev) => {
          setMousePosition({ x: ev.clientX, y: ev.clientY });
        };
        window.addEventListener("mousemove", updateMousePosition);
        return () => {
          window.removeEventListener("mousemove", updateMousePosition);
        };
      }
      return () => {};
    }, [isModalOpen]);
    return mousePosition;
  };

  const mousePosition = useMousePosition();

  const [currentHighlight, setCurrentHighlight] = useState(undefined);
  const [inProgressHighlight, setInProgressHighlight] = useState(undefined);
  const [cachedBoundingRect, setCachedBoundingRect] = useState(undefined);
  const [highlightMove, setHighlightMove] = useState(undefined);
  const [isMoving, setIsMoving] = useState(false);
  const [cursor, setCursor] = useState("default");
  const [highlightResize, setHighlightResize] = useState(undefined);
  const [isResizing, setIsResizing] = useState(false);
  const [resizeBasis, setResizeBasis] = useState(undefined);

  /**
   * Gets the direction of the cursor relative to the highlight.
   * @param cursorX - The x position of the cursor.
   * @param cursorY - The y position of the cursor.
   * @param highlight - The highlight to check.
   * @param boundingRect - The bounding rect of the highlight.
   * @param borderSize - The size of the border of the highlight.
   * @returns {string} - The direction of the cursor relative to the highlight.
   */
  function getDirection(cursorX, cursorY, highlight, boundingRect, borderSize) {
    const { start, end } = highlight;
    const { x, y } = boundingRect;

    // The positions of the highlight with the border removed.
    const hStartX = start[0] + x,
      hStartY = start[1] + y,
      hEndX = end[0] + x,
      hEndY = end[1] + y;

    // Check if the cursor is within the highlight.
    if (
      cursorX > hStartX &&
      cursorX < hEndX &&
      cursorY > hStartY &&
      cursorY < hEndY
    ) {
      const hStartXBorder = hStartX + borderSize,
        hStartYBorder = hStartY + borderSize,
        hEndXBorder = hEndX - borderSize,
        hEndYBorder = hEndY - borderSize;

      // Check if the cursor is within the border of the highlight, then deduce what the return string should be.
      switch (true) {
        case cursorX > hStartXBorder &&
          cursorX < hEndXBorder &&
          cursorY > hStartYBorder &&
          cursorY < hEndYBorder:
          return "move";
        case cursorX > hEndXBorder && cursorY < hStartYBorder:
          return "top-right";
        case cursorX < hStartXBorder && cursorY > hEndYBorder:
          return "bottom-left";
        case cursorX > hEndXBorder && cursorY > hEndYBorder:
          return "bottom-right";
        case cursorX > hEndXBorder:
          return "right";
        case cursorX < hStartXBorder:
          return "left";
        case cursorY < hStartYBorder:
          return "top";
        case cursorY > hEndYBorder:
          return "bottom";
        default:
          break;
      }
    }
  }

  /**
   * Decides if the highlight is being moved, or if it is being resized. This is done through the border bounds checking.
   * @param e - The mouse down event.
   */
  const onMoveHighlightMouseDown = (e) => {
    e.preventDefault();
    const direction = getDirection(
      e.clientX,
      e.clientY,
      currentHighlight,
      cachedBoundingRect,
      10,
    );
    if (direction === "move") {
      setHighlightMove([e.clientX, e.clientY]);
      setIsMoving(true);
    } else if (direction) {
      setResizeBasis(direction);
      setHighlightResize([e.clientX, e.clientY]);
      setIsResizing(true);
    }
  };

  /**
   * Handles the mouse down event on the highlight itself.
   * @param e - The mouse down event.
   * @param calledFromWithin - Whether or not the function was called from within the component.
   */
  const onHighlightMouseDown = (e, calledFromWithin = false) => {
    e.preventDefault();
    let boundingRect = cachedBoundingRect;
    // If the function was not called from within the component, get the bounding rect of the highlight.
    if (!calledFromWithin) {
      boundingRect = e.target.getBoundingClientRect();
      setCachedBoundingRect(boundingRect);
    }
    // Set the in progress highlight to the current mouse position.
    setInProgressHighlight({
      start: [e.clientX - boundingRect.x, e.clientY - boundingRect.y],
    });
  };

  /**
   * Deduce if the highlight is being moved or resized, and then set the appropriate states dependent on this.
   * @param e - The mouse up event.
   * @param calledFromWithin - Whether or not the function was called from within the component.
   */
  const onHighlightMouseUp = (e, calledFromWithin = false) => {
    e.preventDefault();
    let boundingRect = cachedBoundingRect;
    // If the function was not called from within the component, get the bounding rect of the highlight.
    if (!calledFromWithin) {
      boundingRect = e.target.getBoundingClientRect();
      setCachedBoundingRect(boundingRect);
    }

    // Set the states appropriately depending on if it's being moved, resized, or if the highlight is being created.
    if (highlightMove && isMoving) {
      setHighlightMove(undefined);
      setIsMoving(false);
    } else if (highlightResize && isResizing) {
      setIsResizing(false);
      setHighlightResize(undefined);
    } else if (inProgressHighlight) {
      setCurrentHighlight({
        ...inProgressHighlight,
        current: true,
        end: [e.clientX - boundingRect.x, e.clientY - boundingRect.y],
      });
      setInProgressHighlight(null);
    }
  };

  /**
   * Handle in progress highlight creation, as well as highlight movement and resizing. Also handle setting the cursor.
   * This is going to be called every time the mouse position changes.
   */
  useEffect(() => {
    if (highlightMove && isMoving) {
      // Highlight is being moved, so we need to calculate the new start and end positions.
      const newStart = [
        currentHighlight.start[0] + mousePosition.x - highlightMove[0],
        currentHighlight.start[1] + mousePosition.y - highlightMove[1],
      ];
      const newEnd = [
        currentHighlight.end[0] + mousePosition.x - highlightMove[0],
        currentHighlight.end[1] + mousePosition.y - highlightMove[1],
      ];
      if (
        newStart[0] > 0 &&
        newStart[1] > 0 &&
        newEnd[0] < cachedBoundingRect.width &&
        newEnd[1] < cachedBoundingRect.height
      ) {
        setCurrentHighlight((prevState) => {
          return {
            ...prevState,
            start: newStart,
            end: newEnd,
          };
        });
        setHighlightMove([mousePosition.x, mousePosition.y]);
      }
    } else if (highlightResize && isResizing) {
      // Highlight is being resized, so we need to calculate the new start and end positions
      const delta = directionDeltas[resizeBasis];
      const start = [
        currentHighlight.start[0] +
          (mousePosition.x - highlightResize[0]) * delta.start[0],
        currentHighlight.start[1] +
          (mousePosition.y - highlightResize[1]) * delta.start[1],
      ];
      const end = [
        currentHighlight.end[0] +
          (mousePosition.x - highlightResize[0]) * delta.end[0],
        currentHighlight.end[1] +
          (mousePosition.y - highlightResize[1]) * delta.end[1],
      ];
      const realStart = [
        Math.min(start[0], end[0]),
        Math.min(start[1], end[1]),
      ];
      const realEnd = [Math.max(start[0], end[0]), Math.max(start[1], end[1])];
      if (
        realStart[0] > 0 &&
        realStart[1] > 0 &&
        realEnd[0] < cachedBoundingRect.width &&
        realEnd[1] < cachedBoundingRect.height
      ) {
        setCurrentHighlight((prevState) => {
          return { ...prevState, start, end };
        });
        setHighlightResize([mousePosition.x, mousePosition.y]);
      }
    } else if (inProgressHighlight) {
      // Simple highlight creation, so we just need to set the end position.
      setInProgressHighlight((prevState) => {
        return {
          ...prevState,
          end: [
            mousePosition.x - cachedBoundingRect.x,
            mousePosition.y - cachedBoundingRect.y,
          ],
        };
      });
    }
    if (currentHighlight) {
      // Set the cursor to the appropriate value, depending on if the highlight is being moved or resized. This is only for hovering, as the actual actions have been covered above.
      const direction =
        resizeBasises[
          getDirection(
            mousePosition.x,
            mousePosition.y,
            currentHighlight,
            cachedBoundingRect,
            10,
          )
        ];
      setCursor(direction === "move" ? "move" : `${direction}-resize`);
    }
  }, [mousePosition]);

  const appliedHighlightRef = useRef();
  const [takingScreenshot, setTakingScreenshot] = useState(false);

  /**
   * Applies the highlight to the screenshot and saves it to the database, using HTML2Canvas for simplicity.
   * @returns {(function(): Promise<void>)|*}
   */
  const applyHighlights = () => async () => {
    setTakingScreenshot(true);
    await wait(10); // this is done to allow the page to actually refresh, 10 was chosen as it was the lowest value that worked.

    // Initialize the canvas using the screenshot reference.
    const canvas = await html2canvas(appliedHighlightRef.current);
    // Create the new image blob, then upload it to the DB.
    // Update image cache, and then set the screenshot url in the database.
    canvas.toBlob(async (blob) => {
      setIsModalOpen(false);
      const file = new File([blob], "screenshot.png", { type: "image/png" });
      const imgDBUrl = await uploadFileToFirestore(
        "reports/" + props.reportId + "/insights/" + openedObservation?.id,
        file,
      );

      setImageCache((prevState) => {
        return {
          ...prevState,
          [openedObservation?.id]: {
            ...prevState[openedObservation?.id],
            imagePreview: imgDBUrl,
          },
        };
      });

      // Clear states.
      setInProgressHighlight(undefined);
      setCurrentHighlight(undefined);
      setHighlightMove(undefined);
      setHighlightResize(undefined);
      setCursor("default");
      setIsResizing(false);
      setIsMoving(false);
      setResizeBasis(undefined);
      setCachedBoundingRect(undefined);
    });
    setTakingScreenshot(false);
  };

  const uxErrorOptions = [
    {
      value: "navigationAndStructure",
      label: (
        <>
          <i className="fa-solid fa-block-brick me-2"></i>Navigation & Structure
        </>
      ),
    },
    {
      value: "designAndTypography",
      label: (
        <>
          <i className="fa-solid fa-brush me-2"></i>Design & Typography
        </>
      ),
    },
    {
      value: "accessibilityAndConformity",
      label: (
        <>
          <i className="fa-brands fa-accessible-icon me-2"></i>Accessibility &
          Conformity
        </>
      ),
    },
    {
      value: "contentAndEngagement",
      label: (
        <>
          <i className="fa-solid fa-book me-2"></i>Content & Engagement
        </>
      ),
    },
    {
      value: "mobileAndResponsive",
      label: (
        <>
          <i className="fa-solid fa-mobile me-2"></i>Mobile & Responsive
        </>
      ),
    },
    {
      value: "metric",
      label: (
        <>
          <i className="fa-solid fa-chart-simple"></i> Metric
        </>
      ),
    },
  ];

  const severityOptions = [
    { value: "not-usability", label: "Not a usability problem" },
    { value: "cosmetic", label: "Cosmetic" },
    { value: "minor", label: "Minor" },
    { value: "major", label: "Major" },
    { value: "critical", label: "Critical" },
    { value: "manual", label: "Manual" },
  ];

  const fixEaseOptions = [
    { value: "very-easy", label: "Very easy to fix" },
    { value: "easy", label: "Easy to fix" },
    { value: "challenging", label: "Challenging to fix" },
    { value: "very-challenging", label: "Very challenging to fix" },
    { value: "unfixable", label: "Unfixable" },
  ];

  const actionTypeOptions = [
    { value: "user-tests", label: "User tests" },
    { value: "expert-review", label: "Expert review" },
    { value: "heuristic-evaluation", label: "Heuristic evaluation" },
    { value: "analytics", label: "Analytics" },
    { value: "ab-testing", label: "A/B testing" },
    { value: "other", label: "Other" },
  ];

  const CustomMultiValue = ({ data }) => {
    return (
      openedObservation?.uxErrorType &&
      openedObservation?.uxErrorType[0].value === data.value && (
        <div>
          {openedObservation?.uxErrorType.slice(0, 2).map((option, index) => (
            <span key={option.value}>
              {option.label}
              {index < openedObservation?.uxErrorType.length - 1 ? ", " : null}
            </span>
          ))}
          {openedObservation?.uxErrorType.length > 2
            ? `+ ${openedObservation?.uxErrorType.length - 2} other${
                openedObservation?.uxErrorType.length === 3 ? "" : "s"
              }`
            : null}
        </div>
      )
    );
  };

  return (
    <div>
      <div
        style={{
          marginTop: "51px",
        }}
      >
        <h4>
          <b>{userInfo?.firstName ?? "User"},</b>
          <p>What are the most interesting insights you can share ?</p>
        </h4>
      </div>
      <Button
        className="d-flex align-items-center justify-content-center"
        style={{
          height: "44px",
          marginTop: "40px",
          marginBottom: "50px",
          width: "201.2px",
        }}
        disabled={!props?.activePage?.name}
        onClick={startNewObservation}
      >
        <BsPlusLg
          style={{
            width: "18px",
            height: "18px",
            marginRight: "12px",
          }}
        />
        Add new observation
      </Button>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Insight Delete Modal"
        className="insight-delete-modal modal-shape d-flex flex-column align-items-center justify-content-around"
        appElement={document.getElementById("root")}
        style={{
          overlay: {
            background: "rgba(7, 21, 43, 0.7)",
            zIndex: 10,
          },
        }}
      >
        <h4 className="text-center">
          Are you sure you want to delete this insight?
        </h4>
        <div className="d-flex justify-content-center insight-delete-button-height">
          <Button
            type="button"
            className="btn-primary me-3"
            onClick={() => {
              deleteInsight(insightIdToDelete);
              setIsDeleteModalOpen(false);
            }}
          >
            Delete
          </Button>
          <Button
            type="button"
            className="btn-secondary"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <div style={{ minHeight: "350px", maxWidth: "548px" }}>
        {props?.insights?.map((observation, index) => {
          if (observation.pageName === props?.activePage?.name) {
            return (
              <div className="d-flex" key={index}>
                <div style={{ margin: "11px 20px 0px 0px" }}>
                  {indexBeingDeleted === observation.id ? (
                    <Spinner />
                  ) : (
                    <IoTrashOutline
                      size="24px"
                      color="var(--main-blue)"
                      onClick={() => {
                        setInsightIdToDelete(observation.id);
                        setIsDeleteModalOpen(true);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>
                <div style={{ flexGrow: "1" }}>
                  <CustomCollapse
                    key={index}
                    isUxInsightsPage
                    openIsParentManaged
                    parentOpen={props.activeInsight === observation.id}
                    setOpen={() => handleSetOpened(observation.id)}
                    title={
                      <div className="d-flex">
                        <h5>
                          {observation.title
                            ? observation.title
                            : "This insight doesn't have a title yet."}
                        </h5>
                      </div>
                    }
                    content={
                      props.activeInsight === observation.id ? (
                        !loading ? (
                          <div style={{ width: "100%" }}>
                            <div>
                              <div className="insight-input">
                                <CustomInput
                                  containerClassName="observation-text-input-container insight-input"
                                  inputClassNames="insight-input"
                                  placeholder="Give a clear explained title to the observation"
                                  className={
                                    openedObservation?.title ? "active" : ""
                                  }
                                  valueState={openedObservation?.title ?? ""}
                                  onChange={(e) => {
                                    setOpenedObservation((prevObs) => {
                                      return {
                                        ...prevObs,
                                        title: e.target.value,
                                      };
                                    });
                                    props.handleObservationTitleUpdate(
                                      e.target.value,
                                    );
                                  }}
                                />
                              </div>
                              <CustomInput
                                containerClassName="observation-text-input-container insight-input"
                                inputClassNames="insight-input"
                                placeholder="Write a description"
                                description={
                                  <>
                                    <>This will be the name of the slide.</>
                                    <br />
                                    <>
                                      So, be sure that the title is super clear.
                                    </>
                                  </>
                                }
                                isTextArea
                                valueState={
                                  openedObservation?.description ?? ""
                                }
                                className={
                                  openedObservation?.description ? "active" : ""
                                }
                                onChange={(e) =>
                                  setOpenedObservation((prevObs) => {
                                    return {
                                      ...prevObs,
                                      description: e.target.value,
                                    };
                                  })
                                }
                              />
                              <div
                                className="insight-input"
                                style={{
                                  marginTop: "6px",
                                }}
                              >
                                <CustomDropdown
                                  isMulti
                                  hideSelectedOptions={false}
                                  options={uxErrorOptions}
                                  onChange={(e) => {
                                    setOpenedObservation((prevObs) => {
                                      return {
                                        ...prevObs,
                                        uxErrorType: e,
                                      };
                                    });
                                  }}
                                  value={openedObservation?.uxErrorType ?? null}
                                  placeholder="UX Error Type"
                                  unstyled
                                  closeMenuOnSelect={false}
                                  blurInputOnSelect={false}
                                  isClearable={false}
                                  menuOpenState={isErrorTypeMenuOpen}
                                  setMenuOpenState={setIsErrorTypeMenuOpen}
                                  components={{
                                    MultiValue: CustomMultiValue,
                                    // Option: checkCircle,
                                  }}
                                />
                              </div>
                              <div
                                className="d-flex insight-input align-items-center justify-content-center"
                                style={{
                                  padding: "12px",
                                  borderRadius: "8px",
                                  marginTop: "10px",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h6
                                  style={{
                                    margin: "0",
                                    width: "90%",
                                  }}
                                >
                                  Upload screenshot
                                </h6>
                                {hasSelectedImage ? (
                                  <>
                                    <button
                                      type="button"
                                      className="btn-tertiary d-flex align-items-center my-3"
                                    >
                                      <img
                                        src={
                                          imageCache[openedObservation?.id]
                                            .imagePreview
                                        }
                                        alt="Project"
                                        className="project-image-preview me-3"
                                      />
                                      <div className="project-image-name">
                                        {
                                          imageCache[openedObservation?.id]
                                            .selectedImage.name
                                        }
                                      </div>
                                      <FiX
                                        className="ms-3"
                                        size="18px"
                                        onClick={() => {
                                          setImageCache((prevState) => {
                                            return {
                                              ...prevState,
                                              [openedObservation?.id]: {
                                                selectedImage: undefined,
                                                imagePreview: undefined,
                                              },
                                            };
                                          });
                                        }}
                                      />
                                    </button>
                                    <Modal
                                      isOpen={isModalOpen}
                                      contentLabel="Example Modal"
                                      className="d-flex flex-column align-items-center custom-modal highlight-modal"
                                      appElement={document.getElementById(
                                        "root",
                                      )}
                                      style={{
                                        overlay: {
                                          zIndex: 10,
                                          backgroundColor: "#07152BB2",
                                        },
                                      }}
                                    >
                                      <button
                                        onClick={() => setIsModalOpen(false)}
                                        className="align-self-start highlight-close-button"
                                      >
                                        <img
                                          src={xButton}
                                          alt="X button"
                                          size="30px"
                                          color="#1F1F24"
                                        />
                                      </button>

                                      <div className="align-self-start">
                                        <h2>Add highlight</h2>
                                        <h5>
                                          Move and resize the{" "}
                                          <span style={{ color: "#E4411D" }}>
                                            red highlight zone
                                          </span>{" "}
                                          to show the error on the screenshot
                                        </h5>
                                      </div>

                                      <div
                                        ref={appliedHighlightRef}
                                        style={{
                                          position: "relative",
                                          zIndex: 1,
                                        }}
                                      >
                                        <img
                                          onMouseDown={onHighlightMouseDown}
                                          onMouseUp={onHighlightMouseUp}
                                          style={{
                                            borderRadius: !takingScreenshot
                                              ? "10px"
                                              : "",
                                            border: !takingScreenshot
                                              ? "5px solid black"
                                              : "5px solid transparent",
                                            WebkitUserDrag: "none",
                                            maxWidth: "70vw",
                                            maxHeight: "65vh",
                                          }}
                                          alt="selected img"
                                          src={
                                            imageCache[openedObservation?.id]
                                              .imagePreview
                                          }
                                        />
                                        {[
                                          inProgressHighlight,
                                          currentHighlight,
                                        ].map((highlight, index) => {
                                          if (
                                            highlight &&
                                            highlight.end &&
                                            highlight.start
                                          ) {
                                            const highlightStartX =
                                                highlight.start[0],
                                              highlightStartY =
                                                highlight.start[1];
                                            const highlightEndX =
                                                highlight.end[0],
                                              highlightEndY = highlight.end[1];

                                            const maxX = Math.max(
                                                highlightStartX,
                                                highlightEndX,
                                              ),
                                              minX = Math.min(
                                                highlightStartX,
                                                highlightEndX,
                                              );
                                            const maxY = Math.max(
                                                highlightStartY,
                                                highlightEndY,
                                              ),
                                              minY = Math.min(
                                                highlightStartY,
                                                highlightEndY,
                                              );

                                            return (
                                              <div
                                                key={index}
                                                onMouseDown={
                                                  highlight.current
                                                    ? (e) => {
                                                        onMoveHighlightMouseDown(
                                                          e,
                                                        );
                                                      }
                                                    : null
                                                }
                                                onMouseUp={(e) =>
                                                  onHighlightMouseUp(e, true)
                                                }
                                                style={{
                                                  margin: 0,
                                                  cursor: highlight.current
                                                    ? cursor
                                                    : "",
                                                  position: "absolute",
                                                  left: minX,
                                                  top: minY,
                                                  width: maxX - minX,
                                                  height: maxY - minY,
                                                }}
                                              >
                                                {!takingScreenshot &&
                                                  highlight.current && (
                                                    <Button
                                                      className="new-highlight-close-button"
                                                      onClick={() =>
                                                        setCurrentHighlight(
                                                          undefined,
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        alt="X"
                                                        src={close1}
                                                      />
                                                    </Button>
                                                  )}
                                                <div className="new-highlight" />
                                              </div>
                                            );
                                          }
                                        })}
                                      </div>
                                      <div className="align-self-end mt-5 highlight-button-holder">
                                        <Button onClick={applyHighlights()}>
                                          <h5>Apply</h5>
                                          <img src={arrowRight} alt="" />
                                        </Button>
                                      </div>
                                    </Modal>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      style={{
                                        width: "44px",
                                        height: "44px",
                                      }}
                                      type="button"
                                      className="btn-tertiary d-flex align-items-center justify-content-center"
                                      onClick={() =>
                                        document
                                          .getElementById(
                                            "project-image-upload",
                                          )
                                          .click()
                                      }
                                    >
                                      <img src={upload} size="18px" alt="" />
                                    </button>
                                    <input
                                      type="file"
                                      accept="image/*"
                                      id="project-image-upload"
                                      style={{ display: "none" }}
                                      onChange={onSelectImage}
                                    />
                                  </>
                                )}
                              </div>
                            </div>

                            <div>
                              <h5
                                style={{
                                  marginTop: "50px",
                                }}
                              >
                                Recommendations
                              </h5>
                              <CustomInput
                                containerClassName="observation-text-input-container insight-input"
                                inputClassNames="insight-input"
                                placeholder="Explain your recommendation"
                                isTextArea
                                valueState={
                                  openedObservation?.recommendation ?? ""
                                }
                                className={
                                  openedObservation?.recommendation
                                    ? "active"
                                    : ""
                                }
                                onChange={(e) => {
                                  setOpenedObservation((prevObs) => {
                                    return {
                                      ...prevObs,
                                      recommendation: e.target.value,
                                    };
                                  });
                                }}
                              />
                              {!isMetric && (
                                <>
                                  <div
                                    className="insight-input"
                                    style={{
                                      marginTop: "6px",
                                    }}
                                  >
                                    <CustomDropdown
                                      unstyled
                                      isUxInsightsPage
                                      placeholder="Severity"
                                      options={severityOptions}
                                      onChange={(e) =>
                                        setOpenedObservation((prevObs) => {
                                          return {
                                            ...prevObs,
                                            severity: e.value,
                                          };
                                        })
                                      }
                                      value={
                                        openedObservation?.severity
                                          ? severityOptions.filter(
                                              (option) =>
                                                option.value ===
                                                openedObservation?.severity,
                                            )
                                          : null
                                      }
                                      menuOpenState={severityMenuOpen}
                                      setMenuOpenState={setSeverityMenuOpen}
                                    />
                                  </div>
                                  <div
                                    className="insight-input"
                                    style={{
                                      marginTop: "10px",
                                    }}
                                  >
                                    <CustomDropdown
                                      unstyled
                                      placeholder="Ease to fix"
                                      options={fixEaseOptions}
                                      onChange={(e) =>
                                        setOpenedObservation((prevObs) => {
                                          return {
                                            ...prevObs,
                                            fixEase: e.value,
                                          };
                                        })
                                      }
                                      value={
                                        openedObservation?.fixEase
                                          ? fixEaseOptions.filter(
                                              (option) =>
                                                option.value ===
                                                openedObservation?.fixEase,
                                            )
                                          : null
                                      }
                                      menuOpenState={fixEaseMenuOpen}
                                      setMenuOpenState={setFixEaseMenuOpen}
                                    />
                                  </div>
                                </>
                              )}

                              <div>
                                <CustomInput
                                  containerClassName="observation-text-input-container insight-input"
                                  inputClassNames="insight-input"
                                  placeholder="UX/UI rule to not die stupid"
                                  isTextArea
                                  valueState={openedObservation?.uxRule ?? ""}
                                  className={
                                    openedObservation?.uxRule ? "active" : ""
                                  }
                                  onChange={(e) => {
                                    setOpenedObservation((prevObs) => {
                                      return {
                                        ...prevObs,
                                        uxRule: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              </div>
                              <div
                                className="insight-input"
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <CustomDropdown
                                  unstyled
                                  placeholder="Is it linked to a performance goal?"
                                  options={metricOptions}
                                  onChange={(e) =>
                                    setOpenedObservation((prevObs) => {
                                      return {
                                        ...prevObs,
                                        metric: e.value,
                                      };
                                    })
                                  }
                                  value={
                                    openedObservation?.metric
                                      ? metricOptions.filter(
                                          (option) =>
                                            option.value ===
                                            openedObservation?.metric,
                                        )
                                      : null
                                  }
                                  menuOpenState={metricMenuOpen}
                                  setMenuOpenState={setMetricMenuOpen}
                                />
                              </div>
                            </div>
                            <div className="mt-4 insight-input">
                              <div className="d-flex justify-content-end mt-5">
                                <Button
                                  onClick={() => {
                                    saveInsight(openedObservation).then(() => {
                                      toast.success(
                                        "Insight saved successfully!",
                                      );
                                    });
                                  }}
                                  disabled={
                                    [
                                      openedObservation?.title,
                                      openedObservation?.description,
                                    ].some((val) => /^\s*$/.test(val)) ||
                                    (() => {
                                      if (
                                        !imageCache[openedObservation?.id]
                                          ?.imagePreview
                                      )
                                        return false;
                                      return imageCache[
                                        openedObservation?.id
                                      ]?.imagePreview.startsWith("blob:");
                                    })()
                                  }
                                >
                                  Save insight
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="d-flex justify-content-center"
                            style={{ height: "1000px" }}
                          >
                            <Spinner />
                          </div>
                        )
                      ) : null
                    }
                  />
                </div>
              </div>
            );
          }
        })}
      </div>
      <div
        className="d-flex justify-content-end pb-5 mt-5"
        style={{ maxWidth: "548px" }}
      >
        {!doubleSubmit ? (
          <Button
            onClick={() => setDoubleSubmit(true)}
            disabled={!props.validReport}
          >
            Submit report <img alt="✓" src={arrowRight} className="ms-3" />
          </Button>
        ) : (
          <Container>
            <div className="text-center">
              Are you sure you want to submit this report? It will send it to
              the client.
            </div>
            <Row className="mt-3">
              <Col className="d-flex  justify-content-center">
                <Button
                  onClick={submitReport}
                  style={{ background: "apple", border: "none" }}
                >
                  Yep, go ahead!
                </Button>
              </Col>
              <Col className="d-flex  justify-content-center">
                <Button
                  onClick={() => setDoubleSubmit(false)}
                  style={{ background: "red", border: "none" }}
                >
                  Never mind, not yet...
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </div>
  );
}
