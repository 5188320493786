import { useState, useContext, useEffect } from "react";
import "../ClientReports/ClientReports.css";
import "./InsightsV3Container.css";
import { Link, Outlet } from "react-router-dom";
import { Button } from "react-bootstrap";
import { UserInfoContext } from "../../contexts";
import { FiArrowLeft } from "react-icons/fi";
import NotificationPopup from "../NotificationPopup/NotificationPopup";

export function InsightsV3Container(props) {
  const tabItems = [
    {
      name: "insights",
      label: "Insights",
      link: "/insights/view",
    },
    {
      name: "funnels",
      label: "Funnels & Heatmaps",
      link: "/funnels/view",
    },
  ];

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("insights")) {
      setActiveTab("insights");
    } else if (url.includes("funnels")) {
      setActiveTab("funnels");
    }
  }, [window.location.href]);

  const userInfo = useContext(UserInfoContext);

  return (
    <div
      className="client-reports-parent"
      style={{ marginLeft: "36px", marginTop: "39px" }}
    >
      <div style={{ position: "absolute", top: "32px", right: "32px" }}>
        <div className="d-flex justify-content-end align-items-center">
          <NotificationPopup
            setActiveMenuItem={props.setActiveMenuItem}
            userInfo={userInfo}
          />
        </div>
      </div>

      {userInfo?.role === "admin" && (
        <Link to={"/"}>
          <div
            style={{
              width: "200px",
              height: "24px",
              textAlign: "center",
              marginTop: "-50px",
              paddingBottom: "50px",
            }}
            className="fs-14 fw-700 fc-black mb-2"
          >
            <FiArrowLeft
              color="#000"
              size="24px"
              style={{ marginRight: "10px", marginBottom: "2px" }}
            />
            Back to Admin Panel
          </div>
        </Link>
      )}
      <div className="d-flex">
        {tabItems.map((item) => {
          return (
            <div key={item.name}>
              <Button className="tab-item" key={item.name}>
                <Link
                  onClick={() => setActiveTab(item.name)}
                  className={
                    activeTab === item.name
                      ? "fw-700 fc-blue fs-16"
                      : "fw-500 fc-black fs-16"
                  }
                  to={item.link}
                >
                  {item.label}
                </Link>
              </Button>
              <div
                className="tab-bar-insights"
                style={{
                  background: activeTab === item.name ? "#2A00FF" : "none",
                  width: activeTab === "insights" ? "65%" : "85%",
                }}
              />
            </div>
          );
        })}
      </div>
      <hr style={{ marginTop: "5px" }} />

      <Outlet />
    </div>
  );
}
